import { Dialog, DialogContent, Typography } from '@mui/material'
import React, { memo } from 'react'

const CommonModal = memo(({open, onClose, text = "", textCancel= "キャンセル", textConfirm="ダウンロード", handleCancel, handleConfirm} ) => {
  return (
    <Dialog
        open={open}
        onClose={() => {
            if(onClose) {
                onClose()
            }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogContent>
          <Typography align="center" variant="h5" padding={5}>
            {text}
          </Typography>
          <div className="d-flex justify-content-center">
            <button className="btn-main" onClick={() => {
                if(handleCancel) {
                    handleCancel()
                }
            }}>
                {textCancel}
            </button>
            <button className="btn-mail bg-pchat border-pchat ms-2 text-white" onClick={() => {
              if(handleConfirm) {
                handleConfirm()
              }
            }}>
                {textConfirm}
            </button>
          </div>
        </DialogContent>
      </Dialog>
  )
})

export default CommonModal