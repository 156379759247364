import {DOCUMENT_INFO} from "../../constants/store"

const info = (state = {
  color: "#000",
  base64: null,
  key: 0
}, action) => {
  switch (action.type) {
    case DOCUMENT_INFO:
      return Object.assign({}, action.payload);
    default:
      return state
  }
};

export default info;