import { GET_NOTIFICATIONS } from "../../constants/store"

const initialState = {
    list: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case GET_NOTIFICATIONS:
    return { ...state, ...payload }

  default:
    return state
  }
}
