import {
  KONVA_ADD_TEXT,
  KONVA_CHANGE_ELEMENT,
  KONVA_CHANGE,
  KONVA_CHANGE_TOOL,
  KONVA_ELEMENTS,
  KONVA_ELEMENT,
  KONVA_INIT,
  KONVA_CHANGE_HISTORY,
  KONVA_CHANGE_STEP,
  KONVA_REFRESH_POSITION,
  KONVA_SCALE_SCREEN,
  DRAW_SELECT_IMAGES,
  KONVA_IS_CHANGED,
} from '../../constants/store';

export const TOOL = {
  PEN: 'pen',
  DRAG: 'drag',
  ARROW: 'arrow',
  STRAIGHT_LINE: 'straight_line',
  CHECK: 'check',
  TEXT: 'text',
  SQUARE: "square",
  CIRCLE: "circle",
};

const initialState = {
  elements: [],
  element: {},
  tool: TOOL.DRAG,
  stageScale: 1,
  stageX: 0,
  stageY: 0,
  history: [],
  step: 0,
  scaleByScreen: 1,
  selectedImageIds: [],
  isChanged: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case KONVA_INIT: {
      return {
        ...state,
        element: {},
        step: 0,
        isChanged: false,
        history: [
          {
            elements: payload || [],
            stageX: 0,
            stageY: 0,
            stageScale: 1,
          },
        ],
      };
    }
    case KONVA_ELEMENT:
      return { ...state, element: payload };
    case KONVA_ELEMENTS:
      return { ...state, elements: payload };
    case KONVA_CHANGE_TOOL:
      return { ...state, tool: payload };
    case KONVA_ADD_TEXT:
      const { elements } = state;
      const element = {
        ...payload,
        key: elements.length ? elements[elements.length - 1].key + 1 : 0,
      };
      elements.push(element);
      return { ...state, elements, element };
    case KONVA_CHANGE_ELEMENT: {
      const previousStep = state.history[state.step];
      const elements = [...previousStep.elements];
      let index = elements.findIndex((ele) => ele.key === payload.key);
      if (index > -1) {
        elements.splice(index, 1, { ...payload.element });
      }
      const step = payload.newStep ? state.step + 1 : state.step;
      const thisStep = {
        ...previousStep,
        elements,
      };
      let history = state.history.slice(0, step);
      history.push(thisStep);
      return payload.newStep ? { ...state, step, history, isChanged: true} : { ...state, step, history};
    }
    case KONVA_CHANGE: {
      return { ...state, ...payload };
    }
    case KONVA_CHANGE_STEP: {
      return { ...state, step: payload, element: {} };
    }
    case KONVA_CHANGE_HISTORY: {
      const previousStep = state.history[state.step];
      const { newStep, ..._ } = payload;
      const step = payload.newStep ? state.step + 1 : state.step;
      const thisStep = {
        ...previousStep,
        ..._,
      };
      let history = state.history.slice(0, step);
      history = [...history, thisStep];
      return {
        ...state,
        step,
        history,
      };
    }
    case KONVA_REFRESH_POSITION: {
      return { ...state, stageScale: 1, stageX: 0, stageY: 0 };
    }
    case KONVA_SCALE_SCREEN:
      return { ...state, scaleByScreen: payload };
    case DRAW_SELECT_IMAGES: {
      return {...state, selectedImageIds: payload}
    }
    case KONVA_IS_CHANGED: {
      return {...state, isChanged: payload}
    }
    default:
      return state;
  }
};
