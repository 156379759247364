import { connect } from 'react-redux';
import DocumentContent from '../../containers/common/document-content';
import {
  saveDocumentInfoCanvasAction,
  document_set_dialog,
  document_set_filter,
  document_set_info,
  document_set_list,
} from '../../actions/document';
import {
  convertBase64PdfToImage,
  checkSizeImage,
  convertToBase64,
  createClientDraw,
} from '../../constants/utils';
// import moment from 'moment';
import {
  basic_set_loading,
  basic_set_open_snackbar,
} from '../../actions/basic';
import { TEXT_LIST } from '../../constants/index';
import {
  KONVA_CHANGE,
  KONVA_CHANGE_TOOL,
  DOCUMENT_LIST,
  KONVA_CHANGE_ELEMENT,
} from '../../constants/store';
import {
  clearConfirmAction,
  duplicateDrawDetailAction,
  saveDrawDetailsAction,
  saveDrawDetailsNavigation,
  sub_draw_set_info,
} from '../../actions/draw';
import { swalConfirmDelete, swalQuestion } from '../../constants/swal';
import {
  changeElementKonvaAction,
  changeStepKonvaAction,
  initKonvaAction,
  refreshPositionKonvaAction,
} from '../../actions/konva-canvas';
import { GET_SUB_DRAW, GET_USER_ADDRESS_BOOK } from 'share/query';
// import info from "../../reducers/property/info";

let document_filter;
let me;
let document_list = [];
let document_dialog = false;
let document_info = null;
let konva_element = [];
let konva_el = null;
let document_draw;
let konva_is_changed = false;
const mapStateToProps = (state) => {
  me = state.basic.me;
  document_filter = state.document.filter;
  document_list = state.document.list;
  document_dialog = state.document.dialog;
  document_info = state.document.info;
  // konva_element = state.konvaCanvas.elements;
  konva_el = state.konvaCanvas.element;
  const konva_step = state.konvaCanvas.step;
  const konva_history = state.konvaCanvas.history;
  konva_element = konva_history[konva_step]?.elements ?? [];
  document_draw= state.draw.info;
  konva_is_changed = state.konvaCanvas.isChanged;
  return {
    document_info,
    document_filter: state.document.filter,
    document_list: state.document.list,
    document_dialog: state.document.dialog,
    document_tool: state.konvaCanvas.tool,
    document_scale: state.konvaCanvas.stageScale,
    document_draw: state.draw.info,
    konva_element,
    konva_step,
    konva_history,
    me,
    isChange: state.konvaCanvas.isChange,
    sub_draw: state.draw.sub_draw,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => {
      // dispatch(document_set_info());
      // dispatch(document_set_info(a));
      // dispatch(document_set_list([a]));
    },
    onSaveDocument:async(id)=>{
     return saveDocument(dispatch,id)
    },
    onChangeView: () => {
      onChangeView(dispatch);
    },
    onDropFiles: async (previousFiles, files) => {
      dispatch(basic_set_loading(true));
      let attachments = previousFiles;
      const lastIndex = previousFiles.length
        ? previousFiles[previousFiles.length - 1].key
        : -1;
      for (const [index, file] of files.entries()) {
        const { size } = file;
        if (!checkSizeImage(size, dispatch)) return;

        const convert_file = await convertToBase64(file);
        if(file.type === "application/pdf") {
          const fileConvert = await convertBase64PdfToImage(convert_file.base64);
          convert_file.base64 = fileConvert.base64;
          convert_file.pageNumber = fileConvert.pageNumber;
        }        
        attachments.push(convert_file);
        convert_file.key = lastIndex + index + 1;
        attachments.push(convert_file);
      }
      dispatch(document_set_list(attachments));
      dispatch(basic_set_loading(false));
    },
    onChangeInfo:async (info) => {
      dispatch(document_set_info(info));
      dispatch(document_set_dialog(true));
      dispatch(initKonvaAction(info.elements ?? []));
      if (info.change_process) {
        dispatch(saveDrawDetailsAction(info.id));
      }
    },
    onChangeDialog: () => {
      const status = !document_dialog;
      dispatch(document_set_dialog(status));
      if (!status) dispatch(document_set_info(null));
    },
    onChangeValue: (document_info, name, value) => {
      document_info[name] = value;
      dispatch(document_set_info(document_info));

      if (name === 'color' && konva_el && konva_el.key > -1) {
        const element = konva_element.find((ele) => ele.key === konva_el.key);
        if (element) {
          dispatch(
            changeElementKonvaAction({
              key: konva_el.key,
              element: {
                ...element,
                color: value,
              },
              newStep: true,
            })
          );
        }
      }

      if (name === 'fontSize' && konva_el && konva_el.key > -1) {
        const element = konva_element.find((ele) => ele.key === konva_el.key);
        if (element) {
          dispatch(
            changeElementKonvaAction({
              key: konva_el.key,
              element: {
                ...element,
                fontSize: value,
              },
              newStep: true,
            })
          );
        }
      }

      // if (name === 'stroke' && konva_el && konva_el.key > -1) {
      //   const element = konva_element.find((ele) => ele.key === konva_el.key);
      //   if (element) {
      //     dispatch(
      //       changeElementKonvaAction({
      //         key: konva_el.key,
      //         element: {
      //           ...element,
      //           stroke: value,
      //         },
      //         newStep: true,
      //       })
      //     );
      //   }
      // }
    },
    onChangeAgreed: (document_info, name, value) => {
      if (!document_info.agreed) document_info.agreed = {};
      document_info.agreed[name] = value;
      dispatch(document_set_info(document_info));
    },
    onChangeConfirm: (document_info, text) => {
      document_info.process = 5;
      dispatch(document_set_info(document_info));

      const index = document_list.findIndex(
        (document) => document.base64 === document_info.base64
      );
      if (index > -1) {
        document_list[index].process = document_info.process;
        dispatch(document_set_list(document_list));
      }

      dispatch(
        basic_set_open_snackbar({
          type: 'success',
          message: TEXT_LIST.has_registered,
        })
      );
    },
    onChangeRemove: (document_info) => {
      const index = document_list.findIndex(
        (document) => document.base64 === document_info.base64
      );
      if (index > -1) {
        document_list.splice(index, 1);
        dispatch(document_set_list(document_list));
        dispatch(
          basic_set_open_snackbar({
            type: 'error',
            message: TEXT_LIST.has_removed,
          })
        );
      }
    },
    saveDocumentInfoCanvas: (drawDetailId) => {
      dispatch(saveDocumentInfoCanvasAction(drawDetailId));
    },
    /**
     * @author quyennguyen
     */
    onChangeElementsKonva: (elements) => {
      dispatch(initKonvaAction(elements));
    },
    onChangeTool: (tool) => {
      dispatch({
        type: KONVA_CHANGE_TOOL,
        payload: tool,
      });
    },
    dupplicateImage: (list) => {
      dispatch({
        type: DOCUMENT_LIST,
        payload: list,
      });
    },
    changeScale: (stageScale) => {
      dispatch({
        type: KONVA_CHANGE,
        payload: {
          stageScale,
        },
      });
    },
    onRevertDrawDetail: (drawDetailId) => {
      this.onChangeElementsKonva([]);
      dispatch(saveDrawDetailsAction(drawDetailId));
    },
    onSaveDrawDetail: (drawDetailId, action = 'save') => {
      dispatch(saveDrawDetailsAction(drawDetailId, action));
    },
    duplicateDrawDetail: (drawDetailId, duplicateAll = false) => {
      dispatch(duplicateDrawDetailAction({drawDetailId, duplicateAll}));
    },
    onCloseDrawDetail: async (info) => {
      if (
        !info.process &&
        JSON.stringify(info.elements || []) !== JSON.stringify(konva_element)
      ) {
        await swalConfirmDelete({
          html: 'この画像は編集中で、保存されていません。他の画像を開くと編集した内容を保存しますか？',
          confirmButtonText: '保存する',
          cancelButtonText: '保存しないで移動する',
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(saveDrawDetailsAction(info.id));
          }
        });
      }

      dispatch(saveDocumentInfoCanvasAction(info.id));
      dispatch(document_set_info(null));
      dispatch({ type: KONVA_CHANGE_TOOL, payload: '' });
    },
    clearConfirm: (drawId) => {
      dispatch(clearConfirmAction(drawId));
    },
    changeKonvaStep: (step) => {
      dispatch(changeStepKonvaAction(step));
    },
    refreshPositionKonva: () => {
      dispatch(refreshPositionKonvaAction());
    },
    getUserAddressBook:async ()=>{
      let userName ;
      const client = createClientDraw()
      await client.request(GET_USER_ADDRESS_BOOK, {id: document_draw.user_address_book_id}).then((data) => {
        userName = data?.getUserAddressBookById?.user_name || "";
      })
      return userName
    },
    onChangeSubDraw: async (id,info) => {  
      const checkConfirm = await saveDocument(dispatch,id);
      if(checkConfirm){
        dispatch(document_set_info(info));
        dispatch(document_set_dialog(true));
        dispatch(initKonvaAction(info.elements ?? []));
  
        // if (info.change_process) {
        //   dispatch(saveDrawDetailsAction(info.id));
        // }
      }
      
    },
    changeSubDrawInfo: async(draw_info)=>{
      const client = createClientDraw()
      await client.request(GET_SUB_DRAW, {draw_parent_id: draw_info.id}).then((data) => {
        let subData = data.getSubDraws
        if(subData.length > 0){
          subData.unshift(draw_info)
        } 
        dispatch(sub_draw_set_info(subData));
      })
    }
  };
};

// const sortDocumentByDate = (documents) => {
//   let sortDocument = [];
//   for (const document of documents) {
//     const date = moment(document.date).format('YYYY/MM/DD');
//     const index = sortDocument.findIndex(s => s.date === date);
//     if (index > -1) {
//       sortDocument[index].images.push(document)
//     } else {
//       sortDocument.push({date: date, images: [document]})
//     }
//   }
//   return sortDocument;
// };
export const saveDocument = async(dispatch, id)=>{
  let confirmCheck = false;
  if(konva_is_changed){
    await swalQuestion({
      html: 'この画像は編集中で、保存されていません。他の画像を開くと編集した内容を保存しますか？',
      confirmButtonText: '保存する',
      cancelButtonText: '保存しないで移動する',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(saveDrawDetailsAction(id, 'save'));
      }
      confirmCheck = true;
    });
   
  }else{
    confirmCheck = true;
  }
  return confirmCheck;
}

export const onChangeView = (dispatch) => {
  const { view } = document_filter;
  document_filter.view = view !== 'list' ? 'list' : 'grid';
  dispatch(document_set_filter(document_filter));
};

const CDocumentContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentContent);

export default CDocumentContent;
