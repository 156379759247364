import React from "react"
import { CHANGE_HOC_MODAL, CLOSE_HOC_MODAL } from "../constants/store"

export const closeHOCModalAction = () => {
    return {
        type: CLOSE_HOC_MODAL,
        payload: {
            isOpen: false,
            component: <></>
        }
    }
}

export const openHOCModalAction = (component) => {
    return {
        type: CHANGE_HOC_MODAL,
        payload: {
            isOpen: true,
            component
        }
    }
}