import React from "react";
import {connect} from "react-redux";
import RegisterUser from '../../containers/common/register-user'
import {draw_set_register_user, getDrawDetailsAction} from "../../actions/draw";
import {basic_set_open_snackbar} from "../../actions/basic";
import {createClient, getErrorMessage, validateEmail} from "../../constants/utils";
import {TEXT_LIST} from "../../constants/index";
import {ASSIGN_USER_TO_DRAW} from "../../share/mutation";
import {onLoadDraws} from "../cpages/cdraws";
import { openHOCModalAction } from "../../actions/hoc-modal";
import {QRDialog} from "../../containers/common/document"
let register_user = null;

const mapStateToProps = (state) => {
  register_user = state.draw.register_user;
  
  return {
    draw_info: state.draw.info,
    register_user: state.draw.register_user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => {
    },
    onChangeOpenRegisterUser: () => {
      onChangeOpenRegisterUser({dispatch})
    },
    onChangeValue: (name, value) => {
      register_user[name] = value;
      dispatch(draw_set_register_user(register_user));
    },
    assignUserToDraw: (data) => {
      const errors = checkData(data);
      if(errors) return dispatch(basic_set_open_snackbar({m: errors, t: 'error'}));
      
      const {draw_id, user_name, email} = data;
      const client = createClient();
      return client.request(ASSIGN_USER_TO_DRAW, {userDrawInput: {draw_id, user_name, email}}).then(data => {
        const {assignUserToDraw} = data;
        if(assignUserToDraw) {
          dispatch(basic_set_open_snackbar({m: TEXT_LIST.has_registered, t: 'success'}));
          dispatch(draw_set_register_user({open_dialog: false}));
          dispatch(getDrawDetailsAction(draw_id))
          onLoadDraws(dispatch);
          // if(assignUserToDraw.url_confirm) {
          //   dispatch(openHOCModalAction(<QRDialog url={assignUserToDraw.url_confirm} />))
          // }
        }
      }).catch(e => {
        const error = getErrorMessage(e);
        dispatch(basic_set_open_snackbar({m: error, t: 'error'}));
      })
    },
  }
};

const checkData = (data) => {
  let errors = '';
  const {draw_id, user_name, email} = data;
  
  if (!user_name || !user_name?.trim()) errors = 'お客様名を入力してください \n';
  if (!email) errors = 'メールアドレスを入力してください \n';
  if (!user_name && !email) errors = 'お客様名とメールアドレスを入力してください \n';
  if (!draw_id) errors += 'No found draw_id \n';
  if (email && !validateEmail(email)) errors += 'E-Mail形式が合っていません。';
  
  return errors;
};

export const onChangeOpenRegisterUser = ({dispatch}) => {
  register_user.open_dialog = !register_user.open_dialog;
  if(register_user.open_dialog) {
    dispatch(draw_set_register_user(register_user));
  } else {
    dispatch(draw_set_register_user());
  }
};

const CRegisterUser = connect(mapStateToProps, mapDispatchToProps)(RegisterUser);

export default CRegisterUser;