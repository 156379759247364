import React from 'react';
import html2canvas from "html2canvas";

const ScheduleDownloader = ({rootElementId , label}) => {

    const downloadPdfDocument = () => {

        const hiddenElements = document.querySelectorAll(".hidden-when-download");
        hiddenElements.forEach(element => {
            element.style.display = 'none'
        })
        const tableElements = document.getElementById("schedule-table").style.overflow ="hidden";
        const input = document.getElementById(rootElementId);

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                var a = document.createElement("a");    
                a.href = Buffer.from(imgData);
                a.download = "schedule.png";
                a.click();
            })
    }

    return <button className='schedule-download-btn' onClick={downloadPdfDocument}>{label}</button>

}

export default ScheduleDownloader;