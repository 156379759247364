import {DOCUMENT_LIST_STORAGE} from "../../constants/store";

const storage = (state = [], action) => {
  switch (action.type) {
    case DOCUMENT_LIST_STORAGE:
      return action.payload.map(payload => Object.assign({}, payload));
    default:
      return state;
  }
};

export default storage;