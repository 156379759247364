import {combineReducers} from "redux";
import basic from "./basic/index";
import document from "./document";
import konvaCanvas from "./konva-canvas/konva-canvas";
import property from "./property";
import draw from "./draw/index"
import hocModal from "./hoc-modal/hoc-modal";
import drawDetail from "./draw-detail/draw-detail";
import notification from "./notification/notification";
import confirm from "./confirm/confirm";
import schedule from "./schedule";

const reducer = combineReducers({
  basic,
  property,
  document,
  konvaCanvas,
  draw,
  hocModal,
  drawDetail,
  notification,
  confirm,
  schedule
});

export default reducer;
