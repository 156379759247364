import moment from "moment";
import {createAction} from "redux-actions";
import {
  DRAW_LIST,
  DRAW_INFO,
  DRAW_FILTER,
  DRAW_PAGING,
  DRAW_REGISTER_DRAW,
  DRAW_REGISTER_USER,
  ROOM_SELECT,
  ROOM_SELECT_LIST,
  SUB_DRAW_INFO,
  DRAW_FRAME_LIST,
} from "../constants/store"
import React from "react"
import {
  CLEAR_CONFIRM, SAVE_DRAW_DETAIL, SEND_CONFIRM_DRAW, UPLOAD_DRAW_DETAILS, DUPLICATE_DRAW_DETAIL,
  USER_SEND_CONFIRMED_MAIL, SAVE_DRAW_DETAIL_BY_USER, UPLOAD_DRAW_DETAILS_BY_USER, DUPLICATE_DRAW_DETAIL_BY_USER, CONFIRM_DRAW_DETAIL_BY_USER, ADD_MESSAGE_INTO_CHAT, ADD_ATTACHMENT_DRAW
} from "../share/mutation";
import {COOKIE_DOMAIN, convertSameFileName, convertToBase64, createClientDraw, getErrorMessage, processCancelRequest, processCancelRequestConfirm, removeCookie, requestGraphQL, userRequestGraphQL} from "../constants/utils";
import { DOWNLOAD_IMAGE, GET_DRAW_BY_ID, USER_DOWNLOAD_IMAGE, USER_GET_DRAW_BY_ID, GET_FIREBASE_TOKEN, GET_ROOM_INFO_BY_ROOM_ID, GET_SUB_DRAW, DOWNLOAD_COMBINE_PDF, GET_ROOM_INFO_BY_DRAW_ID, GET_CGS_FRAMES_INDEX} from "../share/query";
import {document_set_info, document_set_list, document_set_storage, saveDocumentInfoCanvasAction} from "./document";
import { basic_loading, basic_loading_status, basic_open_snackbar, basic_set_loading } from "./basic";
import { property_set_info } from "./property";
import {TEXT_LIST, USER_TYPE} from "../constants/index";
import { setConfirmedIdAction, setListConfirmAction } from "./confirm";
import history from "../containers/pages/history";
import { initKonvaAction, selectedImageIdsAction, setIsChangedKonvaAction, setKonvaElementsAction } from "./konva-canvas";
import { jsPDF } from "jspdf";
import { swalFire } from "constants/swal";
import { handleSortArray } from "utils/common";
export const draw_set_list = createAction(DRAW_LIST);
export const draw_set_info = createAction(DRAW_INFO);
export const draw_set_filter = createAction(DRAW_FILTER);
export const draw_set_paging = createAction(DRAW_PAGING);
export const draw_set_register_draw = createAction(DRAW_REGISTER_DRAW);
export const draw_set_register_user = createAction(DRAW_REGISTER_USER);
// import jsPDF from 'jspdf';
export const draw_set_room_select = createAction(ROOM_SELECT);
export const draw_set_room_list = createAction(ROOM_SELECT_LIST);
export const sub_draw_set_info = createAction(SUB_DRAW_INFO);
export const draw_set_frame_list = createAction(DRAW_FRAME_LIST);
/**
 * @author quyennguyen
 */
export const getDrawDetailsAction = (drawId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(basic_set_loading(true));
      const data = await requestGraphQL(GET_DRAW_BY_ID, { drawId});
      
      const {room_by_user, room_id,cg_frame_list, from_user} = data.getDrawById;
      if(from_user?.company && getState()?.basic?.me !== null && from_user?.company?.id !== getState()?.basic?.me?.company?.id) {
        history.push('/draws');
      }
      const cgData = await requestGraphQL(GET_CGS_FRAMES_INDEX,{draw_id:drawId})
      if(cgData.getCgAndFrameListIndex.length > 0){
        const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
        dispatch(draw_set_frame_list(sortResult))
      }
      
      dispatch(draw_set_room_select({value: room_by_user.find(({id}) => id === room_id), draw_id: drawId}));
      dispatch(property_set_info({ name: data.getDrawById.name}));
      dispatch(draw_set_info({
        ...getState().draw.info,
        id: drawId,
        userAddressBookId: data.getDrawById.user_address_book_id,
        endUserName: data.getDrawById.user_name,
        room_id:data.getDrawById.room_id,
        ...data.getDrawById,
      }));
      
      // if(data.getDrawById.details.length) {
        const payload = data.getDrawById.details.map((drawDetail) => {
          const result =  {
            ...drawDetail,
            date: moment(drawDetail.createdAt),
            base64: drawDetail.attachment.url
          };
          if(getState().document.info.id === drawDetail.id) {
            dispatch(document_set_info(result));
          }
            
          return result;
        });

        //remove state document info when data getDrawById empty
        if(!data.getDrawById.details.length) {
          dispatch(document_set_info());
        }
        
        // dispatch(document_set_list(payload));
        const listFile = convertSameFileName(payload);
        dispatch(document_set_list(listFile));
        dispatch(document_set_storage(listFile));
        dispatch(basic_set_loading(false))
      // }
    } catch (error) {
      dispatch(basic_set_loading(false))
      dispatch(basic_open_snackbar({t: "error", m: getErrorMessage(error)}))
      history.push('/draws');
      return window.location.reload();
    }
  }
}

export const duplicateDrawDetailAction = ({drawDetailId, duplicateAll, subDrawList = {}}) => {
  return async (dispatch, getState) => {
    const draw_id = getState()?.draw?.info?.id;
    dispatch(basic_loading(true));
    try {
      const data = await requestGraphQL(DUPLICATE_DRAW_DETAIL, {drawDetailId, duplicateAll, subDrawList: Object.values(subDrawList)});
      const {duplicateDrawDetail} = data;
      if(duplicateDrawDetail) {
        dispatch(getDrawDetailsAction(draw_id));
        // let {list} =  getState().document;
        // list.unshift({
        //   ...duplicateDrawDetail,
        //   date: moment(duplicateDrawDetail.createdAt),
        //   base64: duplicateDrawDetail.attachment.url
        // });
        // // dispatch(document_set_list(list));
        // const listFile = convertSameFileName(list);
        // dispatch(document_set_list(listFile));
        // dispatch(document_set_storage(listFile));
      }
      
      dispatch(basic_open_snackbar({t: "success", m: duplicateAll ? TEXT_LIST.dupplicate_success_after_edit : TEXT_LIST.dupplicate_success}));
      dispatch(basic_loading(false));
      
      return true;
      
    } catch (error) {
      dispatch(basic_open_snackbar({t: "error", m: getErrorMessage(error)}))
    }
    
    dispatch(basic_loading(false));
  }
};

export const duplicateDrawDetailByUserAction = ({drawDetailId, duplicateAll, subDrawList = {}}) => {
  return async (dispatch, getState) => {
    dispatch(basic_loading(true));
    try {
      const data = await userRequestGraphQL(DUPLICATE_DRAW_DETAIL_BY_USER, {drawDetailId, duplicateAll, subDrawList: Object.values(subDrawList)});
      dispatch(userGetDrawDetailsAction(getState().draw.info.id));
      
      dispatch(basic_open_snackbar({t: "success", m: duplicateAll ? TEXT_LIST.dupplicate_success_after_edit : TEXT_LIST.dupplicate_success}));
      dispatch(basic_loading(false));
      
      return true;
      
    } catch (error) {
      dispatch(basic_open_snackbar({t: "error", m: getErrorMessage(error)}))
    }
    
    dispatch(basic_loading(false));
  }
};

export const uploadFileDrawDetailsAction = (drawId, details) => {
  return async (dispatch, getState) => {
    dispatch(basic_loading(true));
    
    try {
      const data = await requestGraphQL(UPLOAD_DRAW_DETAILS, {drawId, details});
      const {uploadDrawDetails} = data;
      if(uploadDrawDetails.details.length) {
        const payload = uploadDrawDetails.details.map((drawDetail) => {
          const result =  {
            ...drawDetail,
            date: moment(drawDetail.createdAt),
            base64: drawDetail.attachment.url
          };
          return result;
        });
        // dispatch(document_set_list(payload));
        const listFile = convertSameFileName(payload);
        dispatch(document_set_list(listFile));
        dispatch(document_set_storage(listFile));
  
        const {info} = getState().document;
        if(!info || !info.id) {
          dispatch(document_set_info({...payload[0], elements: []}))
          dispatch(initKonvaAction())
          await requestGraphQL(GET_SUB_DRAW, {draw_parent_id: payload[0].id}).then((data) => {
            let subData = data.getSubDraws
            if(subData.length > 0){
              subData.unshift(payload[0])
            } 
            dispatch(sub_draw_set_info(subData));
          })
        };
        
      } else {
        dispatch(document_set_list([]));
        dispatch(document_set_storage([]));
      }
      
      dispatch(basic_open_snackbar({t: "success", m: TEXT_LIST.upload_success}));
      dispatch(basic_loading(false));
      return true;
      
    } catch (error) {
      dispatch(basic_open_snackbar({t: "error", m: getErrorMessage(error)}))
    }
    
    dispatch(basic_loading(false));
  }
};

export const uploadFileDrawDetailsByUserAction = (drawId, details) => {
  return async (dispatch) => {
    dispatch(basic_loading(true));
    try {
      const data = await userRequestGraphQL(UPLOAD_DRAW_DETAILS_BY_USER, {drawId, details});
      dispatch(userGetDrawDetailsAction(drawId))
      dispatch(basic_open_snackbar({t: "success", m: TEXT_LIST.upload_success}));
      dispatch(basic_loading(false));
      return true;
    } catch (error) {
      dispatch(basic_open_snackbar({t: "error", m: getErrorMessage(error)}))
    }
    dispatch(basic_loading(false));
  }
};

export const saveDrawDetailsAction = (drawDetailId, action = "save") => {
  return async (dispatch, getState) => {
    dispatch(basic_loading(true));
    try {
      dispatch(saveDocumentInfoCanvasAction(getState().document.info.id));
      const {list, info: infoDocument} = getState().document;
      const {sub_draw, info} = getState().draw;
      let totalList = []
      if(Object.keys(sub_draw).length > 0){
        for (var i=1; i< Object.keys(sub_draw).length; i++){
          totalList.push(sub_draw[i])
        }
      }
      let subdrawInfo = totalList.find(item => item.id === drawDetailId);
      const detail = list.find(d => d.id === drawDetailId);
      if(!detail && !subdrawInfo) {
        dispatch(basic_loading(false));
        return console.log('No fount info');
      }
      const data = await requestGraphQL(SAVE_DRAW_DETAIL, {detail: detail ? detail : subdrawInfo});
      if(data.saveDrawDetail) {
        const payload = data.saveDrawDetail.details.map((drawDetail) => {
          return  {
            ...drawDetail,
            date: moment(drawDetail.createdAt),
            base64: drawDetail.attachment.url
          };
        });
        // dispatch(document_set_list(payload));
        // const listFile = convertSameFileName(payload);
        // dispatch(document_set_list(listFile));
        // dispatch(document_set_storage(listFile));
        dispatch(setIsChangedKonvaAction(false))
        dispatch(getDrawDetailsAction(info?.id));
        const client = createClientDraw()
        const idInfo = infoDocument?.parent_id ?? infoDocument?.id;
        const subFirst = list?.find((item) => item?.id === idInfo);
        await client.request(GET_SUB_DRAW, {draw_parent_id: subFirst?.id}).then((data) => {
          let subData = data.getSubDraws
          if(subData.length > 0){
            subData.unshift(subFirst)
          } 
          dispatch(sub_draw_set_info(subData));
        })
      }

      if(action === "save") {
        dispatch(basic_open_snackbar({t: "success", m: TEXT_LIST.save_success}));
      } else {
        dispatch(basic_open_snackbar({t: "success", m: TEXT_LIST.revert_success}));
      }
      
      dispatch(basic_loading(false));
      return true;
      
    } catch (error) {
      dispatch(basic_open_snackbar({t: "error", m: getErrorMessage(error)}));
    }
    
    dispatch(basic_loading(false))
  }
};

export const saveDrawDetailsByUserAction = (drawDetailId, action = "save") => {
  return async (dispatch, getState) => {
    dispatch(basic_loading(true));
    try {
      dispatch(saveDocumentInfoCanvasAction(getState().document.info.id));
      const {list} = getState().document;
      const {sub_draw} = getState().draw;
      let totalList = []
      if(Object.keys(sub_draw).length > 0){
        for (var i=1; i< Object.keys(sub_draw).length; i++){
          totalList.push(sub_draw[i])
        }
      }
      let subdrawInfo = totalList.find(item => item.id === drawDetailId);
      const detail = list.find(d => d.id === drawDetailId);
      if(!detail && !subdrawInfo) {
        dispatch(basic_loading(false));
        return console.log('No fount info');
      }
      
      const data = await userRequestGraphQL(SAVE_DRAW_DETAIL_BY_USER, {detail : detail ? detail : subdrawInfo});
      dispatch(userGetDrawDetailsAction(getState().draw.info.id))
      dispatch(setIsChangedKonvaAction(false))
      
      if(action === "save") {
        dispatch(basic_open_snackbar({t: "success", m: TEXT_LIST.save_success}));
      } else {
        dispatch(basic_open_snackbar({t: "success", m: TEXT_LIST.revert_success}));
      }
      
      dispatch(basic_loading(false));
      return true;
      
    } catch (error) {
      dispatch(basic_open_snackbar({t: "error", m: getErrorMessage(error)}));
    }
    
    dispatch(basic_loading(false))
  }
};

export const saveDrawDetailsNavigation = (drawDetailId, action = "save") => {
  return async (dispatch, getState) => {
    dispatch(basic_loading(true));
    try {
      dispatch(saveDocumentInfoCanvasAction(getState().document.info.id));
      const {list} = getState().document;
      const {sub_draw} = getState().draw;
      let totalList =[];
      if(Object.keys(sub_draw).length > 0){
        for (var i=1; i< Object.keys(sub_draw).length; i++){
        totalList.push(sub_draw[i])
        }
      }

      const detail = list.find(d => d.id === drawDetailId);
      const subDrawInfo = totalList.find(d => d.id === drawDetailId);
      if(!detail && !subDrawInfo) {
        dispatch(basic_loading(false));
        return console.log('No fount info');
      }
      const data = await userRequestGraphQL(SAVE_DRAW_DETAIL_BY_USER, {detail : detail ? detail : subDrawInfo});
      dispatch(userGetDrawDetailsAction(getState().draw.info.id))
      dispatch(setIsChangedKonvaAction(false))
      
      dispatch(basic_loading(false));
      return true;
      
    } catch (error) {
      console.log('')
    }
    
    dispatch(basic_loading(false))
  }
};

export const clearConfirmAction = (drawId) => {
  return async (dispatch, getState) => {
    dispatch(basic_loading(true))
    try {
      const data = await requestGraphQL(CLEAR_CONFIRM, {drawId})
      if(data) {
        dispatch(getDrawDetailsAction(drawId))
      }
    } catch (error) {
      dispatch(basic_open_snackbar({
        t: "error",
        m: getErrorMessage("Clear failed")
      }))
    }
    dispatch(basic_loading(false))
  }
}

export const sendConfirmDrawAction = (drawId,isSendMail) => {
  return async (dispatch, getState) => {
    try {
      const data = await requestGraphQL(SEND_CONFIRM_DRAW, {drawId,isSendMail});
      dispatch(basic_open_snackbar({t: "success", m: TEXT_LIST.send_success}));
      await dispatch(getDrawDetailsAction(drawId))
      
      return data.sendConfirmDraw;
      
    } catch (error) {
      dispatch(basic_open_snackbar({t: "error", m: getErrorMessage(error)}))
    }
  }
}

export const handleSendMessage = async (info,link, room, valueSelectRoom) => {
  try {
    let roomInfo;
    if(!valueSelectRoom) {
      if(info.room_id && info.room_id.length > 10){
      
        const roomData = await requestGraphQL(GET_ROOM_INFO_BY_ROOM_ID, {id: info.room_id});
        roomInfo = roomData.getRoomInfoByRoomId;
      }else{
        roomInfo = room;
      }
    } else {
      roomInfo = valueSelectRoom;
    }
    
    let variables = {
      room_id: roomInfo.id,
      to_user_id: roomInfo.to_user_id,
      message: `${info.endUserName}デジタルサイン（確認・署名）に関して、ご確認いただきたい書類・図面がございます。
        こちらのリンクで確認画面に移動することができます。
        よろしくお願いします。
        ${link}
      `,
      property_id: roomInfo.property_id,
      line_seller_receive_notif:roomInfo.line_seller_receive_notif,
      send_message_to_mail:true
    }
    const client = createClientDraw();
    
    client.request(ADD_MESSAGE_INTO_CHAT, variables).then(() => {
      client.request(GET_FIREBASE_TOKEN, { id: roomInfo.to_user_id }).then(data => {
      })
    })
    return true;
    
  } catch (error) {
    console.log(error)
  }
}

export const userGetDrawDetailsAction = (drawId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(basic_set_loading(true));
      const client = createClientDraw();
      const data = await client.request(USER_GET_DRAW_BY_ID, {
        drawId,
      })
      dispatch(property_set_info({
        name: data.userGetDrawById.name
      }))
      const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:drawId})
      // console.log("=======================alo")
      if(cgData.getCgAndFrameListIndex.length > 0){
        const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
        dispatch(draw_set_frame_list(sortResult))
      }else{
        dispatch(draw_set_frame_list([]))
      }
      dispatch(draw_set_info({
        ...getState().draw.info,
        ...data.userGetDrawById,
        id: drawId,
        userAddressBookId: data.userGetDrawById.user_address_book_id,
        endUserName: data.userGetDrawById.user_name,
      }))
      if(data.userGetDrawById.details.length) {
        const payload = data.userGetDrawById.details.map((drawDetail) => {
            const result =  {
              ...drawDetail,
              date: moment(drawDetail.createdAt),
              base64: drawDetail.attachment.url
            };
            
          return result
        });
  
        // dispatch(document_set_list(payload));
        const listFile = convertSameFileName(payload);
        dispatch(document_set_list(listFile));
        dispatch(document_set_storage(listFile));
        let parent_id;
        const {info} = getState().document;
        if(!info.id) {
          dispatch(document_set_info(payload[0]));
          dispatch(initKonvaAction(payload[0].elements ?? []))
          parent_id = payload[0].id
        }else{
          if(info.parent_id){
            parent_id = info.parent_id
          }else{
            parent_id = info.id
          }
          
        }
        if(parent_id){
          await client.request(GET_SUB_DRAW, {draw_parent_id: parent_id}).then((data) => {
            let subData = data.getSubDraws
            if(subData.length > 0){
              subData.unshift(info.id && !info.parent_id ? info : payload[0])
            } 
            dispatch(sub_draw_set_info(subData));
          })
        }
        
      } else {
        dispatch(document_set_list([]));
        dispatch(document_set_storage([]));
      }
      dispatch(basic_set_loading(false))
    } catch (error) {
      dispatch(basic_set_loading(false))
      const msg  = getErrorMessage(error);
      dispatch(basic_open_snackbar({t: "error", m: msg}));
      
      if( msg.search("don't have permission") > -1) {
        removeCookie('x-token', COOKIE_DOMAIN);
        return  window.location.reload();
      }
    }
  }
}

export const confirmDrawByUserAction = ({confirmedItem,listSubDrawChange =[], process}) => {
  return async (dispatch, getState) => {
    try { 
      dispatch(basic_loading(true))
      dispatch(basic_loading_status(<><p>処理中</p><p>しばらくお待ちください</p><p>ページ数によっては数分かかることがあります</p></>))
      let newConfirmedItem = confirmedItem
      if(listSubDrawChange.length > 0){
        const index =  listSubDrawChange.findIndex((el)=> el.id === confirmedItem.id)
        newConfirmedItem.elementsCanvas = listSubDrawChange[index].elements;
      }
      const {file_same_name, draw_id, file_same_name_have_ex} = newConfirmedItem ?? {};
      const data = await userRequestGraphQL(CONFIRM_DRAW_DETAIL_BY_USER, {confirmedItem:newConfirmedItem, subDrawList: listSubDrawChange, process});
      if(!data.confirmDrawDetailByUser) return;
      const {processResult} = data?.confirmDrawDetailByUser ?? {};
      if(processResult === processCancelRequest.type || processResult === processCancelRequestConfirm.type) {
        const roomInfoResponse = await userRequestGraphQL(GET_ROOM_INFO_BY_DRAW_ID, {id: draw_id});
        const {property_id, to_user_id, id: room_id,line_seller_receive_notif} = roomInfoResponse?.getRoomInfoByDrawId ?? {};

        userRequestGraphQL(ADD_MESSAGE_INTO_CHAT, {
          message: `「確認依頼」「記入依頼」において、「差戻し」が要求されました。
             ${`  `}デジタルサインで「差戻し」内容をご確認のうえ、改めて「確認依頼」「記入依頼」を行ってください。`,
          property_id,
          room_id,
          to_user_id,
          line_seller_receive_notif,
          // stored: true,
        });

      } else {
        let baseData = data.confirmDrawDetailByUser.files[0].data;
        let fileName = file_same_name_have_ex;
        if(data.confirmDrawDetailByUser.type !== "image/png"){
          const mergeResult = await mergeBase64ImgToPdfBase64(data.confirmDrawDetailByUser.files)
          baseData = mergeResult;
          fileName = file_same_name + ".pdf";
        }
        const roomInfoResponse = await userRequestGraphQL(GET_ROOM_INFO_BY_DRAW_ID, {id: draw_id});
        const {property_id, to_user_id, id: room_id,line_seller_receive_notif,from_company_id,from_user_id} = roomInfoResponse?.getRoomInfoByDrawId ?? {};
        // isSendLine = true => dont create first image for line 
        let variables={
          create_signed_url:true,
          file_name: data.confirmDrawDetailByUser.prev_name + fileName,
          file_type: data.confirmDrawDetailByUser.type,
          image64: baseData, 
          zip: false,
          isSendLine: !line_seller_receive_notif,
          // from_company_id:from_company_id,
          // from_user_id:from_user_id,
          // room_id:room_id
        }
        const result = await userRequestGraphQL(ADD_ATTACHMENT_DRAW, { attachmentInput: variables })
        await userRequestGraphQL(ADD_MESSAGE_INTO_CHAT, {
          message: '【自動送信】テスト において依頼されていた確定作業が完了しました。',
          property_id,
          room_id,
          to_user_id,
          line_seller_receive_notif,
          // send_message_to_mail:true
          // stored: true,
        });
        userRequestGraphQL(ADD_MESSAGE_INTO_CHAT, {
          attachment_id: result.addAttachmentDraw?.id,
          message: fileName ?? result.addAttachmentDraw?.file_name,
          property_id,
          room_id,
          to_user_id,
          line_seller_receive_notif,
          // stored: true,
        });
      }
      dispatch(setConfirmedIdAction(data.confirmDrawDetailByUser.id))
      dispatch(basic_loading(false))
      dispatch(basic_loading_status(""))
    } catch (error) {
      dispatch(basic_open_snackbar({t: "error", m: getErrorMessage(error)}))
      dispatch(basic_loading(false))
      dispatch(basic_loading_status(""))
    }
  }
}

export const userSendConfirmedMailAction = (drawId) => {
  return async (dispatch, getState) => {
    try {
      // Send confirmed mail to seller
      const data = await userRequestGraphQL(USER_SEND_CONFIRMED_MAIL, {drawId});
      dispatch(basic_open_snackbar({t: "success", m: "確認を確定しメールを送信しました"}));
      // Get updated draw details
      // await dispatch(getDrawDetailsAction(drawId))
      // Reset state process list in local
      dispatch(setListConfirmAction([]));
      await dispatch(userGetDrawDetailsAction(drawId))
      dispatch(document_set_info(getState().document.list[0]));
      dispatch(setKonvaElementsAction(getState().document.list[0].elements ?? []))
      history.replace(`/confirm/${drawId}`);
    } catch (error) {
      dispatch(basic_open_snackbar({t: "error", m: getErrorMessage(error)}))
    }
  }
}

export const downloadImagesAction = (userType = USER_TYPE.SELLER, image) => {
  return async (dispatch, getState) => {
    const {selectedImageIds} = getState().konvaCanvas
    const imageItem = image?.length ? image : selectedImageIds;
    const listFullImage = getState().document.list;
    const filteredListImage = listFullImage?.filter((item) => imageItem.includes(item?.id));
    if(filteredListImage.length === 0) {
      dispatch(basic_open_snackbar({t: "error", m: TEXT_LIST.missing_images}))
      dispatch(selectedImageIdsAction([]))
      return ;
    }
    dispatch(basic_loading(true))
    dispatch(basic_loading_status("ダウンロード中"))
    try {
      const {id} = getState().draw.info;
      const downloadPromises = filteredListImage.map(async item => {
        const imageId = item?.id ?? {};
        const {attachment} = item ?? {};
        return await (userType === USER_TYPE.SELLER ? downloadImage(id, imageId, attachment) : userDownloadImage(id, imageId, attachment))
      })
      await Promise.all(downloadPromises).then(() => {
        dispatch(selectedImageIdsAction([]))
        // dispatch(basic_open_snackbar({ t: "success", m: TEXT_LIST.download_success}))
      })
    } catch (error) {
      dispatch(basic_open_snackbar({t: "error", m: getErrorMessage(error)}))
    }
    dispatch(basic_loading(false))
    dispatch(basic_loading_status(""))
  }
}

const downloadImage = async (drawId, drawDetailId, attachment) => {
  const {file_alias, file_type} = attachment ?? {};
  try {
    const data = await requestGraphQL(DOWNLOAD_IMAGE, {
      drawId,
      drawDetailId,
    });
    if(file_type === "application/pdf") {
      await downloadPdfFromBase64Image({listFile: data.downloadImage, file_alias})
    } else {
      var a = document.createElement("a");    
      a.href = Buffer.from(data.downloadImage[0].data);
      a.download = file_alias;
      a.click();
      swalFire({title:"ダウンロードが完了しました。"})
    }
  } catch (error) {
    console.error(error)
  }
}

const userDownloadImage = async (drawId, drawDetailId, attachment) => {
  const {file_alias, file_type} = attachment ?? {};
  try {
    const data = await userRequestGraphQL(USER_DOWNLOAD_IMAGE, {
      drawId,
      drawDetailId,
    });
    if(file_type === "application/pdf") {
      await downloadPdfFromBase64Image({listFile: data.userDownloadImage, file_alias})
    } else {
      var a = document.createElement("a");    
      a.href = Buffer.from(data.userDownloadImage[0].data);
      a.download = file_alias;
      a.click();
      swalFire({title:"ダウンロードが完了しました。"})
    }
  } catch (error) {
    console.error(error)
  }
}

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

const pdfGenerate = (img,file_alias,typeView,size) => {
  var doc = new jsPDF(typeView, 'px', size);
  var width = doc.internal.pageSize.getWidth();
  var height = doc.internal.pageSize.getHeight();
  doc.addImage(img, null, 0, 0, size[0], size[1], null, 'FAST');
  doc.save(`${file_alias}.pdf`);
};

const downloadPdfFromBase64Image = async ({listFile, file_alias, handle}) => {
  listFile.sort(function(a, b) {
    let keyA = a.pageNumber,
      keyB = b.pageNumber;
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });

  let imageData = [];

  for(let i = 0; i < listFile.length ; i++){
    console.log("compress:",i)
    const img = await getImage(listFile[i].data);
    const oldWidth = img.naturalWidth;
    const oldHeight = img.naturalHeight;
    const canvas = document.createElement('canvas');
    canvas.width = oldWidth;
    canvas.height = oldHeight;

    // Draw the downscaled image on the canvas and return the new data URL.
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const newDataUrl = canvas.toDataURL("image/jpeg", 0.85);
    
    const new_img = await getImage(newDataUrl);
    imageData.push(new_img);
  }
  
  const typeView = imageData[0].width > imageData[0].height ? "l" : "p";

  let doc = new jsPDF(typeView,'px',[imageData[0].width,imageData[0].height],'','FAST');
  for(let i = 0; i < imageData.length ; i++){

    console.log("start create pdf img:",i)

    doc.addImage(imageData[i], 'png',0,0,imageData[i].width,imageData[i].height);
    if(i < imageData.length -1){
      const view = imageData[i+1].width > imageData[i+1].height ? "l" : "p";
      doc.addPage([imageData[i+1].width, imageData[i+1].height], view)
    }
  }


  // const imfile = await dataURLtoFile(images[i],"new-file.png");
//   let compress_file;
//   const compressedFile = await imageCompression(doc, options);
//   compress_file =  compressedFile;
//  const file =  URL.createObjectURL(compress_file)
  // const image = await getImage(base64Image);
  // const images = await splitImage(image, pageNumber);
  // let imgDatas = [];

  // for(let i = 0; i < images.length ; i++){
  //   const imfile = await dataURLtoFile(images[i],"new-file.png");
  //   let compress_file;
  //   const compressedFile = await imageCompression(imfile, options);
  //   compress_file = await compressedFile;
  //   imgDatas.push(URL.createObjectURL(compress_file))
  // }

  // const pdfHeight = image.height/pageNumber;
  // const typeView = image.width > pdfHeight ? "l" : "p"

  // var doc = new jsPDF(typeView, 'px', [image.width, pdfHeight]);
  // for(let i = 0; i < imgDatas.length; i++){
  //   doc.addImage(imgDatas[i], null, 0, 0, image.width, pdfHeight, null, 'FAST');
  //   if(i < imgDatas.length -1){
  //     doc.addPage()
  //   }
  // }

  if(handle) {
    const writableStream = await handle.createWritable();
    await writableStream.write(doc.output('blob'));
    await writableStream.close();
    return;
  }


  console.log("===========end create pdf==========")
  const fileName = file_alias.replace('_min.pdf', '').replace('.pdf','').replace('_0','').replace('_min','');
  if(imageData.length > 5){
    doc.save(`${fileName}_min.pdf`);
  }else{
    doc.save(`${fileName}.pdf`);
  }

  swalFire({title:"ダウンロードが完了しました。"})
}

function getImage(file) {
  return new Promise (function (resolved, rejected) {
    var i = new Image()
    i.onload = function(){
      resolved(i)
    };
    i.src = file
  })
}

async function splitImage(img, pageNumber) {
    const parts = []; // to push into oud base64 strings
    // const img = await getImage(base64)

      const h2 = img.height / pageNumber;

      for (let i = 0; i < pageNumber; i++) {
        const  y = (i*h2);
        const canvas = getClippedRegion(img, 0, y, img.width, img.height, pageNumber)
        parts.push(canvas.toDataURL()); // ("image/jpeg") for jpeg
      }
      return parts
}

function getClippedRegion(image, x, y, width, height, pageNumber) {

  var canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = height/pageNumber;

  ctx.drawImage(image, x, y, width, height/pageNumber,  0, 0, width, height/pageNumber);

  return canvas;
}


export const downloadCombinePDFAction = ({listImage, handle}) => {
  return async (dispatch, getState) => {
    dispatch(basic_loading(true))
    dispatch(basic_loading_status("ダウンロード中"))
    try {
      const {id} = getState().draw.info;
      const drawDetailIds = listImage?.map(({id}) => id);
      const data = await requestGraphQL(DOWNLOAD_COMBINE_PDF, {
        drawId: id,
        drawDetailIds,
      });
      await downloadPdfFromBase64Image({listFile: data.downloadCombinePDF, handle})
      swalFire({title:"ダウンロードが完了しました。"})
    } catch (error) {
      dispatch(basic_open_snackbar({t: "error", m: getErrorMessage(error)}))
    }
    dispatch(basic_loading(false))
    dispatch(basic_loading_status(""))
  }
}

const mergeBase64ImgToPdfBase64 = async (listFile) => {
  listFile.sort(function(a, b) {
    let keyA = a.pageNumber,
      keyB = b.pageNumber;
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });

  let imageData = [];
  for(let i = 0; i < listFile.length ; i++){
    const img = await getImage(listFile[i].data);
    const oldWidth = img.naturalWidth;
    const oldHeight = img.naturalHeight;
    const canvas = document.createElement('canvas');
    canvas.width = oldWidth;
    canvas.height = oldHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const newDataUrl = canvas.toDataURL("image/jpeg", 0.85);
    const new_img = await getImage(newDataUrl);
    imageData.push(new_img);
  }
  
  const typeView = imageData[0].width > imageData[0].height ? "l" : "p";

  let doc = new jsPDF(typeView,'px',[imageData[0].width,imageData[0].height],'','FAST');
  for(let i = 0; i < imageData.length ; i++){
    doc.addImage(imageData[i], 'png',0,0,imageData[i].width,imageData[i].height);
    if(i < imageData.length -1){
      const view = imageData[i+1].width > imageData[i+1].height ? "l" : "p";
      doc.addPage([imageData[i+1].width, imageData[i+1].height], view)
    }
  }

  const out = doc.output('blob');
  const i =await convertToBase64(out)
  return i.base64
}