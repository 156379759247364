import {combineReducers} from "redux"

import list from "./list";
import info from "./info";
import filter from "./filter";
import paging from "./paging";
import register_draw from "./register-draw";
import register_user from "./register-user";
import room_select from "./room_select";
import sub_draw from "./sub_draw";
import frame_list from "./frame_list";
const draw = combineReducers({
  list,
  info,
  filter,
  paging,
  register_draw,
  register_user,
  room_select,
  sub_draw,
  frame_list
});

export default draw;