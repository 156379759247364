import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import React from 'react';


export class SortColumn extends React.Component {
  render() {
    const {data} = this.props;
    if (!data) return <div/>;
    
    const {name, filter, onSort} = data;
    
    if (name === filter.sort_name && "desc" === filter.sort_type)
      return (
        <div className="cursor-pointer">
          <ArrowDropUp onClick={() => onSort({sort_name: name, sort_type: "asc"})}/>
        </div>
      );
    
    if (name === filter.sort_name && "asc" === filter.sort_type)
      return (
        <div className="cursor-pointer">
          <ArrowDropDown onClick={() => onSort({sort_name: name, sort_type: "desc"})}/>
        </div>);
    
    return (
      <div className="cursor-pointer">
        <ArrowDropDown onClick={() => onSort({sort_name: name, sort_type: "desc"})}/>
      </div>
    );
  }
}

