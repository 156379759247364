import {connect} from "react-redux";
import PageContainer from "../../containers/basic/page-container";
import { basic_set_is_modal_question } from "actions/basic";

const mapStateToProps = (state) => {
  return {
    me: state.basic.me,
    is_show_question: state.basic.is_show_question
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit:() => {},
    onCloseQuestion:()=>{
      dispatch(basic_set_is_modal_question(false))
    }
  }
};

const CPageContainer = connect(mapStateToProps, mapDispatchToProps)(PageContainer);

export default CPageContainer;