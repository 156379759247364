import React from "react"
import { CLOSE_HOC_MODAL, CHANGE_HOC_MODAL } from "../../constants/store"
const initialState = {
    isOpen: false,
    component: <></>
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case CLOSE_HOC_MODAL:
    return { ...state, ...payload }
  case CHANGE_HOC_MODAL:
    return { ...state, ...payload }
  default:
    return state
  }
}
