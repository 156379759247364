import React, {Fragment} from 'react';
import CLoading from "../../ccontainers/cbasic/cloading";
import CSnackbar from "../../ccontainers/cbasic/csnackbar";
import CRegisterUser from "../../ccontainers/common/cregister-user";
import HOCModal from '../../custom-components/hoc-modal/HOCModal';
import question from "../../assets/images/question.png";
import arrow from "../../assets/images/arrow-right.png";
class PageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loaded: false}
  }
  
  async componentDidMount() {
    await this.props.onInit();
    this.setState({loaded: true})
  }
  
  render() {
    if (!this.state.loaded) return <div/>;
    
    return (
      <Fragment>
        <CSnackbar/>
        <CLoading/>
        <CRegisterUser/>
        {this.props.children}
        <HOCModal />
        {
          this.props.is_show_question && 
          <div className='modal-question' onClick={this.props.onCloseQuestion}>
            {/* <img className='arrow-sidebar' src={arrow} alt='question'/> */}
            {/* <div ><i class="fa-solid fa-arrow-right-long"></i></div> */}
            <div className='modal-question-content'>
              <div className='modal-question-close' onClick={this.props.onCloseQuestion}><i className="fa-solid fa-xmark" style={{fontSize:"34px",color:"#9e9e9e"}}></i></div>
              <img src={question} alt='question' style={{}}/>
              {/* <div>このエリア上でCtrl+vを押すとスクリーンショットをアップロードできます。</div> */}
            </div>
          </div>
        }
        
      </Fragment>
    );
  }
}

export default PageContainer;