import {PROPERTY_LIST} from "../../constants/store";

const list = (state = [], action) => {
  switch (action.type) {
    case PROPERTY_LIST:
      return  action.payload.map(payload => Object.assign({}, payload));
    default:
      return state
  }
};

export default list;