import {createAction} from "redux-actions";
import {
  BASIC_LOADING, BASIC_CLOSE_SNACKBAR, BASIC_OPEN_SNACKBAR, BASIC_SET_ME, BASIC_ME, BASIC_ME_DRAW, BASIC_SET_WINDOW_SIZE, BASIC_LOADING_STATUS,BASIC_MODAL_QUESTION
} from "../constants/store";

export const basic_set_loading = createAction(BASIC_LOADING);
export const basic_set_open_snackbar = createAction(BASIC_OPEN_SNACKBAR);
export const basic_set_close_snackbar = createAction(BASIC_CLOSE_SNACKBAR);
export const basic_set_me = createAction(BASIC_SET_ME);
export const basic_set_me_draw = createAction(BASIC_ME_DRAW);

export const basic_loading = createAction(BASIC_LOADING);
export const basic_loading_status = createAction(BASIC_LOADING_STATUS);
export const basic_open_snackbar = createAction(BASIC_OPEN_SNACKBAR);
export const basic_close_snackbar = createAction(BASIC_CLOSE_SNACKBAR);
export const basic_me = createAction(BASIC_ME);
export const basic_me_draw = createAction(BASIC_ME_DRAW);
export const basic_set_is_modal_question = createAction(BASIC_MODAL_QUESTION);

export const basic_set_window_size = createAction(BASIC_SET_WINDOW_SIZE)