import { Box, Dialog, Modal } from '@mui/material'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeHOCModalAction } from '../../actions/hoc-modal';
import { TEXT_LIST } from '../../constants';
import "./HOCModal.css"

const HOCModal = memo(() => {
  const {isOpen, component} = useSelector(a => a.hocModal);
  const dispatch = useDispatch();

  return (
    // <Modal
    //   open={isOpen}
    //   onClose={() => {
    //     dispatch(closeHOCModalAction())
    //   }}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    // >
    //   <Box sx={{
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 1400,
    //     height: 700,
    //     bgcolor: '#fff',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 3
    //   }}>
    //     <div className='d-flex justify-content-end'>
    //       <div className='button-close' onClick={() => {
    //         dispatch(closeHOCModalAction())
    //       }}>{TEXT_LIST.close}</div>
    //     </div>
    //     {component}
    //   </Box>
    // </Modal>
    <Dialog
      open={isOpen}
      onClose={() => {
        dispatch(closeHOCModalAction())
      }}
      fullScreen
    >
      <Box sx={{
        // height: window.innerHeight,
        bgcolor: '#fff',
        // border: '2px solid #000',
        // boxShadow: 24,
        p: 3
      }}>
        <div className='d-flex justify-content-end'>
          <div className='button-close' onClick={() => {
            dispatch(closeHOCModalAction())
          }}>{TEXT_LIST.close}</div>
        </div>
        {component}
      </Box>
    </Dialog>
  )
})

export default HOCModal