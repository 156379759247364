import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { basic_set_open_snackbar } from 'actions/basic';
import {
  getListDrawSchedule,
  set_status_list_draw_schedule,
} from 'actions/schedule';
import { swalQuestion } from 'constants/swal';
import {
  compareDate,
  createGoogleCalendar,
  createOulook,
  getErrorMessage,
  requestGraphQL,
  subscription,
} from 'constants/utils';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CREATE_DRAW_SCHEDULE,
  DELETE_DRAW_SCHEDULE,
  UPDATE_STATUS_DRAW_SCHEDULE,
} from 'share/mutation';
import GoogleMeetImg from '../../../assets/images/google_calendar_icon.png';
import OutlookImg from '../../../assets/images/outlook.png';
import {
  TAB_DRAW_SCHEDULE,
  TEXT_LIST,
  refreshTask,
} from '../../../constants/index';
import ModalAssignTask from './modalAssignTask';
import ModalSchedule from './modalSchedule';
import './schedule.scss';

const Schedule = ({ draw_id, room_id, roleUser }) => {
  const dispatch = useDispatch();
  const listUser = useSelector((state) => state.schedule.list_user);
  const statusListDrawSchedule = useSelector(
    (state) => state.schedule.status_list_draw_schedule
  );
  const listDrawSchedule = useSelector(
    (state) => state.schedule.list_draw_schedule
  );
  const me = useSelector((state) => state.basic.me ?? state.basic.me_draw);
  const [isModalAssignTask, setModalAssignTask] = useState(false);
  const [isModalSchedule, setModalSchedule] = useState(false);
  const [dataForEditDrawSchedule, setDataForEditDrawSchedule] = useState();
  const [heightScheduleList, setheightScheduleList] = useState(null);
  let [subscriptionRefreshTask] = useState();

  useEffect(() => {
    loadSubsciptionTask();
    return () => {
      subscriptionRefreshTask.unsubscribe();
    };
  }, [draw_id, room_id, statusListDrawSchedule, subscriptionRefreshTask]);

  const loadSubsciptionTask = useCallback(() => {
    const { role, isShowTaskFinish } = statusListDrawSchedule;
    try {
      subscriptionRefreshTask = subscription
        .subscribe({
          query: refreshTask,
          variables: { room_id },
        })
        .subscribe({
          next() {
            dispatch(
              getListDrawSchedule({ draw_id, room_id, role, isShowTaskFinish })
            );
          },
        });
    } catch (error) {
      console.err(error);
    }
  }, [draw_id, room_id, statusListDrawSchedule, subscriptionRefreshTask]);

  const handleCloseModalAssignTask = useCallback(() => {
    setModalAssignTask(false);
  }, []);

  const handleCloseModalSchedule = useCallback(() => {
    setModalSchedule(false);
  }, []);

  const createAndUpdateDrawSchedule = useCallback(
    async (value) => {
      try {
        const data = await requestGraphQL(CREATE_DRAW_SCHEDULE, {
          ...value,
        });
        if (data && data.createDrawSchedule) {
          const { role, isShowTaskFinish } = statusListDrawSchedule;
          dispatch(
            getListDrawSchedule({ draw_id, room_id, role, isShowTaskFinish })
          );
          dispatch(
            basic_set_open_snackbar({
              m: value?.id ? '保存されました' : TEXT_LIST.create_task_success,
              t: 'success',
            })
          );
          handleCloseModalAssignTask();
          setDataForEditDrawSchedule();
        }
      } catch (err) {
        dispatch(
          basic_set_open_snackbar({ t: 'error', m: getErrorMessage(err) })
        );
      }
    },
    [statusListDrawSchedule, draw_id, room_id]
  );

  const handleChangeListTask = useCallback(
    ({ role, isShowTaskFinish }) => {
      if (role === undefined) role = statusListDrawSchedule.role;
      if (isShowTaskFinish === undefined)
        isShowTaskFinish = statusListDrawSchedule.isShowTaskFinish;
      try {
        dispatch(set_status_list_draw_schedule({ role, isShowTaskFinish }));
        dispatch(
          getListDrawSchedule({ draw_id, room_id, role, isShowTaskFinish })
        );
      } catch (err) {
        dispatch(
          basic_set_open_snackbar({ t: 'error', m: getErrorMessage(err) })
        );
      }
    },
    [draw_id, room_id, statusListDrawSchedule]
  );

  const handleUpdateStatusDrawSchedule = useCallback(
    async ({ id, status }) => {
      try {
        const { role, isShowTaskFinish } = statusListDrawSchedule;
        const data = await requestGraphQL(UPDATE_STATUS_DRAW_SCHEDULE, {
          id,
          status,
          room_id,
          finish_user_id: me.id,
        });
        if (data && data.updateStatusDrawSchedule) {
          // dispatch(
          //   getListDrawSchedule({ draw_id, room_id, role, isShowTaskFinish })
          // );
          dispatch(
            basic_set_open_snackbar({
              m: 'タスクが完了されました',
              t: 'success',
            })
          );
        }
      } catch (err) {
        dispatch(
          basic_set_open_snackbar({ t: 'error', m: getErrorMessage(err) })
        );
      }
    },
    [draw_id, room_id, statusListDrawSchedule, me]
  );

  const handleDeleteDrawSchedule = useCallback(
    async ({ id }) => {
      try {
        const { role, isShowTaskFinish } = statusListDrawSchedule;
        const confirmDelete = await swalQuestion({
          html: '削除してもよろしいですか？',
        }).then((result) => {
          return result.isConfirmed;
        });
        if (confirmDelete) {
          const res = await requestGraphQL(DELETE_DRAW_SCHEDULE, {
            id,
            room_id,
          });
          if (res && res.deleteDrawSchedule) {
            dispatch(
              getListDrawSchedule({ draw_id, room_id, role, isShowTaskFinish })
            );
            dispatch(
              basic_set_open_snackbar({
                m: TEXT_LIST.has_removed,
                t: 'success',
              })
            );
          }
        }
      } catch (err) {
        dispatch(
          basic_set_open_snackbar({ t: 'error', m: getErrorMessage(err) })
        );
      }
    },
    [draw_id, room_id, statusListDrawSchedule]
  );

  useEffect(() => {
    const cg_tooltip_elm = document.getElementById('cg-tooltip-disable');
    if (cg_tooltip_elm) {
      setheightScheduleList(cg_tooltip_elm.offsetHeight);
    }
  }, [document.getElementById('cg-tooltip-disable')]);

  const renderAction = useCallback(
    ({ status, item, id, to_user_id }) => {
      return (
        <Box className="d-flex align-items-center">
          <>
            {!status && (
              <ModeEditIcon
                className="cursor-pointer icon-crud"
                onClick={() => {
                  setModalAssignTask(true);
                  setDataForEditDrawSchedule(item);
                }}
              />
            )}
            <DeleteOutlineIcon
              className="cursor-pointer icon-crud"
              onClick={() => {
                handleDeleteDrawSchedule({ id, room_id });
              }}
            />
          </>
          {(to_user_id === me.id || !to_user_id) &&
            (status ? (
              <Button
                size="small"
                className="btn-color-unconfirm mb-3px"
                style={{
                  border: '1px solid black',
                  padding: '2px',
                }}
                onClick={() =>
                  handleUpdateStatusDrawSchedule({
                    id,
                    status: false,
                  })
                }
              >
                済
              </Button>
            ) : (
              <Button
                size="small"
                className="btn-color-light-blue fs-12px mb-3px"
                style={{
                  border: '1px solid black',
                  padding: '2px',
                }}
                onClick={() =>
                  handleUpdateStatusDrawSchedule({
                    id,
                    status: true,
                  })
                }
              >
                完了
              </Button>
            ))}
          {to_user_id && to_user_id !== me.id && status && (
            <Typography>済</Typography>
          )}
        </Box>
      );
    },
    [room_id, me]
  );

  return (
    <Box className="schedule-container fs-12px">
      <Stack>
        <Button
          onClick={() => setModalSchedule(!isModalSchedule)}
          className="mb-2 font-weight-bold btn-color-light-blue"
        >
          カレンダー表示
        </Button>
      </Stack>
      <Stack>
        <Button
          onClick={() => {
            setModalAssignTask(!isModalAssignTask);
            setDataForEditDrawSchedule();
          }}
          className="mb-2 font-weight-bold"
          variant="outlined"
        >
          新規作成
        </Button>
      </Stack>
      <Box className="d-flex mb-2 justify-content-between">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          className="mw-50"
          onChange={(e) => handleChangeListTask({ role: e.target.value })}
          defaultValue={TAB_DRAW_SCHEDULE.ALL}
          // blurOnSelect={true}
          sx={{ fontSize: '12px' }}
        >
          <MenuItem sx={{ fontSize: '12px' }} value={TAB_DRAW_SCHEDULE.ALL}>
            全て
          </MenuItem>
          <MenuItem sx={{ fontSize: '12px' }} value={roleUser}>
            自分のタスクのみ
          </MenuItem>
          {/* <MenuItem value={TAB_DRAW_SCHEDULE.GUEST}>{toUser && (toUser.name || toUser.email)}のタスク</MenuItem> */}
        </Select>
        <FormControlLabel
          className="ml-2 mr-0"
          control={
            <Checkbox
              defaultValue={statusListDrawSchedule.isShowTaskFinish}
              onChange={(e) =>
                handleChangeListTask({ isShowTaskFinish: e.target.checked })
              }
              checked={statusListDrawSchedule.isShowTaskFinish}
            />
          }
          label={<Typography sx={{ fontSize: '12px' }}>完了も表示</Typography>}
        />
      </Box>
      <Box
        className={` ${heightScheduleList ? 'list-task' : 'mb-1 list-task tasks-draw'}`}
        sx={{
          maxHeight: `${
            heightScheduleList
              ? `calc(100vh - ${
                  heightScheduleList + 54 + 45 + 45 + 48 + 330
                }px)`
              : '100px'
          }`,
        }}
      >
        {listDrawSchedule &&
          listDrawSchedule?.map((item) => {
            const {
              id,
              content,
              end_time,
              status,
              to_user_id,
              from_user_id,
              from_user,
              to_user,
              start_time,
            } = item;
            return (
              <Box
                key={id}
                className={`text-left d-flex justify-content-between schedule-item mb-2px ${
                  status && 'bg-confirmed'
                } ${from_user_id === me.id && 'bg-schedule-me'}`}
              >
                <Box className="w-100">
                  <Box className="d-flex justify-content-between align-items-center ">
                    <Typography className="fs-12px font-weight-bold">
                      {content}
                    </Typography>
                    <Box className="d-flex gap-10px">
                      <Tooltip title="Outlookに保存" placement="top">
                        {createOulook({
                          item: (
                            <img
                              className="mr-10px c-pointer"
                              src={OutlookImg}
                              alt="outlook"
                              width={20}
                              height={20}
                            />
                          ),
                          start_time:
                            start_time &&
                            moment(start_time).format('YYYY-MM-DDT00:00:00'),
                          end_time: moment(end_time).format(
                            'YYYY-MM-DDT00:00:00'
                          ),
                          title: content,
                        })}
                      </Tooltip>
                      <Tooltip title="Googleカレンダーに保存" placement="top">
                        {createGoogleCalendar({
                          item: (
                            <img
                              className="mr-10px c-pointer"
                              src={GoogleMeetImg}
                              alt="google meet"
                              width={20}
                              height={20}
                            />
                          ),
                          start_time:
                            start_time &&
                            moment(start_time).format('YYYYMMDDT000000Z'),
                          end_time: moment(end_time).format('YYYYMMDDT000000Z'),
                          title: content,
                        })}
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box className="d-flex justify-content-between align-items-end">
                    <Box>
                      <p>
                        担当: {from_user?.name || from_user?.email || 'なし'}
                      </p>
                      <p>
                        完了確認:
                        {(to_user && (to_user.name || to_user.email)) || 'なし'}
                      </p>
                      <p className={compareDate(end_time) && 'text-red'}>
                        {end_time && moment(end_time).format('yyyy年MM月DD日')}
                      </p>
                    </Box>
                    {renderAction({ status, item, id, to_user_id })}
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>

      <ModalAssignTask
        isModalAssignTask={isModalAssignTask}
        handleCloseModalAssignTask={handleCloseModalAssignTask}
        listUser={listUser}
        createAndUpdateDrawSchedule={createAndUpdateDrawSchedule}
        dataForEditDrawSchedule={dataForEditDrawSchedule}
        draw_id={draw_id}
        room_id={room_id}
      />

      <ModalSchedule
        isModalSchedule={isModalSchedule}
        handleCloseModalSchedule={handleCloseModalSchedule}
        listDrawSchedule={listDrawSchedule}
        handleChangeListTask={handleChangeListTask}
      />
    </Box>
  );
};

export default React.memo(Schedule);
