import { DRAW_FILTER } from "../../constants/store";

const filter = (state = null, action) => {
  switch (action.type) {
    case DRAW_FILTER:
      return Object.assign({}, action.payload);
    default:
      return state;
  }
}

export default filter;
