import {DRAW_REGISTER_USER} from "../../constants/store";

const state_default = {
  open_dialog: false,
  user_name: '',
  email: ''
};
const register_user = (state = state_default, action) => {
  switch (action.type) {
    case DRAW_REGISTER_USER:
      return Object.assign({}, action.payload);
    default:
      return state;
  }
}

export default register_user;
