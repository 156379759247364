import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React from 'react';
import { EXPANDED_CONST } from '../../../constants';

import CDocuments from 'ccontainers/common/cdocument';
import '../cg-modal.css';
import { CDocument } from 'ccontainers/cpages/user/draw/cdraw.components';
import { useSelector } from 'react-redux';

const AccordionDraw = ({ expanded, setexpanded }) => {
  const me = useSelector((state) => state?.basic?.me);
  return (
    <Accordion
      expanded={EXPANDED_CONST.LIST_IMAGE_DRAW === expanded}
      className={`cg-bar-ac accorion-draw`}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() =>
          setexpanded(
            expanded === EXPANDED_CONST.LIST_IMAGE_DRAW
              ? ''
              : EXPANDED_CONST.LIST_IMAGE_DRAW
          )
        }
        className="cg-item"
      >
        <Typography className="ac-parent-title">ファイル</Typography>
      </AccordionSummary>
      <AccordionDetails className="cg-details p-0">
        {me ? <CDocuments /> : <CDocument />}
      </AccordionDetails>
    </Accordion>
  );
};
export default React.memo(AccordionDraw);
