export const GET_ME = `
  query GetMe {
    me {
      id
      name
      email
      role
      state
      send_mail_when_unread
      personal
      zoom_email
      created_default_tags
      company {
        id
        name
        company_type
        address
        tel
        fax
        website
        email
        information
        allow_trade
        company_types_allowed
        allowed_to_invite
        show_buyer_tab
        zip
        line
      }
      host
    }
  }
`;

export const GET_ME_DRAW = `
  query GetMeDraw {
    meDraw {
      id
      name
      email
      role
      temporary
      temporary_date
      company {
        id
        name
        company_type
      }
    }
  }
`;

export const GET_ROOM_INFO_BY_ROOM_ID = `
  query getRoomInfoByRoomId($id: ID!) {
    getRoomInfoByRoomId(id: $id) {
      id
      property_id
      company_type
      from_company_id
      from_user_id
      to_user_id
      line_seller_receive_notif
    }
  }
`;

export const GET_ROOM_INFO_BY_DRAW_ID = `
  query getRoomInfoByDrawId($id: ID!) {
    getRoomInfoByDrawId(id: $id) {
      id
      property_id
      company_type
      from_company_id
      from_user_id
      to_user_id
      line_seller_receive_notif
    }
  }
`;


export const GET_PROPERTY = `
query GetProperty($id: ID!) {
  getProperty(id: $id) {
    id
    user {
      id
      name
    }
    company {
      id
      name
    }
    company_contract_id
    user_contract_id
    progress
    directory
    name
    address
    building_type
    room_never_read
    user_type
    assigned_user
    building_document
    variety_house {
      id
      name
      url
      drawings
      allowed_to_invite_tab
    }
    property_job_type_first {
      id
      name_eng
      name
      allowed_to_buy
    }
  }
}
`;

export const GET_DRAWS_WITH_PAGING = `
query getDrawsWithPaging($filter: DrawFilter, $current_page: Int!, $per_page: Int!) {
  getDrawsWithPaging (filter: $filter, current_page: $current_page, per_page: $per_page) {
    draws {
      id
      name
      user_name
      user_address_book {
        id
        to_user_id
        user_name
      }
      to_user {
        id
        email
        mailconfirmed
      }
      room_by_user {
        id
        name
        to_user_id
        property_id
        property_job_type_id
        property {
          id
          variety_house {
            name
          }
        }
        line_seller_receive_notif
      }
      createdAt
      updatedAt
      updatedAtDetail
      room_id
    }
    current_page
    total_page
    per_page
   }
}`;


export const GET_DRAW = `
  query getDraw($drawId: String!) {
    getDraw (drawId: $drawId) {
      id
      name
      user_address_book_id
      user_name
      room_id
      url_confirm
      details {
        id
        draw_id
        from_confirmed
        to_confirmed
        attachment {
          id
          file_name
          file_type
          url
          url_resize
          file_alias
        }
        elements
        process
        processedAt
        owner_id
        createdAt
      }
      room_by_user {
        id
        name
        to_user_id
        property_id
        property {
          id
          variety_house {
            name
          }
        }
        line_seller_receive_notif
      }
      from_company_id
      from_user_id
    }
  }
`;
// Get project information and images
export const GET_DRAW_BY_ID = `
  query getDrawById($drawId: String!) {
    getDrawById (drawId: $drawId) {
      id
      name
      user_address_book_id
      user_name
      room_id
      to_user{
        email
        name
        id
      }
      from_user {
        company {
          id
          name
        }
      }
      cg_u_id
      user_address_book{
        user_name
      }
      cg_property_id
      details {
        id
        draw_id
        from_confirmed
        to_confirmed
        attachment {
          id
          file_name
          file_type
          url
          url_resize
          file_alias
        }
        elements
        process
        processedAt
        owner_id
        createdAt
        parent_id
        text_position
      }
      room_by_user {
        id
        name
        to_user_id
        property_id
        property {
          id
          variety_house {
            name
          }
        }
        property_job_type_id
        line_seller_receive_notif
      }
      from_user_id
      cg_frame_list
    }
  }
`;

export const GET_EMAIL_NOT_LOGIN_FOR_DRAW = `
  query GetEmailNotLoginForDraw($draw_id: String!) {
    getEmailNotLoginForDraw(draw_id: $draw_id)
  }
`;

// User get project infomation and images
export const USER_GET_DRAW_BY_ID = `
    query userGetDrawById($drawId: String!) {
      userGetDrawById (drawId: $drawId) {
            cg_frame_list
            id
            name
            user_name
            room_id
            user_address_book_id
            to_user{
              email
              name
              id
            }
            user_address_book{
              user_name
            }
            cg_property_id
            cg_u_id
            details {
                id
                draw_id
                from_confirmed
                to_confirmed
                attachment {
                    id
                    file_name
                    file_type
                    url
                    url_resize
                    file_alias
                }
                elements
                process
                processedAt
                owner_id
                createdAt
                text_position
            }
            room_by_user {
              id
              name
              to_user_id
              property_id
              property {
                id
                variety_house {
                  name
                }
              }
              property_job_type_id
              line_seller_receive_notif
            }
      }
    }
`;

export const USER_GET_DRAW_DETAIL_BY_ID = `
    query userGetDrawDetailById($drawDetailId: String!) {
      userGetDrawDetailById (drawDetailId: $drawDetailId) {
      id
      draw_id
      from_confirmed
      to_confirmed
      attachment {
          id
          file_name
          file_type
          url
          url_resize
          file_alias
      }
      elements
      process
      processedAt
      owner_id
    }
  }
`;

export const USER_GET_NOTIFICATIONS = `
    query userGetNotifications {
      userGetNotifications {
        id,
        title
        link,
        content,
        type,
        createdAt
      }
    }
`;

export const DOWNLOAD_IMAGE = `
  query downloadImage($drawId: ID!, $drawDetailId: ID!) {
    downloadImage(drawId: $drawId, drawDetailId: $drawDetailId) {
      data
      fileName
      pageNumber
    }
  }
`;

export const USER_DOWNLOAD_IMAGE = `
  query userDownloadImage($drawId: ID!, $drawDetailId: ID!) {
    userDownloadImage(drawId: $drawId, drawDetailId: $drawDetailId) {
      data
      fileName
      pageNumber
    }
  }
`;

export const DOWNLOAD_COMBINE_PDF = `
  query downloadCombinePDF($drawId: ID!, $drawDetailIds: [ID]!) {
    downloadCombinePDF(drawId: $drawId, drawDetailIds: $drawDetailIds) {
      data
      fileName
      pageNumber
    }
  }
`;

export const GET_FIREBASE_TOKEN = `
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      firebase_token
      device_firebase_token
      company {
        company_type
      }
    }
  }
`;

export const GET_ALL_USER_BELONG_DRAW = `
  query getAllUserBelongDraw($draw_id: ID!) {
    getAllUserBelongDraw(draw_id: $draw_id) {
      id
      name
      email
    }
  }
`

export const GET_LIST_DRAW_SCHEDULE = `
  query getListDrawSchedule($draw_id: ID, $room_id: ID, $role: String, $isShowTaskFinish: Boolean) {
    getListDrawSchedule(draw_id: $draw_id, room_id: $room_id, role: $role, isShowTaskFinish: $isShowTaskFinish) {
      id
      from_user_id
      to_user_id
      start_time
      end_time
      content
      status
      from_user {
        id
        name
        email
      }
      to_user {
        id
        name
        email
      }
    }
  }
`
export const GET_USER_ADDRESS_BOOK = `
  query getUserAddressBookById($id: String) {
    getUserAddressBookById(id: $id) {
      user_name
    }
  }
`

export const GET_SUB_DRAW = `
    query getSubDraws($draw_parent_id: String) {
      getSubDraws(draw_parent_id: $draw_parent_id) {
        id
        draw_id
        from_confirmed
        to_confirmed
        attachment {
            id
            file_name
            file_type
            url
            url_resize
            file_alias
        }
        elements
        process
        processedAt
        owner_id
        createdAt
        parent_id
        text_position
      }
    }
`;


export const GET_DRAW_INFO = `
  query getDrawById($drawId: String!) {
    getDrawById (drawId: $drawId) {
      id
      name
      user_address_book_id
      user_name
      room_id
      to_user{
        email
        name
        id
      }
      user_address_book{
        user_name
      }
      cg_u_id
      cg_property_id
      from_user_id
      room_by_user {
        id
        name
        to_user_id
        property_id
        property {
          id
          variety_house {
            name
          }
        }
        property_job_type_id
        line_seller_receive_notif
      }
      cg_frame_list
    }
  }
`;

export const CG_GET_PROPERTY_VIEW = `
  query getPropertyView($id: String!) {
    getPropertyView(id: $id)
  }
`;

export const GET_USER_PROPERTY_BY_PROPERTY_ID = `
  query getUserPropertyNyPropertyId($property_id: String!) {
    getUserPropertyByPropertyId(property_id: $property_id) {
      id
      user_id
      property_id
      bundle_data
    }
  }
`;

export const GET_RECORDS_BY_PROPERTY_ID = `
  query getRecordByPropertyId($property_id: String!,$draw_id: String) {
    getRecordByPropertyId(property_id: $property_id, draw_id: $draw_id) {
      id
      is_save
      record_name
      user_id
      property_id
      material_data
      draw_id
      extension_data
    }
  }
`;

export const GET_CGS_FRAMES_INDEX = `
  query getCgAndFrameListIndex($draw_id: String!) {
    getCgAndFrameListIndex(draw_id: $draw_id)
  }
`;

export const CONVERT_DATA_CSV_TO_BASE64 = `
  query convertDataCsvToBase64($data: [JSON]) {
    convertDataCsvToBase64(data: $data)
  }
`;