import { DRAW_FRAME_LIST } from "constants/store";

const frame_list = (state = [], action) => {
  switch (action.type) {
    case DRAW_FRAME_LIST:
      return action.payload;
    default:
      return state;
  }
}

export default frame_list;
