import { GET_ALL_USER_BELONG_DRAW, GET_ALL_USER_BELONG_DRAW_CONST } from "constants/store";

const list_user = (state = [], action) => {
  switch (action.type) {
    case GET_ALL_USER_BELONG_DRAW_CONST:
      return Object.assign([], action.payload);
    default:
      return state;
  }
};

export default list_user;