import {DRAW_PAGING} from "../../constants/store"

const body = (state={}, action) => {
  switch (action.type) {
    case DRAW_PAGING:
      return action.payload;
    default:
      return state;
  }
}

export default body;
