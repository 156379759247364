import { setCookieXToken } from 'constants/utils';
import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
export default function Cookies() {
    const { cookie, draw } = useParams();
    useEffect(() => {
      if(cookie && draw){
        const isSetCookies = setCookieXToken(cookie)
        if(isSetCookies){
            window.location.replace(`${window.location.origin}/confirm/${draw}`)

        }
        
      }
    }, [cookie,draw])
    
  return (
    <div></div>
  )
}
