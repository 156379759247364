import {DOCUMENT_FILTER} from "../../constants/store";

const filter_default = {type: '', view: 'gird'};
const filter = (state = filter_default, action) => {
  switch (action.type) {
    case DOCUMENT_FILTER:
      return Object.assign({}, action.payload);
    default:
      return state;
  }
};

export default filter;