import React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TEXT_LIST } from '../../constants/index';
import { InputAdornment } from '@mui/material';

class RegisterUserDialog extends React.Component {
  render() {
    const {
      register_user,
      draw_info,
      onChangeValue,
      onChangeOpenRegisterUser,
      assignUserToDraw,
    } = this.props;
    if (!register_user.open_dialog || !draw_info) return <div />;
    const { user_name, email } = register_user;

    return (
      <div>
        <Dialog open={true}>
          <DialogTitle
            className="text-center"
            sx={{
              fontWeight: '900',
            }}
          >
            お客様を招待
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                color: 'black',
              }}
            >
              お客様を招待する場合はご入力下さい
              <br />
              後で招待することもできます。
            </DialogContentText>
            <div className="w-100 pt-3 d-flex">
              <label className="w-40 m-auto fs-1rem">お客様名</label>
              <TextField
                value={user_name || ''}
                className="float-right"
                size="small"
                sx={{ width: '300px' }}
                InputProps={{
                  endAdornment: <InputAdornment position="start">様</InputAdornment>,
                }}
                onChange={(e) => onChangeValue('user_name', e.target.value)}
              />
            </div>
            <div className="w-100 pt-1 d-flex">
              <label className="w-40 m-auto fs-1rem">
                {TEXT_LIST.email_address}
              </label>
              <TextField
                value={email || ''}
                className="float-right"
                size="small"
                sx={{ width: '300px' }}
                onChange={(e) => onChangeValue('email', e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions className="m-auto pb-4">
            <button
              className="btn-main mr-2"
              onClick={() => onChangeOpenRegisterUser({ register_user })}
            >
              {TEXT_LIST.cancel}
            </button>
            <button
              className="btn-main mr-2 main-bg"
              onClick={() => {
                assignUserToDraw({ draw_id: draw_info.id, user_name, email });
              }}
            >
              {TEXT_LIST.invitation}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default RegisterUserDialog;
