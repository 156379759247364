import { DRAW_INFO } from "../../constants/store";

const info = (state = {}, action) => {
  switch (action.type) {
    case DRAW_INFO:
      return Object.assign({}, action.payload);
    default:
      return state;
  }
}

export default info;
