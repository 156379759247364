import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import ja from "date-fns/locale/ja";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import './schedule.scss';
registerLocale("ja", ja);

const ModalAssignTask = ({
  isModalAssignTask,
  handleCloseModalAssignTask,
  listUser,
  createAndUpdateDrawSchedule,
  dataForEditDrawSchedule,
  draw_id,
  room_id,
}) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDatePickerEndTime, setOpenDatePickerEndTime] = useState(false);
  const [openStartTime, setOpenStartTime] = useState(false);
  const [stateChooseTime, setStateChooseTime] = useState(false);
  const [objectDefault] = useState({
    start_time: null,
    end_time: null,
    content: null,
    from_user_id: null,
    to_user_id: null,
    draw_id,
    room_id,
  });
  const [stateAssignTask, setStateAssignTask] = useState({
    ...objectDefault,
    room_id,
    draw_id,
  });
  const [stateError, setStateError] = useState();

  useEffect(() => {
    if (!draw_id) return;
    setStateAssignTask((prev) => ({
      ...prev,
      draw_id,
      room_id,
    }));
  }, [draw_id, room_id]);

  useEffect(() => {
    if (!dataForEditDrawSchedule) {
      setOpenStartTime(false);
      return setStateAssignTask({ ...objectDefault, room_id, draw_id });
    }
    const { id, start_time, end_time, content, from_user_id, to_user_id } =
      dataForEditDrawSchedule;
    setStateAssignTask((prev) => ({
      ...prev,
      id,
      start_time: start_time && new Date(start_time),
      end_time: end_time && new Date(end_time),
      content,
      from_user_id,
      to_user_id,
    }));
    if (start_time) {
      setOpenStartTime(true);
    }
    if (!start_time && !end_time) {
      setStateChooseTime(true);
    }
  }, [dataForEditDrawSchedule, draw_id, room_id]);

  const onChangeDatePicker = useCallback((dates) => {
    const [start_time, end_time] = dates;
    setStateAssignTask((prev) => ({
      ...prev,
      start_time,
      end_time: end_time ?? prev.end_time,
    }));
  }, []);

  const renderListUser = useMemo(() => {
    return listUser.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name || item.email}
        </MenuItem>
      );
    });
  }, [listUser]);

  const resetModal = useCallback(() => {
    handleCloseModalAssignTask();
    setOpenStartTime();
    setStateChooseTime(false);
    setStateAssignTask({ ...objectDefault, room_id, draw_id });
    setStateError();
  }, [
    handleCloseModalAssignTask,
    setOpenStartTime,
    setStateChooseTime,
    setStateAssignTask,
    setStateError,
    room_id,
    draw_id,
  ]);

  return (
    <Modal open={isModalAssignTask} onClose={resetModal}>
      <Box className="modal-assign-task">
        <textarea
          rows={4}
          fullWidth
          className="mb-2 w-100"
          placeholder="タスクを入力してください"
          onChange={(e) => {
            const content = e.target.value;
            setStateAssignTask((prev) => ({
              ...prev,
              content,
            }));
          }}
          defaultValue={stateAssignTask.content}
        />
        <Box className="d-flex align-items-center mb-2">
          <Typography className="mr-2 w-30">担当</Typography>
          <Select
            size="small"
            fullWidth
            onChange={(e) => {
              setStateAssignTask((prev) => ({
                ...prev,
                from_user_id: e.target.value,
              }));
            }}
            value={stateAssignTask.from_user_id}
            displayEmpty
          >
            <MenuItem key={'default'} value={null}>
              担当なし
            </MenuItem>
            {renderListUser}
          </Select>
        </Box>
        <Box className="d-flex align-items-center mb-2">
          <Typography className="mr-2 w-30">完了確認</Typography>
          <Select
            size="small"
            fullWidth
            onChange={(e) => {
              setStateAssignTask((prev) => ({
                ...prev,
                to_user_id: e.target.value,
              }));
            }}
            value={stateAssignTask.to_user_id}
            displayEmpty
          >
            <MenuItem key={'default'} value={null}>
              担当なし
            </MenuItem>
            {renderListUser}
          </Select>
        </Box>
        {!stateChooseTime && (
          <>
            <Box className="d-flex">
              {/* <Typography className="w-8"></Typography> */}
              <Box className="w-30">
                {openStartTime && <Typography>開始</Typography>}
                <Typography className="mt-2">期限</Typography>
              </Box>
              <DatePicker
                onChange={onChangeDatePicker}
                startDate={stateAssignTask.start_time}
                endDate={
                  stateAssignTask.start_time ? stateAssignTask.end_time : ''
                }
                maxDate={stateAssignTask.end_time}
                selectsRange
                dateFormat="yyyy/MM/dd"
                locale={'ja'}
                open={openDatePicker}
                onClickOutside={() => setOpenDatePicker(false)}
                customInput={<></>}
              />
              <DatePicker
                onChange={(end_time) =>
                  setStateAssignTask((prev) => ({ ...prev, end_time }))
                }
                selected={stateAssignTask.end_time}
                dateFormat="yyyy/MM/dd"
                locale={'ja'}
                open={openDatePickerEndTime}
                onClickOutside={() => setOpenDatePickerEndTime(false)}
                minDate={stateAssignTask.start_time}
                customInput={<></>}
              />
              <Stack>
                <Box className="d-flex align-items-center">
                  {openStartTime && (
                    <CalendarMonthIcon
                      onClick={() => {
                        setOpenDatePicker(!openDatePicker);
                      }}
                    />
                  )}
                  {stateAssignTask.start_time && (
                    <>
                      <Typography className="invisible ml-2 mr-2">~</Typography>
                      <span className="border-time">
                        {moment(stateAssignTask.start_time).format(
                          'yyyy年MM月DD日'
                        )}
                      </span>
                    </>
                  )}
                </Box>
                {
                  <Box className="d-flex align-items-center mt-2">
                    <CalendarMonthIcon
                      className="cursor-pointer"
                      onClick={() =>
                        setOpenDatePickerEndTime(!openDatePickerEndTime)
                      }
                    />
                    {stateAssignTask.end_time && (
                      <>
                        <Typography className="ml-2 mr-2">~</Typography>
                        <span className="border-time">
                          {moment(stateAssignTask.end_time).format(
                            'yyyy年MM月DD日'
                          )}
                        </span>
                      </>
                    )}
                  </Box>
                }
              </Stack>
            </Box>
            <Box className="d-flex align-items-center">
              <Typography className="w-30 mr-2px">開始日も入力</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      stateAssignTask.start_time || openStartTime ? true : false
                    }
                    onChange={(e) => {
                      setOpenStartTime(!openStartTime);
                      if (!e.target.checked) {
                        setStateAssignTask((prev) => ({
                          ...prev,
                          start_time: null,
                        }));
                      }
                    }}
                  />
                }
              />
            </Box>
          </>
        )}

        <Box className="d-flex align-items-center">
          <Typography className="w-30 mr-2px">期限なし</Typography>
          <FormControlLabel
            className="checkbox-show-time"
            control={
              <Checkbox
                defaultChecked={
                  stateAssignTask.start_time ||
                  stateAssignTask.end_time ||
                  !stateChooseTime
                    ? false
                    : true
                }
                onChange={(e) => {
                  setStateChooseTime(!stateChooseTime);
                  if (e.target.checked) {
                    setStateAssignTask((prev) => ({
                      ...prev,
                      start_time: null,
                      end_time: null,
                    }));
                  }
                }}
              />
            }
          />
        </Box>
        {!stateChooseTime && stateError && stateError.start_time && (
          <Typography className="show-error">
            * 開始時間を選択してください
          </Typography>
        )}
        {!stateChooseTime && stateError && stateError.end_time && (
          <Typography className="show-error">
            * 終了時間を選択してください
          </Typography>
        )}
        <Box className="d-flex">
          <Box className="ml-auto">
            <Button
              className="mb-2 btn-schedule mr-2"
              variant="outlined"
              size="small"
              onClick={handleCloseModalAssignTask}
            >
              キャンセル
            </Button>
            <Button
              className="mb-2 btn-schedule btn-color-main text-light"
              variant="outlined"
              size="small"
              onClick={() => {
                if (
                  !stateChooseTime &&
                  !stateAssignTask.start_time &&
                  openStartTime
                ) {
                  return setStateError((prev) => ({
                    ...prev,
                    start_time: true,
                  }));
                }
                if (!stateChooseTime && !stateAssignTask.end_time) {
                  return setStateError((prev) => ({
                    ...prev,
                    end_time: true,
                  }));
                }
                createAndUpdateDrawSchedule(stateAssignTask);
                resetModal();
              }}
            >
              {stateAssignTask?.id ? '保存' : '作成'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default React.memo(ModalAssignTask);
