import {combineReducers} from "redux";
import filter from "./filter";
import list from "./list";
import dialog from "./dialog";
import info from "./info";
import storage from "./storage";

const document = combineReducers({
  filter,
  list,
  dialog,
  info,
  storage,
});

export default document;
