import { CONFIRM_LIST, SET_CONFIRM_ID, SET_OPEN_CONFIRM_NOTIFICATION } from "../../constants/store"

const initialState = {
    list: [],
    confirmedId: null,
    openNotification: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case CONFIRM_LIST:
    return { ...state, list: payload }
  case SET_CONFIRM_ID:
    return { ...state, confirmedId: payload }
  case SET_OPEN_CONFIRM_NOTIFICATION:
    return { ...state, openNotification: payload }
  default:
    return state
  }
}
