import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { basic_open_snackbar, basic_set_loading, basic_set_open_snackbar } from '../../actions/basic';
import {
  draw_set_filter,
  draw_set_info,
  draw_set_list,
  draw_set_paging,
  draw_set_register_draw,
  draw_set_room_list,
  draw_set_room_select,
} from '../../actions/draw';
import { TEXT_LIST } from '../../constants/index';
import { swalConfirmDelete } from '../../constants/swal';
import {
  clientUrl,
  createClient,
  getErrorMessage
} from '../../constants/utils';
import Draws from '../../containers/pages/draws';
import {
  CHANGE_MAIL_DRAW_IN_USER_ADDRESS_BOOK,
  REGISTER_DRAW,
  REMOVE_DRAW,
} from '../../share/mutation';
import { GET_DRAWS_WITH_PAGING } from '../../share/query';
import { onChangeOpenRegisterUser } from '../common/cregister-user';
import { HOST_ADDR, PCHAT_DOMAIN } from 'constants/setting';

let myState = {
  current_page: 1,
  per_page: 10,
};

const mapStateToProps = (state) => {
  return {
    me: state.basic.me,
    list: state.draw.list,
    filter: state.draw.filter,
    paging: state.draw.paging,
    register_draw: state.draw.register_draw,
    register_user: state.draw.register_user,
    room_select: state.draw.room_select,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: async ({ me }) => {
      myState.filter_info = { name: '', sort_name: '', sort_type: '' };
      // if(!me.company) {
        // return Swal.fire({
        //   title: '<h4>アクセス権がありません\n ブラウザを閉じてください。</h4>',
        //   // text: '確認できました。ブラウザを閉じてください。',
        //   icon: 'success',
        //   showConfirmButton: false,
        // });
        // return history.push(`/confirm/${draw_id}`)
      // }
      if (
        me?.company?.company_type === 'seller' || me?.company?.company_type === 'general' &&
        ['admin-assigned', 'admin', 'user'].includes(me.role)
      ) {
         return onLoadDraws(dispatch);
      }
    },
    onChangeFilter: (field, val) => {
      myState.filter_info[field] = val;
      onLoadDraws(dispatch);
    },
    onChangePage: (page_number) => {
      if (page_number !== myState.current_page) {
        myState.current_page = page_number;
        onLoadDraws(dispatch);
      }
    },
    onSort: ({ sort_name, sort_type }) => {
      myState.filter_info.sort_name = sort_name;
      myState.filter_info.sort_type = sort_type;
      onLoadDraws(dispatch);
    },
    onChangeOpenRegisterDraw: ({ register_draw }) => {
      register_draw.open_dialog = !register_draw.open_dialog;
      dispatch(draw_set_register_draw(register_draw));
    },
    onChangeOpenRegisterUser: (draw) => {
      dispatch(draw_set_info(draw));
      onChangeOpenRegisterUser({ dispatch });
    },
    onChangeValueRegister: (register_draw, name, value) => {
      register_draw[name] = value;
      dispatch(draw_set_register_draw(register_draw));
    },
    onChangeRemove: async (id, name) => {
      const remove = await swalConfirmDelete({
        html: name + 'を削除してよろしいですか？',
      }).then((result) => {
        return result.isConfirmed;
      });
      if (!remove) return true;

      const client = createClient();
      client
        .request(REMOVE_DRAW, { id })
        .then((data) => {
          const { removeDraw } = data;
          if (removeDraw) {
            dispatch(
              basic_set_open_snackbar({ m: TEXT_LIST.has_removed, t: 'error' })
            );
            dispatch(draw_set_register_draw({ open_dialog: false }));
            onLoadDraws(dispatch);
          }
        })
        .catch((e) => {
          const error = getErrorMessage(e);
          dispatch(basic_set_open_snackbar({ m: error, t: 'error' }));
        });
    },
    registerDraw: ({ name }) => {
      if (!name) {
        dispatch(
          basic_set_open_snackbar({
            m: 'プロジェクト名を入力してください',
            t: 'error',
          })
        );
        return false;
      }
      const client = createClient();
      client
        .request(REGISTER_DRAW, { mode: 'ADD', drawInput: { name } })
        .then((data) => {
          const { registerDraw } = data;
          if (registerDraw) {
            dispatch(
              basic_set_open_snackbar({
                m: TEXT_LIST.has_registered,
                t: 'success',
              })
            );
            dispatch(draw_set_register_draw({ open_dialog: false }));
            onLoadDraws(dispatch);
            let url_draw_client = `https://draw.test-mina-toku.jp/draws/${registerDraw.id}`;
            if(process.env.REACT_APP_CURR_ENV === "production"){
              url_draw_client = `https://draw.mina-toku.jp/draws/${registerDraw.id}`;
            }
            window.open(url_draw_client, "_blank")
          }
        })
        .catch((e) => {
          const error = getErrorMessage(e);
          dispatch(basic_set_open_snackbar({ m: error, t: 'error' }));
        });
    },
    onCopyUrl: (url) => {
      navigator.clipboard.writeText(`${clientUrl}/confirm/${url}`);
      dispatch(
        basic_set_open_snackbar({
          t: 'success',
          m: 'URLがコピーされました',
        })
      );
    },
    onChangeMailConfirmed: async ({ id, email }) => {
      if(!id) {
        dispatch(
          basic_set_open_snackbar({
            t: 'error',
            m: 'まずはお客様をお誘いください',
          })
        );
        return;
      }
      const { value } = await Swal.fire({
        title: '<h5>変更するメールアドレスを入力してください</h5>',
        input: 'email',
        inputPlaceholder: 'メールアドレスを入力してください',
        validationMessage: '電子メールの無効化',
        inputValue: email,
        showCancelButton: true,
        cancelButtonText: TEXT_LIST.cancel,
        confirmButtonText: TEXT_LIST.btn_change,
      });
      if (!value) return;
      const client = createClient();
      try {
        const res = await client.request(
          CHANGE_MAIL_DRAW_IN_USER_ADDRESS_BOOK,
          { id, email: value }
        );
        if (res.changeMailDrawInUserAddressBook) {
          onLoadDraws(dispatch);
          dispatch(
            basic_set_open_snackbar({
              m: TEXT_LIST.has_registered,
              t: 'success',
            })
          );
        }
      } catch (err) {
        dispatch(basic_set_open_snackbar({ m: getErrorMessage(err), t: 'error' }));
      }
    },
    onSelectRoomForSendMessageConfirm: ({value, draw_id}) => {
      dispatch(draw_set_room_list({value, draw_id}));
    },
    navigateToChatRoom: (room_by_user) => {
      const {id, property_id, property_job_type_id} = room_by_user ?? {};
      if(!id) return dispatch(basic_open_snackbar({t: "error", m: 'お部屋をお選びください'}));
      const url = `${PCHAT_DOMAIN}/r/${property_id}/tab-id/${property_job_type_id}/general/${id}`
      return window.open(url, "_blank")
    }
  };
};

export const onLoadDraws = (dispatch) => {
  const { filter_info } = myState;
  const cpFilter = Object.assign({}, filter_info);

  dispatch(basic_set_loading(true));
  const client = createClient();
  const variables = {
    filter: cpFilter,
    current_page: myState.current_page,
    per_page: myState.per_page,
  };

  client
    .request(GET_DRAWS_WITH_PAGING, variables)
    .then((data) => {
      const { getDrawsWithPaging } = data;
      const { draws, current_page, total_page } = getDrawsWithPaging;
      dispatch(draw_set_list(draws));
      dispatch(draw_set_filter(filter_info));
      dispatch(draw_set_paging({ current_page, total_page }));
      dispatch(basic_set_loading(false));
    })
    .catch((e) => {
      dispatch(basic_set_loading(false));
      const errorMessage = getErrorMessage(e);
      return dispatch(basic_set_open_snackbar({ m: errorMessage, v: 'error' }));
    });
};

const CDraws = connect(mapStateToProps, mapDispatchToProps)(Draws);

export default CDraws;
