import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./CustomAccordion.css"
// import Button from '@mui/material/Button';
// import { useDispatch, useSelector } from 'react-redux';
// import { basic_set_open_snackbar } from 'actions/basic';
// import { TEXT_LIST } from '../../constants';
// import { REGISTER_USER_PROPERTY_3D, UPDATE_AGREED_USER_PROPERTY } from 'share/mutation';
// import { getErrorMessage } from 'utils/common';
// import { createClient3D } from 'constants/utils';
// import { GET_USER_PROPERTY_BY_PROPERTY_ID } from 'share/query';
import { useEffect } from 'react';

export default function CustomAccordion({id,material={},expandChild,children,onChangeExpandChild,extension={},temporaryRecordList}) {
    const [price, setprice] = useState("")
    const [mActive, setmActive] = useState([])
    const [itemPrice, setitemPrice] = useState("")
//    const me = useSelector((a)=>a.basic.me)
//    const dispatch = useDispatch()

    const handleShowDetail = (data)=>{
        onChangeExpandChild(data)
    }

    useEffect(() => {
        let temp = [];
        setmActive([])
        if(temporaryRecordList && temporaryRecordList.length > 0){
            temporaryRecordList.forEach((el,index) => {
                temp.push(el.material_id)
                if(el.material_id === material.id){
                    for(let i = 0; i < material.category_group.category_group_details.length; i++){
                        if(material.category_group.category_group_details[i].category && material.category_group.category_group_details[i].category.id === el.category_id){
                            let price_amount = parseFloat(material.area) * parseFloat(material.category_group.category_group_details[i].price);
                            setprice(price_amount)
                            setitemPrice(material.category_group.category_group_details[i].price)
                            break;
                        }
                    }
                    
                }
            });
            setmActive(temp)
        }
        
    }, [temporaryRecordList]);
    

  return (
    <>
    {
        id !== "extensions" ?
        <Accordion className="accordion-custom-root" expanded={id === "combos" ? expandChild === "combos" : expandChild === material.id}>
            {
                id === "combos" ?
                <AccordionSummary className='cg-child-summary'>
                    <div className='cg-child-title' onClick={()=>handleShowDetail("combos")}>
                        <ExpandMoreIcon style={{transform:expandChild === "combos" ? "rotate(0deg)" : "rotate(-90deg)"}} />
                        <p>選択セット</p>
                    </div>
                </AccordionSummary>
                :
                <AccordionSummary className='cg-child-summary'>
                    <div className='cg-child-title' onClick={()=>handleShowDetail(material.id)}>
                        <ExpandMoreIcon style={{transform:expandChild === material.id ? "rotate(0deg)" : "rotate(-90deg)"}} />
                        <div>
                            <p>{material.new_name ? material.new_name : material.name}</p>
                            <Typography
                                // onClick={() => this.handleChangeExpandedChild(material.id)}
                                sx={{fontSize:"12px"}}
                            >
                                <small className={"selected-material text-primary"}>
                                    {mActive.length > 0 && mActive.includes(material.id) && String(price) !== "0" && `${material.area} m² * ¥${itemPrice} =  ` + "¥" +String(price)}
                                </small>
                            </Typography>
                        </div>
                    </div>
                    <div className="" style={{pointerEvents: 'auto'}}>
                        <Typography>
                            {/* {material.agreed || isConfirmed ? null :
                                (expired ? null :
                                    <Button
                                        size="small" variant="outlined" color="primary"
                                        style={{padding: '0px', minWidth: '50px', margin: '0 5px'}}
                                        onClick={() => handleSaveMaterial()}
                                    >
                                        保存
                                    </Button>
                                )
                            } */}
                                    
                            {/* {
                                isAgreed && <label className="text-danger">確定済</label>
                            } */}
                            {/* {material.agreed || isConfirmed ? <label className="text-danger">確定済</label> :
                                (expired ? null :
                                    <Button
                                        size="small" variant="outlined" color="error"
                                        style={{padding: '0px', minWidth: '50px', margin: '0'}}
                                        disabled = {!(category_group_detail_active && material.saved)}
                                        onClick={()=>handleChangeAgreed()}
                                        >
                                            確定
                                    </Button>
                                )
                            }        */}
                        </Typography>
                    </div>
                </AccordionSummary>
            }
            
  
            {children ? <AccordionDetails className='row' style={{paddingInline:"0px"}}>{children}</AccordionDetails> : ''}   
        </Accordion> 
        :
        <Accordion className="accordion-custom-root" expanded={expandChild === extension.id}>
            <AccordionSummary className='cg-child-summary'>
                <div className='cg-child-title' onClick={()=>handleShowDetail(extension.id)}>
                    <ExpandMoreIcon style={{transform: expandChild === extension.id ? "rotate(0deg)" : "rotate(-90deg)"}} />
                    <p>{extension.new_name && extension.new_name.length > 0 ? extension.new_name : extension.name}</p>
                </div>
            </AccordionSummary>
            {children ? <AccordionDetails className='row' style={{paddingInline:"15px"}}>{children}</AccordionDetails> : ''} 
        </Accordion>

    }
    </>
  )
}
