import {BASIC_SNACKBAR, BASIC_CLOSE_SNACKBAR, BASIC_OPEN_SNACKBAR} from "../../constants/store";

const state_default = {t: "", m: ""};

export const snackbar = (state = state_default, action) => {
  switch (action.type) {
    case BASIC_SNACKBAR:
      return Object.assign({}, action.payload);
    
    case BASIC_OPEN_SNACKBAR:
      action.payload.open = true;
      return Object.assign({}, action.payload);
    
    case BASIC_CLOSE_SNACKBAR:
      action.payload = state_default;
      return Object.assign({}, action.payload);
    
    default:
      return state;
  }
};