import { DRAW_LIST, ROOM_SELECT_LIST } from '../../constants/store';

const list = (state = [], action) => {
  switch (action.type) {
    case DRAW_LIST:
      // return action.payload.map(payload => Object.assign({}, payload));
      const data = action?.payload?.map((item) => {
        const { room_id, room_by_user } = item ?? {};
        const roomList = room_by_user?.map((room) => {
          if (room?.id === room_id) {
            return {
              ...room,
              isSelect: true,
            };
          } else {
            return {
              ...room,
              isSelect: false,
            };
          }
        });
        return {
          ...item,
          room_by_user: roomList,
        };
      });
      return data;
    case ROOM_SELECT_LIST:
      const { value, draw_id } = action?.payload;
      const { id: rom_by_user_id } = value ?? {};
      return state?.map((item) => {
        const { room_by_user, id } = item;
        if (id === draw_id) {
          return {
            ...item,
            room_by_user: room_by_user.map((room) => {
              if (room.id === rom_by_user_id) {
                return {
                  ...room,
                  isSelect: true,
                };
              } else {
                return {
                  ...room,
                  isSelect: false,
                };
              }
            }),
          };
        }
        return {
          ...item,
        };
      });
    default:
      return state;
  }
};

export default list;
