import React from 'react';
import { SIDEBAR_RIGHT } from '../../constants';
import drawIcon from "../../assets/images/drawIcon.png";
import './style.scss';
import { checkRemoveFrame, removeCGItem, removeFrameItem } from 'utils/common';
import DragDropFrames from "../frames-sidebar-right/frames-sidebar-right"
import { Tooltip } from '@mui/material';


const sidebarRight =({
  history,
  draw_id,
  cg_id,
  paramUrl,
  handleOpenCgModal,
  isHostSide,
  frame_list=[],
  onremoveItem,
  setIsShowModal
}) =>{
  const handleClickItem = (choose) => {
    switch (choose) {
      case SIDEBAR_RIGHT.CG:
        history.push(
          `/${isHostSide ? 'draws' : 'confirm'}/${draw_id}/cg_property/${cg_id}`
        );
        break;
      case SIDEBAR_RIGHT.DRAW:
      default:
        history.push(`/${isHostSide ? 'draws' : 'confirm'}/${draw_id}`);
        break;
    }
  };
  const handleClickFrameItem = (choose) => {
    history.push(
      `/${isHostSide ? 'draws' : 'confirm'}/${draw_id}/frame_id/${choose}`
    );
  };

  const handleRemoveItem =async (frame_id)=>{
    const isConfirm = await checkRemoveFrame()
    if(isConfirm){
      const remove_data = await removeFrameItem(draw_id,frame_id)
      onremoveItem(remove_data)
    }
  }

  const handleRemoveCGItem =async (cg_property_id)=>{
    const isConfirm = await checkRemoveFrame()
    if(isConfirm){
      const remove_data = await removeCGItem(draw_id,cg_property_id)
      await onremoveItem(remove_data)
      return true
    }else{
      return false
    }
  }

  
  return (
    <>
      <div className="sidebarRight">
        {
         (paramUrl === "guest" || paramUrl.draw_id || paramUrl.cg_id) ?
          <div className='cg-question' onClick={()=>setIsShowModal(true)}><i className="fa-solid fa-question" style={{color: "#f5f7fa"}}></i></div>:null
        }
        <Tooltip placement="left" arrow title="デジタルサイン">
          <div
            onClick={() => handleClickItem(SIDEBAR_RIGHT.DRAW)}
            className={`d-flex flex-column item d-flex flex-column align-items-center item ${
              paramUrl?.draw_id && !paramUrl?.cg_id && !paramUrl?.frame_id || paramUrl === "guest" ? 'item-active' : ''}`}
          >
            <img width={30} className='drawIcon' src={drawIcon} alt="drawIcon" />
            <span className="fs-12px">
              デジタル
              <br />
              サイン
            </span>
          </div>
        </Tooltip>
        

        {/* {process.env.REACT_APP_CURR_ENV !== 'production' && cg_id || paramUrl?.cg_id ? (
          <>
            <div
              onClick={
                cg_id
                  ? () => handleClickItem(SIDEBAR_RIGHT.CG)
                  : handleOpenCgModal
              }
              className={`d-flex flex-column align-items-center item ${
                paramUrl?.cg_id ? 'item-active' : ''
              } ${!cg_id ? 'item-no-active' : ''}`}
            >
              <img width={30} className='drawIcon' src={cgIcon} alt="cgIcon" />
              <span className="fs-14px">CG</span>
            </div>
          </>
        ):null} */}
        {
          frame_list.length > 0 && <DragDropFrames frame_list={frame_list} cg_id={cg_id} isHostSide={isHostSide} paramUrl={paramUrl} handleClickFrameItem={handleClickFrameItem} handleRemoveItem={handleRemoveItem} draw_id={draw_id} handleRemoveCg={handleRemoveCGItem} />
        }


          <div className="d-flex flex-column item">
            <i className="fa-solid fa-plus pb-1" onClick={handleOpenCgModal}></i>
          </div>

      </div>
      
      
    </>
  );
};
export default sidebarRight;
