import React, { useEffect , useState} from 'react'
import "./cg-record-modal.css"
export default function RecordModal({mode,closeModal,recordName,addNewRecord,selectedRecord,updateSelectedRecord,temporaryRecordList,total}) {
    const [name, setname] = useState("")
    const [newRecordName, setnewRecordName] = useState("")
    const [totalPrice, settotalPrice] = useState(0)

    const handleCloseModal = ()=>{
        closeModal()
    }

    const handleAddNewRecord = (status)=>{
        // false => add new record ; true => update
        addNewRecord(newRecordName,status)
    }

    const handleupdateSelectedRecord=()=>{
        updateSelectedRecord(name)
    }

    useEffect(() => {
      if(recordName){
        setnewRecordName(recordName)
      }
    }, [recordName])
    

    useEffect(() => {
        if(selectedRecord){
            setnewRecordName(selectedRecord.record_name)
            setname(selectedRecord.record_name)
        }
        if(temporaryRecordList.length > 0 || selectedRecord){
           
            if(selectedRecord && !selectedRecord.material_data || selectedRecord && selectedRecord.material_data.length <= 0){
                settotalPrice(0)
            }else{
                settotalPrice(total)
            }
            
        }
    }, [selectedRecord])
    

    
  return (
    <div className='record-modal'>
        <div className='record-modal-content'>
            <i className='fa-solid fa-xmark' onClick={handleCloseModal}></i>
            {
                mode === "add" && 
                <div className='record-add'>
                    <p className='record-title'>保存しますか？</p>
                    <input type='text' value={newRecordName} onChange={(e)=>setnewRecordName(e.target.value)} />
                    <div className='record-btn-group'>
                        <div className='record-cancel' onClick={handleCloseModal}>キャンセル</div>
                        <div className={`${selectedRecord?.id ? "record-add-update":"record-add-new"}`} onClick={()=>handleAddNewRecord(false)}>{selectedRecord?.id ? "新しく保存" : "保存"}</div>
                        {selectedRecord?.id && <div className='record-add-update' onClick={()=>handleAddNewRecord(true)}>上書き保存</div>}
                        
                    </div>
                </div>
            }

            {
                mode === "edit" && 
                <div className='record-add'>
                    <p className='record-title'>保存名</p>
                    <input type='text' value={name} onChange={(e)=>setname(e.target.value)} />
                    <div className='record-update' onClick={handleupdateSelectedRecord}>保存</div>
                </div>
            }

            {
                mode === "view" && 
                <div className='record-view'>
                    <p className='record-title'>保存名： {selectedRecord?.record_name}</p>
                    <p className='record-title'>価格： <i className="fa-solid fa-yen-sign"></i> {totalPrice}</p>

                    <table className='table'>
                            <tbody>
                                {
                                    selectedRecord?.material_data.map((record,index)=>{
                                        return (
                                            <tr key={index}>
                                                <td>{record.new_name.length > 0 ? record.new_name : record.name} : {record.object_name}</td>
                                                <td>{record.code}</td>
                                                <td>{record.area} m&#178;: <i className="fa-solid fa-yen-sign"></i> {parseFloat(record.area) * parseFloat(record.price)}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                    </table>
                </div>
            }

            {
                mode === "view_detail" && 
                <div className='record-view'>
                    <p className='record-title'>金額リスト</p>
                    <p className='record-title'>価格： <i className="fa-solid fa-yen-sign"></i> {totalPrice}</p>

                    <table className='table'>
                        <tbody>
                            {
                                temporaryRecordList.map((record,index)=>{

                                    return (
                                        <tr key={index}>
                                            <td>{record.new_name.length > 0 ? record.new_name : record.name} : {record.object_name}</td>
                                            <td>{record.code}</td>
                                            <td>{record.area} m&#178;: <i className="fa-solid fa-yen-sign"></i> {parseFloat(record.area) * parseFloat(record.price)}</td>
                                        </tr>
                                    )
                                })
                            }
                        
                        </tbody>
                    </table>
                </div>
            }       
            
        </div>
    </div>
  )
}
