import { FormControl, TextField } from '@mui/material';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  Arrow,
  Ellipse,
  Group,
  Image,
  Layer,
  Line,
  Rect,
  Stage,
  Text,
  Transformer,
} from 'react-konva';
import { Html } from 'react-konva-utils';
import { ELEMENT_TYPE } from './Konva.helpers';

// IMAGE CANVAS
export const URLImage = memo(({ src, scale }) => {
  const [image, setImage] = useState(null);
  const imageRef = useRef();

  const loadImage = useCallback(() => {
    if (!src) return;
    let imageComponent = new window.Image();
    imageComponent.src = src;
    imageComponent.addEventListener('load', () => {
      setImage(imageComponent)
    });
  }, [src]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  return image ? (
    <Image image={image} scaleX={scale} scaleY={scale} ref={imageRef} />
  ) : null;
});

// IMAGE CANVAS REF
export const URLImageRef = React.forwardRef((props, ref) => {
  const { src, scale } = props;
  const [image, setImage] = useState(null);

  const loadImage = useCallback(() => {
    if (!src) return;
    let imageComponent = new window.Image();
    imageComponent.src = src;
    imageComponent.addEventListener('load', setImage(imageComponent));
  }, [src]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  return image ? (
    <Image ref={ref} image={image} scaleX={scale} scaleY={scale} />
  ) : null;
});

export const ImagePreview = memo(
  ({ src, elements, height, contain, draggable, scaleParent }) => {
    height = height ?? 200;
    scaleParent = scaleParent ?? 1;
    let imageComponent = new window.Image();
    const [image, setImage] = useState(null);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);

    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    const [width, setWidth] = useState(0);
    const divRef = useRef(null);

    const handleLoaded = (imageComponent) => {
      setImage(imageComponent);
      setWidth(divRef.current.clientWidth);
      const scale = width / 1000;
      setScaleX(scale);
      setScaleY(scale);
    };

    useEffect(() => {
      imageComponent.src = src;
      imageComponent.addEventListener('load', handleLoaded(imageComponent));
      return () => {
        imageComponent.removeEventListener(
          'load',
          handleLoaded(imageComponent)
        );
      };
    }, [src, width]);

    useEffect(() => {
      if (!draggable) {
        setX(0);
        setY(0);
      }
    }, [draggable]);

    return (
      <div ref={divRef} style={{height, overflowY: "hidden"}}>
        <img src={src} width={width} height="auto" />
        <Stage
          width={width}
          height={height}
          scaleX={scaleX * scaleParent}
          scaleY={scaleY * scaleParent}
          draggable={draggable}
          x={x}
          y={y}
          onDragEnd={(e) => {
            setX(e.target.x());
            setY(e.target.y());
          }}
          style={{
            position: "absolute",
            top: 0,
            left: 0
          }}
        >
          <Layer>
            {/* <URLImage src={src} width={width} scale={scaleX} /> */}
            {(elements ?? []).map((ele, i) => {
              switch (ele.type) {
                case ELEMENT_TYPE.LINE:
                  return (
                    <Line
                      // key={ele.key}
                      key={i}
                      x={ele.x}
                      y={ele.y}
                      points={ele.points}
                      stroke={ele.color}
                      strokeWidth={7}
                      tension={0.5}
                      lineCap="round"
                      lineJoin="round"
                    />
                  );
                case ELEMENT_TYPE.ARROW:
                  return (
                    <Arrow
                      points={ele.points}
                      x={ele.x}
                      y={ele.y}
                      fill={ele.color}
                      stroke={ele.color}
                      // key={ele.key}
                      key={i}
                      strokeWidth={7}
                    />
                  );
                case ELEMENT_TYPE.CHECK:
                  return (
                    <Line
                      // key={ele.key}
                      key={i}
                      x={ele.x}
                      y={ele.y}
                      points={[
                        ele.points[0] - 10,
                        ele.points[1] - 10,
                        ele.points[0],
                        ele.points[1],
                        ele.points[0] + 15,
                        ele.points[1] - 15,
                      ]}
                      stroke={ele.color}
                      strokeWidth={4}
                    />
                  );
                case ELEMENT_TYPE.TEXT:
                  return (
                    <Text
                      text={ele.text}
                      stroke={ele.color}
                      fontSize={ele.fontSize}
                      strokeWidth={1}
                      // key={ele.key}
                      key={i}
                      x={ele.x}
                      y={ele.y}
                    />
                  );
                default:
                  return null;
              }
            })}
          </Layer>
        </Stage>
      </div>
    );
  }
);

export const CustomLine = ({ shapeProps, isSelected, onSelect, onChange }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <React.Fragment>
      <Line
        onMouseDown={onSelect}
        onTap={onSelect}
        onPointerclick={onSelect}
        x={shapeProps.x}
        y={shapeProps.y}
        ref={shapeRef}
        points={shapeProps.points}
        stroke={shapeProps.color}
        strokeWidth={7}
        tension={0.5}
        lineCap="round"
        lineJoin="round"
        rotation={shapeProps.rotation || 0}
        draggable={isSelected}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          const { points } = shapeProps;
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);
          const newPoints = points.map((point, index) => {
            return point * (index % 2 === 0 ? scaleX : scaleY);
          });
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
            rotation: shapeRef.current.rotation(),
            points: newPoints,
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          shouldOverdrawWholeArea
        />
      )}
    </React.Fragment>
  );
};

export const CustomArrow = ({ shapeProps, isSelected, onSelect, onChange }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <React.Fragment>
      <Arrow
        onMouseDown={onSelect}
        onTap={onSelect}
        onPointerclick={onSelect}
        x={shapeProps.x}
        y={shapeProps.y}
        ref={shapeRef}
        points={shapeProps.points}
        stroke={shapeProps.color}
        strokeWidth={7}
        rotation={shapeProps.rotation || 0}
        draggable={isSelected}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          const { points } = shapeProps;
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);
          const newPoints = points.map((point, index) => {
            return point * (index % 2 === 0 ? scaleX : scaleY);
          });
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
            rotation: shapeRef.current.rotation(),
            points: newPoints,
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          shouldOverdrawWholeArea
        />
      )}
    </React.Fragment>
  );
};

export const CustomCheck = ({ shapeProps, isSelected, onSelect, onChange }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <React.Fragment>
      <Line
        onMouseDown={onSelect}
        onTap={onSelect}
        onPointerclick={onSelect}
        x={shapeProps.x}
        y={shapeProps.y}
        ref={shapeRef}
        points={[
          shapeProps.points[0] - 10,
          shapeProps.points[1] - 10,
          shapeProps.points[0],
          shapeProps.points[1],
          shapeProps.points[0] + 15,
          shapeProps.points[1] - 15,
        ]}
        stroke={shapeProps.color}
        strokeWidth={4}
        // rotation={shapeProps.rotation || 0}
        draggable={isSelected}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          resizeEnabled={false}
          rotateEnabled={false}
          shouldOverdrawWholeArea
        />
      )}
    </React.Fragment>
  );
};

export const CustomText = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  propIsEditing,
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const [editText, setEditText] = useState(shapeProps.text);
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    if (isSelected && !isEditing) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    } else {
      setIsEditing(false);
    }
  }, [isSelected]);

  useEffect(() => {
    if (propIsEditing) {
      setIsEditing(propIsEditing);
    }
  }, [propIsEditing]);

  // useEffect(() => {
  //   if (!isEditing && shapeProps.text !== editText) {
  //     onChange({
  //       ...shapeProps,
  //       text: editText,
  //     });
  //   }
  // }, [isEditing]);
  return (
    <React.Fragment>
      <Group
        ref={shapeRef}
        // x={shapeProps.x}
        y={shapeProps.y - (!isEditing ? 0 : 1)}
        x={shapeProps.x}
        // y={shapeProps.y - shapeProps.fontSize/2 - (!isEditing ? 0 : 1)}
        rotation={shapeProps.rotation || 0}
        draggable={isSelected}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        scaleX={shapeProps.scaleX || 1}
        scaleY={shapeProps.scaleY || 1}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
            rotation: shapeRef.current.rotation(),
            scaleX: node.scaleX(),
            scaleY: node.scaleY(),
          });
        }}
        onMouseDown={() => onSelect()}
        onTap={() => onSelect()}
        onPointerclick={() => onSelect()}
      >
        {!isEditing ? (
          <Text
            text={shapeProps.text}
            fill={shapeProps.color}
            fontSize={shapeProps.fontSize}
            strokeWidth={1}
          />
        ) : (
          <Html
            transformFunc={(e) => {
              return { ...e, x: e.x - 14, y: e.y - 16.5 };
            }}
          >
            <FormControl>
              <TextField
                value={shapeProps.text}
                onChange={(e) => {
                  onChange({
                  ...shapeProps,
                  text: e.currentTarget.value,
                });
                }}
                inputProps={{
                  style: {
                    color: shapeProps.color,
                    fontSize: `${shapeProps.fontSize}px`,
                    padding: '0',
                    lineHeight: `${shapeProps.fontSize}px`,
                  },
                }}
                inputRef={(input) => {
                  if (input !== null) {
                    input.focus();
                    // input.selectionStart = editText.length;
                  }
                }}
                onFocus={(e) => {}}
                multiline
              />
            </FormControl>
          </Html>
        )}
      </Group>
      {isSelected && !isEditing && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          onDblClick={() => {
            onSelect(true);
            setEditText(shapeProps.text);
            setIsEditing(true);
          }}
          onDbltap={() => {
            onSelect(true);
            setEditText(shapeProps.text);
            setIsEditing(true);
          }}
          onPointerdblclick={() => {
            onSelect(true);
            setEditText(shapeProps.text);
            setIsEditing(true);
          }}
          shouldOverdrawWholeArea
        />
      )}
    </React.Fragment>
  );
};

export const CustomSquare = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <React.Fragment>
      <Rect
        onMouseDown={onSelect}
        onTap={onSelect}
        onPointerclick={onSelect}
        x={shapeProps.x}
        y={shapeProps.y}
        ref={shapeRef}
        width={shapeProps.width || 20}
        height={shapeProps.height || 20}
        fill="transparent"
        stroke={shapeProps.color || '#000'}
        strokeWidth={2}
        rotation={shapeProps.rotation || 0}
        draggable={isSelected}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          const { height, width } = shapeProps;
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
            rotation: shapeRef.current.rotation(),
            width: width * scaleX,
            height: height * scaleY,
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          shouldOverdrawWholeArea
        />
      )}
    </React.Fragment>
  );
};

export const CustomCircle = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <React.Fragment>
      <Ellipse
        onMouseDown={onSelect}
        onTap={onSelect}
        onPointerclick={onSelect}
        x={shapeProps.x}
        y={shapeProps.y}
        ref={shapeRef}
        width={shapeProps.width || 20}
        height={shapeProps.height || 20}
        fill="transparent"
        stroke={shapeProps.color || '#000'}
        strokeWidth={2}
        rotation={shapeProps.rotation || 0}
        draggable={isSelected}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          const { width, height } = shapeProps;
          const node = shapeRef.current;

          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
            rotation: shapeRef.current.rotation(),
            width: width * scaleX,
            height: height * scaleY,
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          shouldOverdrawWholeArea
        />
      )}
    </React.Fragment>
  );
};
