import { property_set_drag_file } from 'actions/property';
import { connect } from 'react-redux';
import { basic_loading, basic_open_snackbar, basic_set_is_modal_question, basic_set_loading, basic_set_open_snackbar } from '../../../../actions/basic';
import { setOpenConfirmNotificationAction } from '../../../../actions/confirm';
import { document_set_dialog, document_set_info, document_set_list } from '../../../../actions/document';
import { downloadImagesAction, draw_set_frame_list, saveDrawDetailsByUserAction, sub_draw_set_info, uploadFileDrawDetailsByUserAction, userGetDrawDetailsAction } from '../../../../actions/draw';
import { changeToolKonvaAction, initKonvaAction, selectedImageIdsAction } from '../../../../actions/konva-canvas';
import { TEXT_LIST, USER_TYPE } from '../../../../constants';
import { swalConfirmDelete, swalQuestion } from '../../../../constants/swal';
import { convertBase64PdfToImage, convertToBase64, createClient, createClientDraw, getErrorMessage, sizeImageMax, uniqueArray, userRequestGraphQL } from '../../../../constants/utils';
import { Document, DrawSideBar } from '../../../../containers/pages/user/draw/draw.components';
import { TOOL } from '../../../../reducers/konva-canvas/konva-canvas';
import { REMOVE_DRAW_DETAIL_BY_USER } from '../../../../share/mutation';
import { GET_CGS_FRAMES_INDEX, GET_SUB_DRAW, GET_USER_ADDRESS_BOOK } from 'share/query';
import blobStream from "blob-stream";
import * as PDFJS from 'pdfjs-dist';
import { AddNewFrameItem, handleSortArray } from 'utils/common';
PDFJS.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + '/pdf.worker.js';


let document_draw = null;
let document_info = null;
let document_list = null;
let konva_element = [];
let selectedImageIds = [];
let konva_is_changed = false;
let property_sidebar = {};
let document_list_storage= [];

const mapStateToPropsDocument = (state) => {
  document_draw = state.draw.info;
  document_info = state.document.info
  document_list = state.document.list
  const {me_draw} = state.basic
  const konva_step = state.konvaCanvas.step;
  const konva_history = state.konvaCanvas.history;
  konva_element = konva_history[konva_step]?.elements ?? [];
  selectedImageIds = state.konvaCanvas.selectedImageIds;
  konva_is_changed = state.konvaCanvas.isChanged;
  property_sidebar = state.property.property_sidebar;
  document_list_storage = state.document.storage;
  const draw = state.draw.info;

  return {
    list: state.document.list,
    document_info,
    me_draw,
    selectedImageIds,
    property_sidebar,
    document_list_storage,
    document_draw,
    frame_list: state.draw.frame_list,
  }
}

const mapDispatchToPropsDocument = (dispatch) => {
  return {
    onChangeInfo: async (info_active, info_change) => {
      if (info_active.id === info_change.id) return true;
      if (info_active.id && !info_active.process && konva_is_changed) {
        await swalQuestion({
          html: 'この画像は編集中で、保存されていません。他の画像を開くと編集した内容を保存しますか？',
          confirmButtonText: '保存する',
          cancelButtonText: '保存しないで移動する',
        }).then(result => {
          if (result.isConfirmed) {
            dispatch(saveDrawDetailsByUserAction(info_active.id));
          }
        });
      }
      dispatch(document_set_info(info_change));
      dispatch(document_set_dialog(true));
      dispatch(changeToolKonvaAction(TOOL.DRAG));
      dispatch(initKonvaAction(info_change.elements ?? []));
    },
    onChangeRemove: async (info) => {
      try {
        if (!info.id) return console.log('No found id');
        const confirm_delete = await swalConfirmDelete().then(result => {
          return result.isConfirmed
        });
        if (!confirm_delete) return true;
        dispatch(basic_set_loading(true))
        const data = await userRequestGraphQL(REMOVE_DRAW_DETAIL_BY_USER, {drawDetailId: info.id})
        const {removeDrawDetailByUser} = data;
        dispatch(basic_open_snackbar({t: 'success', m: TEXT_LIST.has_removed}));
        
        if (info.id === document_info.id) {
          dispatch(document_set_info());
          dispatch(document_set_dialog(false));
          dispatch(initKonvaAction())
        }
        
        dispatch(userGetDrawDetailsAction(removeDrawDetailByUser.id))
        dispatch(basic_set_loading(false))
      } catch (error) {
        dispatch(basic_open_snackbar({t: 'error', m: getErrorMessage(error)}));
        dispatch(basic_set_loading(false))
      }
    },
    onChangeRemoveList: async () => {
      if (!selectedImageIds.length) return console.log('No found id');
      const confirm_delete = await swalConfirmDelete().then(result => {
        return result.isConfirmed
      });
      if (!confirm_delete) return true;
    
      dispatch(basic_loading(true));
      try {
        const deletePromises = selectedImageIds.map(async (idInfo) => {
          return await userRequestGraphQL(REMOVE_DRAW_DETAIL_BY_USER, {drawDetailId: idInfo});
        })
        await Promise.all(deletePromises).then(() => {
          selectedImageIds.map((item) => {
            if(item === document_info.id) {
              dispatch(document_set_info());
              dispatch(document_set_dialog(false));
              dispatch(initKonvaAction())
            }
          })
          dispatch(userGetDrawDetailsAction(document_draw?.id));
          dispatch(basic_open_snackbar({t: 'success', m: TEXT_LIST.has_removed}));
          dispatch(selectedImageIdsAction([]));
        })
      } catch(e) {
        dispatch(basic_open_snackbar({t: 'error', m: getErrorMessage(e)}));
      }
      dispatch(basic_loading(false));
      
    },
    onDropFiles: async (files) => {
      handleUserDropFiles(files,dispatch,3)
    },
    downloadImages: (image) => {
      dispatch(downloadImagesAction(USER_TYPE.END_USER, image));
    },
    selectImage: (imageId, checked) => {
      if(checked) dispatch(selectedImageIdsAction(uniqueArray([...selectedImageIds, imageId])))
      else dispatch(selectedImageIdsAction(uniqueArray(selectedImageIds.filter((item, index) => item !== imageId))))
    },
    handleSearchDrawList: (value) => {
      if(value === '') return dispatch(document_set_list([...document_list_storage]));
      const listDrawSearched = [...document_list_storage]?.filter((item) => item?.file_same_name?.includes(value));
      dispatch(document_set_list([...listDrawSearched]));;
    },
    onDeselectListItem: () => {
      dispatch(selectedImageIdsAction([]));
    },
    getUserAddressBook: async ()=>{
      let userName ;
      const client = createClientDraw()
      await client.request(GET_USER_ADDRESS_BOOK, {id: document_draw.user_address_book_id}).then((data) => {
        userName = data?.getUserAddressBookById?.user_name || "";
      })
      return userName
    },
    changeSubDrawInfo:async (draw_info)=>{
      const client = createClientDraw()
      await client.request(GET_SUB_DRAW, {draw_parent_id: draw_info.id}).then((data) => {
        let subData = data.getSubDraws
        if(subData.length > 0){
          subData.unshift(draw_info)
        } 
        dispatch(sub_draw_set_info(subData));
      })
    },
  }
}

export const CDocument = connect(mapStateToPropsDocument, mapDispatchToPropsDocument)(Document)

const mapStateToPropsDrawSideBar = (state) => {
  const document_list = state.document.list;
  const {info: property_info} = state.property
  document_draw = state.draw.info;
  
  return {
    document_list,
    property_info,
    windowSize: state.basic.windowSize,
    document_draw,
    frame_list: state.draw.frame_list,
  }
}

const mapDispatchToPropsDrawSideBar = (dispatch) => {
  const setOpenConfirmNotification = (isOpen) => {
    dispatch(setOpenConfirmNotificationAction(isOpen))
  }
  
  return {
    setOpenConfirmNotification,
    handleRemoveFrameItem:async(data) =>{
      if(data.status){
        const client = createClientDraw()
        const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:document_draw.id})
        if(cgData.getCgAndFrameListIndex.length > 0){
            const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
            dispatch(draw_set_frame_list(sortResult))
        }else{
          dispatch(draw_set_frame_list([]))
        }
        dispatch(basic_set_open_snackbar({ t: 'success', m: "削除されました" }));
      }else{
          dispatch(basic_set_open_snackbar({ t: 'error', m: data.data }));
      }
    },
    handleAddNewFame: async(url)=>{
      dispatch(basic_loading(true))
      try {
        const add_data = await AddNewFrameItem(document_draw,url,dispatch);
        if(add_data && add_data.length > 0){
          const client = createClientDraw()
          const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:document_draw.id})
          if(cgData.getCgAndFrameListIndex.length > 0){
              const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
              dispatch(draw_set_frame_list(sortResult))
              dispatch(basic_set_open_snackbar({ t: 'success', m: "追加されました" }));
          }else{
            dispatch(draw_set_frame_list([]))
          }
        }
        dispatch(basic_loading(false))
      } catch (error) {
        dispatch(basic_loading(false))
        const msg = getErrorMessage(error)
        dispatch(basic_open_snackbar({t:'error',m:msg}))
      }
    },
    onCloseQuestion: async()=>{
      dispatch(basic_set_is_modal_question(true))
    }, 
  }
}

export const handleUserDropFiles = async (files,dispatch,scale = 3)=>{
  dispatch(basic_loading(true));
  // let isLoadFile = true;
  let attachments = [];

  // Check size image max;
  const size_image_max = sizeImageMax();
  let errors_upload = [];
  for (const [index, file] of files.entries()) {
    if (file.size > size_image_max) errors_upload.push(file.name);
  }
  if(errors_upload.length) {
    errors_upload.unshift(`添付可能なファイルサイズは${size_image_max}MB以下です`);
    dispatch(basic_open_snackbar({t: 'error', m: errors_upload.join('\n')}));
    dispatch(basic_loading(false));
    return true;
  }

  for (const [index, file] of files.entries()) {
    let convert_file = await convertToBase64(file);
    if(file.type === "application/pdf") {
      const result = file.name.includes("min");
      const imagesData = await convertBase64PdfToImage(convert_file.base64,result ? 1 : 3);
      convert_file.base64 = imagesData.base64;
      convert_file.isPdf = true;
      attachments.push(convert_file);
    }else{
      attachments.push(convert_file);
    }    
  }
  if(!attachments.length){
    dispatch(basic_open_snackbar({t: 'error', m: 'Not found attachments'}));
    return true;
  }
  dispatch(uploadFileDrawDetailsByUserAction(document_draw?.id, attachments));
}

export const userCompressPdf = async (file,dispatch)=>{
  dispatch(basic_loading(true));
  let pdfDoc = null,
      pageRendering = false,
      pageCount = 0,
      pageNumPending = null,
      pageScale = 1,
      pageQuality = 0.5,
      pageQualityUI = 0.5,
      pageFormat = "image/jpeg",
      imgData = null;
  let tenet = [];
  const canvas = document.createElement('canvas');
  var ctx = canvas.getContext("2d");
  var ordered_input_files = [];

  let pdfName = file["name"];
  let pdfFileObject = file;
  let selected_file_name = pdfName;

  const reader = new FileReader();
  reader.onload = function (e) {
    var url = e.target.result;
   pdf2img(url);
  };
  reader.readAsDataURL(pdfFileObject);


  function pdf2img(pdf_url){
    readPDF(pdf_url).then(
      () => downloadAll(),
      () => {
        console.log("Error reading PDF");
      }
    );
  }
  

  function readPDF(url){
    return new Promise((resolve, reject) => {
      var loadingTask = PDFJS.getDocument(url);
      loadingTask.promise.then(
        function (pdfDoc_) {
          pdfDoc = pdfDoc_;
          resetPDFMetaStore(pdfDoc.numPages);
          // selected_file_name += " - " + pageCount + " Pages";
          resolve(1);
        },
        () => reject(1)
      );
    });
  }

  function resetPDFMetaStore(numPages) {
    pageCount = numPages;
    imgData = {};
    pageNumPending = [];
    pageScale = 1;
    pageQuality = 0.5;
    pageQualityUI = 0.5;
    pageFormat = "image/jpeg";
  }

  function downloadAll() {
    for (let i = 1; i <= pageCount; i++) {
      queueRenderPage(i);
    }
  }
  function queueRenderPage(num) {
    dispatch(basic_loading(true));
    if (pageRendering) {
      pageNumPending.push(num);
    } else {
      renderPage(num);
    }
  }

  function renderPage(num) {
    dispatch(basic_loading(true));
    pageRendering = true;
    // Using promise to fetch the page
    pdfDoc.getPage(num).then(function (page) {
      var viewport = page.getViewport({
        scale: pageScale
      });
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };
      var renderTask = page.render(renderContext);

      // Wait for rendering to finish
      renderTask.promise.then(function () {
        let data = canvas.toDataURL(pageFormat, pageQuality);

        imgData[num] = data;
        pageRendering = false;

        if (pageNumPending !== null && pageNumPending.length != 0) {
          renderPage(pageNumPending.shift());
        } else {
          if (Object.keys(imgData).length == pageCount) {
            tenet.push(JSON.parse(JSON.stringify(imgData)));
            console.log("Rendering complete");
            checkFileProcessProgress();
          }
        }
      });
    });
  }

  function checkFileProcessProgress() {
    if (ordered_input_files.length == 0) {
      processImageData();
    } else {
      userCompressPdf();
    }
  }

  async function processImageData() {
    var options = {
      autoFirstPage: false,
      compress: false,
    };
    var doc = new window.PDFDocument(options);
    const stream = doc.pipe(blobStream());

    for (let k = 0; k < tenet.length; k++) {
      var imgData = tenet[k];
      console.log("PDF %d: %d pages", k + 1, Object.keys(imgData).length);
      for (let i = 1; i <= Object.keys(imgData).length; i++) {
        let img_data = await getImgObj(imgData[i]);
        doc.addPage({
          size: [img_data.width, img_data.height],
        });
        doc.image(img_data.src, 0, 0);
      }
    }

    doc.end();

    stream.on("finish",async function () {
      var output_blob = stream.toBlob("application/pdf");
      output_blob.name = selected_file_name;
      dispatch(basic_open_snackbar({t: "success", m: "File compressed. Uploading file..."}));
      await handleUserDropFiles([output_blob],dispatch,1)
      
    });
  }
  function getImgObj(data) {
    return new Promise(function (resolve, reject) {
      var img = new Image();
      img.onload = function () {
        resolve(img);
      };
      img.src = data;
    });
  }
}

export const CDrawSideBar = connect(mapStateToPropsDrawSideBar, mapDispatchToPropsDrawSideBar)(DrawSideBar)