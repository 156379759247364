import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { setListConfirmAction } from '../../../../actions/confirm';
import { document_set_info } from '../../../../actions/document';
import {
  userGetDrawDetailsAction,
  userSendConfirmedMailAction,
} from '../../../../actions/draw';
import { TEXT_LIST } from '../../../../constants';
import {
  createClientDraw,
  findProcessDrawDetail,
  processAgreed,
  processCancelRequest,
  processCancelRequestConfirm,
  processConfirmed,
  processRequest,
  processRequestConfirm,
  resetRoute,
} from '../../../../constants/utils';
import { ImagePreview } from '../../../../custom-components/KonvaCanvas/Konva.components';
import ProcessLabel from '../../../../custom-components/process-label/process-label';
import { GET_DRAW, GET_FIREBASE_TOKEN, GET_ROOM_INFO_BY_ROOM_ID, GET_ME, GET_ROOM_INFO_BY_DRAW_ID } from 'share/query';
import { ADD_MESSAGE_INTO_CHAT,SEND_CONFIRMED_BY_GUEST } from 'share/mutation';
import CustomProcessCancel from 'containers/common/customProcessCancel';
import CustomIconConfirm from 'containers/common/customIconConfirm';
import { isMobile } from 'react-device-detect';

const ListConfirm = memo(() => {
  const { list } = useSelector((a) => a.confirm);
  const listConfirmSorted = useMemo(
    () =>
      [...list]
        .filter((item) => item.confirmed)
        .sort((a, b) => (a.process === processAgreed.type ? 1 : -1)),
    [list]
  );
  const listNotConfirmed = useMemo(
    () => [...list].filter((item) => !item.confirmed),
    [list]
  );
  const { draw_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleConfirmByGuest = async (draw_id)=>{
    if(draw_id){
      const client = await createClientDraw()
      const drawResponse = await client.request(GET_DRAW,{drawId:String(draw_id)});
      const drawData = await drawResponse.getDraw;
      const roomInfoResponse = await client.request(GET_ROOM_INFO_BY_DRAW_ID, {id: draw_id});
      const roomInfo = await roomInfoResponse.getRoomInfoByDrawId;

      // const me = await client.request(GET_ME);
      // const response = await client.request(SEND_CONFIRMED_BY_GUEST,{drawId:String(draw_id),email:me.me.email});
      // await response.sendConfirmedByGuest; 

      // if(roomInfo !== null){
      //   let variables = {
      //     room_id: roomInfo.id,
      //     to_user_id: roomInfo.to_user_id,
      //     message: `【自動送信】${drawData.name} において依頼されていた確定作業が完了しました。`,
      //     property_id:roomInfo.property_id,
      //     line_seller_receive_notif:roomInfo.line_seller_receive_notif,
      //     send_message_to_mail:true
      //   }
      //   client.request(ADD_MESSAGE_INTO_CHAT, variables).then(() => {   
      //     client.request(GET_FIREBASE_TOKEN, { id: roomInfo.to_user_id }).then(data => {
      //     })
      //   })        
      // }
      
    }
  }

  useEffect(() => {
    // handleConfirmByGuest(draw_id);
  }, [draw_id])

  return (
    <Box
      p={5}
      display="flex"
      flexDirection={'column'}
      justifyContent="space-between"
      alignItems="center"
      height={window.innerHeight}
    >
      <div className="d-flex flex-column w-100">
        <div
          className="btn-modal align-self-end mb-3"
          onClick={async () => {
            dispatch(setListConfirmAction([]));
            dispatch(document_set_info());
            resetRoute(`/confirm/${draw_id}`);
          }}
        >
          {TEXT_LIST.close}
        </div>
        <Typography variant="h5" textAlign={'center'} fontWeight={'700'}>
          ありがとうございます。
        </Typography>
        <Typography variant="h5" textAlign={'center'} fontWeight={'500'} fontSize={'18px'}>
          chatへのファイル送信は数分かかる場合がございます。
        </Typography>
      </div>
      <div
        style={{
          width: '100%',
          height: window.innerHeight * 0.8,
          padding: 30,
        }}
      >
        <ImageList cols={isMobile ? 2 : 4} gap={35} sx={{ overflowY: 'visible' }}>
          {listConfirmSorted.length > 0 && listConfirmSorted.map((image, index) => {
            let { process, base64, elements, elementsCanvas } = image;
            if(elementsCanvas.length > 0){
              elementsCanvas = elementsCanvas.map((ele, index) => {
                return {
                  ...ele,
                  key: elements.length + index,
                };
              });
            }
            
            const processing = findProcessDrawDetail(process);
            return (
              <div key={index} className="mb-5 position-relative">
                <ImageListItem>
                  <ImagePreview
                    src={base64}
                    elements={[...elements, ...elementsCanvas]}
                  />
                  {process ? <ProcessLabel processing={processing} /> : null}
                </ImageListItem>
                {(process === processAgreed.type ||
                  process === processConfirmed.type) && (
                  <CustomIconConfirm />
                )}
                {process === processCancelRequest.type && (
                  <CustomProcessCancel isWarningRed={true} width={50} />
                )}
                  {process === processCancelRequestConfirm.type && (
                  <CustomProcessCancel isWarningRed={true} width={50} />
                )}
              </div>
            );
          })}
        </ImageList>
        {/* {listNotConfirmed.length ?
          <ImageList cols={4} gap={15}  sx={{overflowY: "visible"}}>
            {listNotConfirmed.map((image, index) => {
              let {process, base64, elements, elementsCanvas} = image;
              elementsCanvas = elementsCanvas.map((ele, index) => {
                return {
                  ...ele,
                  key: elements.length + index
                }
              })
              const processing = findProcessDrawDetail(process);
              return (
                <div key={index} onClick={() => {history.push(`/confirm/${draw_id}/details/${image.id}`)}}>
                  <ImageListItem>
                  <ImagePreview src={base64} elements={[...elements, ...elementsCanvas]} />
                    {process ? <ProcessLabel processing={processing} /> : null}
                  </ImageListItem>
                </div>)
            })}
          </ImageList> : null} */}
      </div>
      <div className="d-flex flex-column align-items-center">
        {listNotConfirmed.length > 0 && (
          <Box
            display={'flex'}
            flexDirection="column"
            alignItems={'center'}
            marginBottom={2}
          >
            <Typography textAlign={'center'} marginBottom={2}>
              ！未確認・未記入の画像が残っています！
            </Typography>
            <div
              className="btn-modal cursor-pointer"
              onClick={() => {
                history.push(
                  `/confirm/${draw_id}/details/${listNotConfirmed[0].id}`
                );
              }}
            >
              確認作業に戻る
            </div>
          </Box>
        )}
        {/* <div className='btn-pchat border-0' style={{
            cursor: listConfirmSorted.length === list.length && list.length > 0? "pointer" : "no-drop",
            opacity: listConfirmSorted.length === list.length && list.length > 0 ? 1 : 0.5
          }} onClick={() => {
            if(listConfirmSorted.length === list.length && list.length > 0) {
              dispatch(userSendConfirmedMailAction(draw_id, list))
            }
          }}>確認を確定する</div> */}
      </div>
    </Box>
  );
});

export default ListConfirm;
