export const ELEMENT_TYPE = {
  LINE: "line",
  TEXT: "text",
  ARROW: "arrow",
  CHECK: "check",
  SQUARE: "square",
  CIRCLE: "circle",
}

export const KEY = {
  DELETE: 127,
}

export const SCALE_BY = 1.02