import {connect} from "react-redux";
import Documents from '../../containers/common/document'
import {
  saveDocumentInfoCanvasAction, document_set_dialog, document_set_filter,
  document_set_info, document_set_list
} from "../../actions/document";
import {
  convertBase64PdfToImage, convertToBase64, createClient,
  createClientDraw,
  getErrorMessage, sizeImageMax, uniqueArray
} from "../../constants/utils";
// import moment from 'moment';
import {basic_loading, basic_open_snackbar} from "../../actions/basic";
import {TEXT_LIST, USER_TYPE} from "../../constants/index";
import { KONVA_ADD_TEXT, KONVA_CHANGE, KONVA_CHANGE_TOOL, DOCUMENT_LIST } from "../../constants/store";
import { ELEMENT_TYPE } from "../../custom-components/KonvaCanvas/Konva.helpers";
import {
  clearConfirmAction, downloadImagesAction, draw_set_list, draw_set_register_user, draw_set_room_select, saveDrawDetailsAction, sub_draw_set_info, uploadDrawDetailsAction,
  uploadFileDrawDetailsAction,
  userGetDrawDetailsAction
} from "../../actions/draw";
import { openHOCModalAction } from "../../actions/hoc-modal";
import {swalConfirmDelete, swalQuestion} from "../../constants/swal";
import {CONVERT_BASE64_FILE, REMOVE_DRAW_DETAIL} from "../../share/mutation";
import {TOOL} from "../../reducers/konva-canvas/konva-canvas";
import { initKonvaAction, selectedImageIdsAction, setKonvaElementsAction } from "../../actions/konva-canvas";
import { getDrawDetailsAction } from "../../actions/draw";
import { GET_SUB_DRAW } from "share/query";
import moment from "moment";
import blobStream from "blob-stream";
import * as PDFJS from 'pdfjs-dist';
PDFJS.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + '/pdf.worker.js';

// const cMapUrl = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.3.200/cmaps/';

let document_filter;
let document_list = [];
let document_dialog = false;
let document_info = null;
let document_draw = null;
let register_user = null;
let konva_element = [];
let selectedImageIds = [];
let konva_is_changed = false
let document_list_storage= [];
let property_sidebar = {}
let me;
let fileLarge = false;

const mapStateToProps = (state) => {
  document_filter = state.document.filter;
  document_list = state.document.list;
  document_list_storage = state.document.storage;
  document_dialog = state.document.dialog;
  document_info = state.document.info;
  document_draw = state.draw.info;
  register_user = state.draw.register_user;
  // konva_element = state.konvaCanvas.elements
  const konva_step = state.konvaCanvas.step;
  const konva_history = state.konvaCanvas.history;
  konva_element = konva_history[konva_step]?.elements ?? [];
  me = state.basic.me
  selectedImageIds = state.konvaCanvas.selectedImageIds
  konva_is_changed = state.konvaCanvas.isChanged;
  property_sidebar = state.property.property_sidebar;

  return {
    document_info,
    document_filter: state.document.filter,
    document_list: state.document.list,
    document_dialog: state.document.dialog,
    document_tool: state.konvaCanvas.tool,
    document_scale: state.konvaCanvas.stageScale,
    document_draw,
    konva_element,
    me,
    selectedImageIds,
    document_list_storage,
    property_sidebar,
    room_select: state.draw.room_select,
  }
};

const mapDispatchToProps = (dispatch, getState) => {
  return {
    onInit: () => {
      dispatch(document_set_info());
      // dispatch(document_set_info(a));
      // dispatch(document_set_list([a]));
    },
    onChangeView: () => {
      onChangeView(dispatch);
    },
    onDropFiles: async (files) => {
      handleDropFiles(files,dispatch)
    },
    onChangeInfo: async (info_active, info_change) => {
      if (info_active.id === info_change.id) return true;
      if (info_active.id && !info_active.process && konva_is_changed) {
        await swalQuestion({
          html: 'この画像は編集中で、保存されていません。他の画像を開くと編集した内容を保存しますか？',
          confirmButtonText: '保存する',
          cancelButtonText: '保存しないで移動する',
        }).then(result => {
          if (result.isConfirmed) {
            dispatch(saveDrawDetailsAction(info_active.id));
          }
        });
      }
      dispatch(document_set_info(info_change));
      dispatch(document_set_dialog(true));
      dispatch({type: KONVA_CHANGE_TOOL, payload: TOOL.DRAG});
      dispatch(initKonvaAction(info_change.elements ?? []));
      const client = createClientDraw()
      await client.request(GET_SUB_DRAW, {draw_parent_id: info_change.id}).then((data) => {
        let subData = data.getSubDraws
        if(subData.length > 0){
          subData.unshift(info_change)
        } 
        dispatch(sub_draw_set_info(subData));
      })
    },
    onChangeDialog: () => {
      const status = !document_dialog;
      dispatch(document_set_dialog(status));
      if (!status) dispatch(document_set_info(null));
    },
    onChangeValue: (document_info, name, value) => {
      document_info[name] = value;
      dispatch(document_set_info(document_info));
    },
    onChangeAgreed: (document_info, name, value) => {
      if (!document_info.agreed) document_info.agreed = {};
      document_info.agreed[name] = value;
      dispatch(document_set_info(document_info));
    },
    onChangeConfirm: (document_info, text) => {
      document_info.process = 5;
      dispatch(document_set_info(document_info));
      
      const index = document_list.findIndex(document => document.base64 === document_info.base64);
      if (index > -1) {
        document_list[index].process = document_info.process;
        dispatch(document_set_list(document_list));
      }
      
      dispatch(basic_open_snackbar({type: 'success', message: TEXT_LIST.has_registered}))
    },
    onChangeRemove: async (info) => {
      
      if (!info.id) return console.log('No found id');
      const confirm_delete = await swalConfirmDelete().then(result => {
        return result.isConfirmed
      });
      if (!confirm_delete) return true;
      dispatch(basic_loading(true));
      const client = createClient();
      await client.request(REMOVE_DRAW_DETAIL, {drawDetailId: info.id}).then( async data => {
        const {removeDrawDetail} = data;
      
        dispatch(basic_open_snackbar({t: 'success', m: TEXT_LIST.has_removed}));
        
        // dispatch(initKonvaAction())
        if (info.id === document_info.id){
          dispatch(document_set_info());
          // dispatch(setKonvaElementsAction([]));
          dispatch(initKonvaAction())
        }
      
        const index = document_list.findIndex(document => document.id === removeDrawDetail.id);
        if (index > -1) {
          document_list.splice(index, 1);
          dispatch(document_set_list(document_list));
          
          if(document_list.length){
            dispatch(document_set_info(document_list[0]));
            await client.request(GET_SUB_DRAW, {draw_parent_id: document_list[0].id}).then((data) => {
              let subData = data.getSubDraws
              if(subData.length > 0){
                subData.unshift(document_list[0])
              } 
              dispatch(sub_draw_set_info(subData));
            })
          }
        }
        dispatch(basic_loading(false));
      }).catch(e => {
        dispatch(basic_open_snackbar({t: 'error', m: getErrorMessage(e)}));
        dispatch(basic_loading(false));
      });
    },
    onChangeRemoveList: async () => {
      if (!selectedImageIds.length) return console.log('No found id');
      const confirm_delete = await swalConfirmDelete().then(result => {
        return result.isConfirmed
      });
      if (!confirm_delete) return true;
    
      const client = createClient();
      dispatch(basic_loading(true));
      try {
        const deletePromises = selectedImageIds.map(async (idInfo) => {
          return await client.request(REMOVE_DRAW_DETAIL, {drawDetailId: idInfo});
        })
        await Promise.all(deletePromises).then(() => {
          selectedImageIds.map((item) => {
            if(item === document_info.id) {
              dispatch(document_set_info(document_list[0]));
              dispatch(initKonvaAction())
            }
          })
          dispatch(selectedImageIdsAction([]));
          dispatch(getDrawDetailsAction(document_draw?.id));
          dispatch(basic_open_snackbar({t: 'success', m: TEXT_LIST.has_removed}));
        })
      } catch(e) {
        dispatch(basic_open_snackbar({t: 'error', m: getErrorMessage(e)}));
      }
      dispatch(basic_loading(false));
      
    },
    onDeselectListItem: () => {
      dispatch(selectedImageIdsAction([]));
    },
    saveDocumentInfoCanvas: (draw_detail_id) => {
      dispatch(saveDocumentInfoCanvasAction(draw_detail_id))
    },
    /**
     * @author quyennguyen
     */
    onChangeElementsKonva: (elements) => {
      dispatch(setKonvaElementsAction(elements));
    },
    onChangeTool: (tool) => {
      dispatch({
        type: KONVA_CHANGE_TOOL,
        payload: tool
      })
    },
    addText: (color = "#000") => {
      dispatch({
        type: KONVA_ADD_TEXT,
        payload: {
          type: ELEMENT_TYPE.TEXT,
          text: TEXT_LIST.text_input,
          x: 50,
          y: 50,
          color
        }
      })
    },
    dupplicateImage: (list) => {
      dispatch({
        type: DOCUMENT_LIST,
        payload: list
      })
    },
    changeScale: stageScale => {
      dispatch({
        type: KONVA_CHANGE,
        payload: {
          stageScale
        }
      })
    },
    // onSaveDraw: (drawId) => {
    //   dispatch(uploadDrawDetailsAction(drawId))
    // },
    clearConfirm: (drawId) => {
      dispatch(clearConfirmAction(drawId))
    },
    openPreviewDialog: (component) => {
      dispatch(openHOCModalAction(component))
    },
    onChangeOpenRegisterUser: () => {
      register_user.open_dialog = !register_user.open_dialog;
      if(register_user.open_dialog) {
        dispatch(draw_set_register_user(register_user));
      } else {
        dispatch(draw_set_register_user());
      }
    },
    downloadImages: (image) => {
      dispatch(downloadImagesAction(USER_TYPE.SELLER, image))
    },
    selectImage: (imageId, checked) => {
      if(checked) dispatch(selectedImageIdsAction(uniqueArray([...selectedImageIds, imageId])))
      else dispatch(selectedImageIdsAction(uniqueArray(selectedImageIds.filter((item, index) => item !== imageId))))
    },
    handleSearchDrawList: (value) => {
      if(value === '') return dispatch(document_set_list([...document_list_storage]));
      const listDrawSearched = [...document_list_storage]?.filter((item) => item?.file_same_name?.includes(value));
      dispatch(document_set_list([...listDrawSearched]));;
    },
    onSelectRoomForSendMessageConfirm: ({value, draw_id}) => {
      dispatch(draw_set_room_select({value, draw_id}));
    },
  }
};

// const sortDocumentByDate = (documents) => {
//   let sortDocument = [];
//   for (const document of documents) {
//     const date = moment(document.date).format('YYYY/MM/DD');
//     const index = sortDocument.findIndex(s => s.date === date);
//     if (index > -1) {
//       sortDocument[index].images.push(document)
//     } else {
//       sortDocument.push({date: date, images: [document]})
//     }
//   }
//   return sortDocument;
// };

export const onChangeView = (dispatch) => {
  const {view} = document_filter;
  document_filter.view = view !== 'list' ? 'list' : 'grid';
  dispatch(document_set_filter(document_filter))
};


export const handleDropFiles = async (files,dispatch,scale = 3)=>{
      dispatch(basic_loading(true));
      // let isLoadFile = true;
      if(document_draw.from_user_id !== me.id){
        dispatch(basic_loading(false));
        dispatch(basic_open_snackbar({t: 'error', m: '別のユーザーが作成したプロジェクトにファイルをアップロードすることはできません。'}));
        return true;
      }
      let attachments = [];
      
      // Check size image max;
      const size_image_max = sizeImageMax();
      let errors_upload = [];
      for (const [index, file] of files.entries()) {
        if (file.size > size_image_max) errors_upload.push(file.name);
      }
      if(errors_upload.length) {
        errors_upload.unshift(`添付可能なファイルサイズは${size_image_max}MB以下です`);
        dispatch(basic_open_snackbar({t: 'error', m: errors_upload.join('\n')}));
        dispatch(basic_loading(false));
        return true;
      }
      for (const [index, file] of files.entries()) {
        // isLoadFile = true
        // if(file.size > size_image_max){
        //   isLoadFile = false
        // }
        let convert_file = await convertToBase64(file)
        // if(isLoadFile || file.type !== "application/pdf"){
        //   // convert_file = ;
        // }

        if(file.type === "application/pdf") {
          const result = file.name.includes("min");

          const imagesData = await convertBase64PdfToImage(convert_file.base64,result ? 1 : 3);
          convert_file.base64 = imagesData.base64;
          convert_file.isPdf = true;
          const addFieldName = {...convert_file, file_name: convert_file?.name};
          attachments.push(addFieldName);
        }else{
          const addFieldName = {...convert_file, file_name: convert_file?.name};
          attachments.push(addFieldName);    
        }      
      }
      if(!attachments.length){
        dispatch(basic_open_snackbar({t: 'error', m: 'Not found attachments'}));
        return true;
      }
      // if(attachments.length)
      dispatch(uploadFileDrawDetailsAction(document_draw.id, attachments));
}

export const compressPdf = async (file,dispatch)=>{
  dispatch(basic_loading(true));
  let pdfDoc = null,
      pageRendering = false,
      pageCount = 0,
      pageNumPending = null,
      pageScale = 1,
      pageQuality = 0.5,
      pageQualityUI = 0.5,
      pageFormat = "image/jpeg",
      imgData = null;
  let tenet = [];
  const canvas = document.createElement('canvas');
  var ctx = canvas.getContext("2d");
  var ordered_input_files = [];

  let pdfName = file["name"];
  let pdfFileObject = file;
  let selected_file_name = pdfName;

  const reader = new FileReader();
  reader.onload = function (e) {
    var url = e.target.result;
   pdf2img(url);
  };
  reader.readAsDataURL(pdfFileObject);


  function pdf2img(pdf_url){
    readPDF(pdf_url).then(
      () => downloadAll(),
      () => {
        console.log("Error reading PDF");
      }
    );
  }
  

  function readPDF(url){
    return new Promise((resolve, reject) => {
      var loadingTask = PDFJS.getDocument(url);
      loadingTask.promise.then(
        function (pdfDoc_) {
          pdfDoc = pdfDoc_;
          resetPDFMetaStore(pdfDoc.numPages);
          // selected_file_name += " - " + pageCount + " Pages";
          resolve(1);
        },
        () => reject(1)
      );
    });
  }

  function resetPDFMetaStore(numPages) {
    pageCount = numPages;
    imgData = {};
    pageNumPending = [];
    pageScale = 1;
    pageQuality = 0.5;
    pageQualityUI = 0.5;
    pageFormat = "image/jpeg";
  }

  function downloadAll() {
    for (let i = 1; i <= pageCount; i++) {
      queueRenderPage(i);
    }
  }
  function queueRenderPage(num) {
    dispatch(basic_loading(true));
    if (pageRendering) {
      pageNumPending.push(num);
    } else {
      renderPage(num);
    }
  }

  function renderPage(num) {
    dispatch(basic_loading(true));
    pageRendering = true;
    // Using promise to fetch the page
    pdfDoc.getPage(num).then(function (page) {
      var viewport = page.getViewport({
        scale: pageScale
      });
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };
      var renderTask = page.render(renderContext);

      // Wait for rendering to finish
      renderTask.promise.then(function () {
        let data = canvas.toDataURL(pageFormat, pageQuality);

        imgData[num] = data;
        pageRendering = false;

        if (pageNumPending !== null && pageNumPending.length != 0) {
          renderPage(pageNumPending.shift());
        } else {
          if (Object.keys(imgData).length == pageCount) {
            tenet.push(JSON.parse(JSON.stringify(imgData)));
            console.log("Rendering complete");
            checkFileProcessProgress();
          }
        }
      });
    });
  }

  function checkFileProcessProgress() {
    if (ordered_input_files.length == 0) {
      processImageData();
    } else {
      compressPdf();
    }
  }

  async function processImageData() {
    var options = {
      autoFirstPage: false,
      compress: false,
    };
    var doc = new window.PDFDocument(options);
    const stream = doc.pipe(blobStream());

    for (let k = 0; k < tenet.length; k++) {
      var imgData = tenet[k];
      console.log("PDF %d: %d pages", k + 1, Object.keys(imgData).length);
      for (let i = 1; i <= Object.keys(imgData).length; i++) {
        let img_data = await getImgObj(imgData[i]);
        doc.addPage({
          size: [img_data.width, img_data.height],
        });
        doc.image(img_data.src, 0, 0);
      }
    }

    doc.end();

    stream.on("finish",async function () {
      var output_blob = stream.toBlob("application/pdf");
      output_blob.name = selected_file_name
      dispatch(basic_open_snackbar({t: "success", m: "File compressed. Uploading file..."}));
      await handleDropFiles([output_blob],dispatch,1)
      // const a = await convertToBase64(output_blob);
      // FileSaver.saveAs(output_blob, `${selected_file_name}`);
      // fileLarge = true;
    });
  }
  function getImgObj(data) {
    return new Promise(function (resolve, reject) {
      var img = new Image();
      img.onload = function () {
        resolve(img);
      };
      img.src = data;
    });
  }
}

const CDocuments = connect(mapStateToProps, mapDispatchToProps)(Documents);

export default CDocuments;