import { Box, Button, ImageListItem, Stack, Tooltip } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './style.scss';
import CustomIconConfirm from 'containers/common/customIconConfirm';
import CustomProcessCancel from 'containers/common/customProcessCancel';

const DragDropContextCombine = ({
  handleBackStep,
  listImageDrag,
  handleRemoveItemDrag,
  onDragEnd,
  downloadCombinePdf,
}) => {
  const [grid] = useState(8);
  const [imgHover, setImgHover] = useState();

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      userSelect: 'none',
      margin: `0 ${grid}px 0 0`,
      ...draggableStyle,
    };
  };

  const getListStyle = () => ({
    display: 'flex',
    padding: grid,
    overflow: 'auto',
    marginBottom: '20px',
  });

  const dragDropContextItem = useMemo(() => {
    return (
      <>
        {listImageDrag.map((image, key) => {
          const { id, attachment, title, process , to_confirmed} = image;
          const background = process && String(process).includes("confirm") ? '#f8a64e' :'#ff0000';
          let badgeName ;
          let isWarning = null ;
          if(to_confirmed){
            badgeName = process === "confirmed" ? '確認済み' : '記入済み'
          }else{
            if(process && process.includes("cancel")){
              badgeName = '差戻し'
              isWarning = true
            }else{
              badgeName = process === "request-confirm" ? '要確認' : '要記入'
            }  
          }
          return (
            <Draggable key={id} draggableId={id} index={key}>
              {(provided, snap) => (
                <ImageListItem
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snap.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <Tooltip
                    placement="top"
                    arrow
                    // title={imgHover?.id === image?.id && file_same_name}
                    key={key}
                  >
                    <div
                      key={key}
                      className={`cursor-pointer `}
                      onMouseOver={(e) => {
                        setImgHover(image);
                      }}
                    >
                      <Stack
                        type="button"
                        className="closeIcon"
                        onClick={() => handleRemoveItemDrag({ id })}
                      >
                        {imgHover?.id === id && (
                          <i className="fa-solid fa-xmark"></i>
                        )}
                      </Stack>
                      {
                        process && 
                        <div className="confirmIcon" style={{background:`${background}`}}>
                          {badgeName}
                        </div>
                      }
                      {
                        process && to_confirmed && <CustomIconConfirm/>
                      }
                      {
                       process && !to_confirmed && isWarning!= null && <CustomProcessCancel isWarningRed={isWarning} top={40} />
                      }
                      
                      <img
                        src={attachment?.url}
                        alt={title}
                        loading="lazy"
                        width={120}
                        height={120}
                        className="objectFitCover"
                      />
                    </div>
                  </Tooltip>
                </ImageListItem>
              )}
            </Draggable>
          );
        })}
      </>
    );
  }, [listImageDrag, imgHover]);

  return (
    <Stack className="dragDropContext">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snap) => (
            <div
              ref={provided.innerRef}
              style={getListStyle()}
              {...provided.droppableProps}
            >
              {dragDropContextItem}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box className="stackBtn">
        <Button onClick={handleBackStep}>
          <i className="fa-solid fa-chevron-left mr-1"></i>
          戻ってファイルを選択
        </Button>
        <Button className="btnSubmit" onClick={downloadCombinePdf}>
          まとめてダウンロード
        </Button>
        <Button></Button>
      </Box>
    </Stack>
  );
};
export default React.memo(DragDropContextCombine);
