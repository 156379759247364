import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Button, InputAdornment, TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const UploadAndSearchDraw = (props) => {
  const { handleSearchDrawList, onDropFiles } = props ?? {};

  const onDrop = useCallback(
    (acceptedFiles) => {
      onDropFiles(acceptedFiles);
    },
    [props]
  );

  const { open, getInputProps, getRootProps } = useDropzone({
    onDrop,
    // noClick: true,
    // noKeyboard: true,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'application/pdf': ['.pdf'],
    },
    multiple: !!props.isMultiple,
  });

  const rendersearchItem = useMemo(() => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <TextField
        className="mb-2"
        onInput={(e) => {
          handleSearchDrawList(e.target.value);
        }}
        placeholder="ファイルを検索"
        variant="outlined"
        size="small"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      </div>
    );
  }, []);

  return (
    <div className="container position-relative" {...getRootProps()}>
      {rendersearchItem}
      <Button  className="btn-upload text-white w-100">
        <input {...getInputProps()} />
        ファイルをアップロード
        <FileUploadOutlinedIcon  />
      </Button>
    </div>
  );
};
export default React.memo(UploadAndSearchDraw);
