import { SUB_DRAW_INFO } from "constants/store";

const sub_draw = (state = [], action) => {
  switch (action.type) {
    case SUB_DRAW_INFO:
      return Object.assign({}, action.payload);
    default:
      return state;
  }
}

export default sub_draw;
