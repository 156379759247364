import {DRAW_REGISTER_DRAW} from "../../constants/store";

const state_default = {
  open_dialog: false,
  name: '',
};
const register = (state = state_default, action) => {
  switch (action.type) {
    case DRAW_REGISTER_DRAW:
      return Object.assign({}, action.payload);
    default:
      return state;
  }
}

export default register;
