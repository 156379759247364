import React, { useState } from 'react'
import "./CG3DForm.css"
import { URL_DOMAIN_LIST } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { basic_set_open_snackbar } from 'actions/basic'

export default function CG3DForm({create3DUser,closeModal,addNewFrame}) {

    // const [url3DUser, seturl3DUser] = useState("")
    const [urlFrame, seturlFrame] = useState("")
    const [isShowListDomain, setisShowListDomain] = useState(false)

    const dispatch = useDispatch()
    
    const drawInfo = useSelector((a)=>a.draw.info)
    const me = useSelector((a)=>a.basic.me)

    const handleCreate3DUser = (url)=>{
        create3DUser(url)
    }
    const handleAddNewFrame =async (url)=>{
        await addNewFrame(url)
        closeModal(false)
    }
    const handleCloseModal = ()=>{
        closeModal(false)
    }

    const handleAddUrl = (url)=>{
        if(url.includes('3dcg.test-mina-toku.jp') || url.includes('localhost') && url.includes('/properties/') || url.includes('3dcg.mina-toku.jp')){
            if(me && drawInfo.id){
                handleCreate3DUser(url)
            }
        }else{
            handleAddNewFrame(url)
        }
    }

    return (
      <> 
        {/* {
        isIframe && 
            <div
                className='cg-dialog'
            >
                <div className='cg-dialog-child'>
                <div className='cg-modal'>
                    <p className='cg-modal-title'>URL :</p>
                    <div className='cg-modal-form'>
                    <input className='cg-modal-input' type='text' placeholder='モデルのURLを入力' onChange={(e)=>{
                        seturl3DUser(e.target.value)
                    }}/>
                    <button className='cg-modal-confirm' onClick={()=>handleCreate3DUser(url3DUser)}>登録</button>
                    </div>
                </div>
                <div className='cg-close' onClick={()=> handleCloseModal()}>X</div>
                </div>
            
            </div>
        } */}

        <div
            className='cg-dialog'
        >
            <div className='cg-dialog-child'>
            <div className='cg-modal'>
                <p className='cg-modal-title fw-bold p-url' >URLを追加</p>
                <div className='cg-modal-form'>
                    <input className='cg-modal-input' type='text' placeholder='URLを入力' onChange={(e)=>{
                        seturlFrame(e.target.value)
                    }} />
                    <div className='cg-url-confirm' onClick={()=>handleAddUrl(urlFrame)}>登録</div>
                </div>
                <div className='cg-domain-link' onClick={()=> setisShowListDomain((prev)=> !prev)}>対応サービスについて</div>
            </div>
            <div className='cg-url-close' onClick={()=> handleCloseModal()}><i className="fa-solid fa-xmark" style={{fontSize:"34px",color:"#9e9e9e"}}></i></div>
            {
                isShowListDomain && 
                <div className='cg-domain-wp'>
                    <div className='cg-domain-content'>
                        <div className='cg-domain-close' onClick={()=> setisShowListDomain(false)}><i className="fa-solid fa-xmark" style={{fontSize:"34px",color:"#9e9e9e"}}></i></div>
                        <p>対応サービスURL</p>
                        <div className='cg-domain-list-item'>
                            {
                                URL_DOMAIN_LIST.map((item,index)=>{
                                    return <p key={index}>{item.domain}</p>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            </div>
        
        </div>
      </>
    );
}
