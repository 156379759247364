import { ROOM_SELECT } from "../../constants/store";

// const drawStorage = JSON.parse(localStorage.getItem('room_select')) || [];
// const setLocalDrawSelectRoom = (state) => {
//   localStorage.setItem('room_select', JSON.stringify(state));
// }
const room_select = (state = {}, action) => {
  switch (action.type) {
    case ROOM_SELECT:
      const {draw_id, value} = action.payload;
      const itemToPush = {...value, draw_id};
      return Object.assign({}, itemToPush);
    //   const index = state.findIndex(item => item.draw_id === itemToPush.draw_id);
    //   if(index < 0) {
    //     const resFirst = [...state, itemToPush];
    //     setLocalDrawSelectRoom(resFirst);
    //     return state = resFirst;
    //   } else {
    //     state[index] = itemToPush;
    //     setLocalDrawSelectRoom(state);
    //     return state;
    //   }
    default:
      // setLocalDrawSelectRoom(state);
      return state;
  }
}

export default room_select;
