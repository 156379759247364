import { basic_loading, basic_set_open_snackbar } from "actions/basic";
import { SHAPESPARK_TYPES, URL_DOMAIN_LIST } from "../constants/index";
import moment from "moment";
import { createClient3D, createClientDraw } from "constants/utils";
import { ADD_NEW_FRAME_3D, DELETE_CG_3D, DELETE_CG_DRAW, REMOVE_FRAME_3D, SIGN_IN_CLIENT_WITH_DRAW, UPDATE_FRAME_INDEX } from "share/mutation";
import Swal from 'sweetalert2';
export const getBrowser = () => {
    const test = function(regexp) {return regexp.test(window.navigator.userAgent)};
    switch (true) {
      case test(/edg/i): return "Microsoft Edge";
      case test(/trident/i): return "Microsoft Internet Explorer";
      case test(/firefox|fxios/i): return "Mozilla Firefox";
      case test(/opr\//i): return "Opera";
      case test(/ucbrowser/i): return "UC Browser";
      case test(/samsungbrowser/i): return "Samsung Browser";
      case test(/chrome|chromium|crios/i): return "Google Chrome";
      case test(/safari/i): return "Apple Safari";
      default: return "Other";
    }
};

export const getTitleSPByType = (type) => {
    const shape_spark = SHAPESPARK_TYPES.find(s => s.type === type);
    return shape_spark ? shape_spark.title : '';
};
export const postMessages = (data) => {
   document.getElementById('3d-render').contentWindow.postMessage(data, '*');
};



export const copyUrl =(url,dispatch)=>{
    navigator.clipboard.writeText(url);
    dispatch(
        basic_set_open_snackbar({
          t: 'success',
          m: 'URLがコピーされました',
        })
    ); 
}

export const checkExpired = (date) => {
    if (!date) return true;
    
    let end_date = moment(date).format("YYYY-MM-DD");
    end_date = moment(end_date);
    
    let current_date = moment().format("YYYY-MM-DD");
    current_date = moment(current_date);
    
    return current_date > end_date;
};
export const getErrorMessage = (error, dispatch) => {
    let error_message = 'エラーが発生しました。もう一度お試しください';
    if (error.response && error.response.errors[0].message) {
      error_message = error.response.errors[0].message;
    }
    return error_message;
};

export const login3DWithDraws = async (me,u_id,dispatch) => {

    if(!u_id){
        dispatch(basic_set_open_snackbar({t: 'error', m: 'USER NOT FOUND'}));
        return;
    }
    const client = createClient3D()
    try {
        const signData = await client.request(SIGN_IN_CLIENT_WITH_DRAW, {email:me.email,u_id, id:me.id});
        const {token} = signData.signInClientWithDraw;
        await localStorage.setItem("sp-client-token", token);
    } catch (e) {
        const error = getErrorMessage(e, dispatch);
        dispatch(basic_set_open_snackbar({t: 'error', m: error}));
    }
}


const handleCustomYoutubeUrl = (url,type)=>{
    let split_array ;
    if(type === 'youtu.be'){
        split_array = url.split("/");
        const result = String(split_array[split_array.length - 1]).split('?')
        return "https://www.youtube.com/embed/"+result[0];
    }

    if(type === 'youtube'){
        split_array = url.split("/");
        const result = String(split_array[split_array.length - 1]).split('?')
        return "https://www.youtube.com/embed/"+String(result[result.length - 1].substring(2));
    }

}

export const AddNewFrameItem = async(drawDetail,url,dispatch)=>{
    // ADD_NEW_FRAME_3D
    let type = "unknown";
    let isValueUrl = false;
    let newUrl = url;
    let drawData = drawDetail;
    if(url.indexOf("3dcg.test-mina-toku.jp") != -1 || url.indexOf("3dcg.mina-toku.jp") != -1){
        Swal.fire({
            text: '3dcgの他のCGのURLになりましたので こののURLが接続できません',
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
        });
        return false;
    }

    URL_DOMAIN_LIST.forEach((item)=> {
        if(url.indexOf(item.type) != -1){
            type = item.type;
            isValueUrl = true;
            if(item.type === "youtube" || item.type === "youtu.be"){
                newUrl =  handleCustomYoutubeUrl(url,item.type);
            }
        }
        
    })
    if(url.length <= 0){
        dispatch(basic_set_open_snackbar({ t: 'error', m: "URLを入力してください" }));
        return false;
    }
    if(!isValueUrl){
        dispatch(basic_set_open_snackbar({ t: 'error', m: "正しいURLを入力してください" }));
        return false;
    }
    let time = new Date().getTime()
    
    const client = createClientDraw();

    const variables={
        draw_id: drawData.id,
        frames_data: {id:time+type,url:newUrl,type:type},
    }

    try {
        const newData = await client.request(ADD_NEW_FRAME_3D, variables)
        if(newData.addNewFrame){
            return newData.addNewFrame;
        }
    } catch (error) {
        const msg = getErrorMessage(error);
        dispatch(basic_set_open_snackbar({ t: 'error', m: msg }));
        return false
    }
}

export const removeFrameItem = async (draw_id,frame_id,dispatch) => {
    const client = createClientDraw()
    const variables={
        draw_id: draw_id,
        id: frame_id
    }
    try {
        const newData = await client.request(REMOVE_FRAME_3D, variables)
        if(newData.removeFrame){
            return {
                status:true,
                data:newData.removeFrame
            }
        }
    } catch (error) {
        const msg = getErrorMessage(error);
        return {
            status:false,
            data:msg
        }
    }
    // REMOVE_FRAME_3D
}

export const removeCGItem = async (draw_id,cg_property_id,dispatch) => {
    const client = createClientDraw()
    const client3D = createClient3D()
    const variables={
        draw_id: draw_id,
        id: cg_property_id
    }
    
    try {
        const drawDeleteResult = await client.request(DELETE_CG_DRAW, variables)
        if(drawDeleteResult.removeCG){
            const cgDeleteResult = await client3D.request(DELETE_CG_3D, {property_id:cg_property_id})
            if(cgDeleteResult.deleteUserPropertiesData){
                return {
                    status:true,
                    data:"Success"
                }
            }
           
        }
    } catch (error) {
        const msg = getErrorMessage(error);
        return {
            status:false,
            data:msg
        }
    }
}

export const checkRemoveFrame = async () => {
    const result = await Swal.fire({
        icon: 'question',
        title: '<h5>このサービスを削除してもいいですか?</h5>',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'キャンセル',
        width: 590,
    });
    return result.value;
};

export const updateFrameIndex = async (draw_id,index,old_index,frame_id) => {
    const client = createClientDraw()
    const variables={
        draw_id,
        index:String(index),
        old_index: String(old_index),
        frame_id:frame_id
    }
    try {
        const newData = await client.request(UPDATE_FRAME_INDEX, variables)
        if(newData.updateFrameIndex){
            return {
                status:true,
                data:newData.updateFrameIndex
            }
        }
    } catch (error) {
        const msg = getErrorMessage(error);
        return {
            status:false,
            data:msg
        }
    }
}


export const handleSortArray = (a,b)=>{
    if ( a.cg_index < b.cg_index ){
      return -1;
    }
    if ( a.cg_index > b.cg_index ){
      return 1;
    }
    return 0;
}