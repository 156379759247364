const sortDocuments = (documents, meId) => {
  const sortedDocuments = documents
    .filter((item) => item?.process)
    .sort((a, b) => {
      const nameA = a.process?.toUpperCase();
      const nameB = b.process?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
    .concat(documents.filter((item) => !item.process && meId !== item.owner_id))
    .concat(
      documents.filter((item) => !item.process && meId === item.owner_id)
    );

  return sortedDocuments;
};

export { sortDocuments };
