import { createAction } from 'redux-actions';
import {
  DRAW_SELECT_IMAGES,
  KONVA_CHANGE_ELEMENT,
  KONVA_CHANGE_HISTORY,
  KONVA_CHANGE_STEP,
  KONVA_CHANGE_TOOL,
  KONVA_ELEMENT,
  KONVA_INIT,
  KONVA_IS_CHANGED,
  KONVA_REFRESH_POSITION,
  KONVA_SCALE_SCREEN,
} from '../constants/store';
import { ELEMENT_TYPE } from '../custom-components/KonvaCanvas/Konva.helpers';

export const initKonvaAction = createAction(KONVA_INIT);
export const changeStepKonvaAction = createAction(KONVA_CHANGE_STEP);
export const changeToolKonvaAction = createAction(KONVA_CHANGE_TOOL);
export const changeElementKonvaAction = createAction(KONVA_CHANGE_ELEMENT);
export const setElementKonvaAction = createAction(KONVA_ELEMENT);
export const setKonvaElementsAction = createAction(KONVA_CHANGE_HISTORY);
export const refreshPositionKonvaAction = createAction(KONVA_REFRESH_POSITION);
export const setScaleByScreenKonvaAction = createAction(KONVA_SCALE_SCREEN);
export const setIsChangedKonvaAction = createAction(KONVA_IS_CHANGED);
export const selectedImageIdsAction = createAction(DRAW_SELECT_IMAGES);
export const checkEmptyElement = (element) => {
  return (
    element &&
    (element.type === ELEMENT_TYPE.LINE ||
      element.type === ELEMENT_TYPE.ARROW) &&
    (!element.points || element.points.length <= 2)
  );
};
