import { swalFire } from 'constants/swal';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const DropzoneCustom = (props) => {
  const { onDropFiles, children, onDragFilesEnter, onDragFilesLeave } = props;

  const onDrop = useCallback(
    (acceptedFiles) => {
      onDropFiles(acceptedFiles);
    },
    [props]
  );

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    onDrop,
    onDragEnter: onDragFilesEnter,
    onDragLeave: onDragFilesLeave,
    // noClick: true,
    // noKeyboard: true,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'application/pdf': ['.pdf'],
    },
    multiple: !!props.isMultiple,
  });

  if(isDragReject) {
    swalFire({
      icon: "error",
      title: '画像、またはPDFファイルのみアップロードが可能です。',
      timer: '10000',
      width: '50vw',
    })
  }

  return (
    <div {...getRootProps({ className: 'dropzone', onClick: event => event.stopPropagation() })}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};
export default React.memo(DropzoneCustom);
