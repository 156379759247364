import React from 'react';

class Upsells extends React.Component {
  componentDidMount() {
    this.props.onInit();
  }
  
  render() {
    return (
      <h4 className="text-center pt-5">Draw</h4>
    );
  }
}
export default Upsells;