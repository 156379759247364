import { DRAW_DETAIL } from "../constants/store"
import { userRequestGraphQL } from "../constants/utils"
import { GET_SUB_DRAW, USER_GET_DRAW_DETAIL_BY_ID } from "../share/query"
import { sub_draw_set_info } from "./draw"

export const setDrawDetailAction = (payload) => {
    return {
        type: DRAW_DETAIL,
        payload
    }
}

export const userGetDrawDetailByIdAction = (drawDetailId) => {
    return async (dispatch) => {
        try {
            const data = await userRequestGraphQL(USER_GET_DRAW_DETAIL_BY_ID, {drawDetailId});
            dispatch(setDrawDetailAction(data.userGetDrawDetailById))
            await userRequestGraphQL(GET_SUB_DRAW, {draw_parent_id: drawDetailId}).then((sub_info) => {
                let subData = sub_info.getSubDraws
                if(subData.length > 0){
                  subData.unshift(data.userGetDrawDetailById)
                } 
                dispatch(sub_draw_set_info(subData));
            })
        } catch (error) {
            throw error
        }
    }
}