import {combineReducers} from "redux";
import list_user from "./list_user";
import list_draw_schedule from "./list_draw_schedule";
import status_list_draw_schedule from "./status_list_draw_schedule";

const schedule = combineReducers({
    list_user,
    list_draw_schedule,
    status_list_draw_schedule,
});

export default schedule;
