import {connect} from "react-redux";
import Upsells from '../../containers/pages/upsells'
// import {upsell_set_info} from "../../actions/upsell";

const mapStateToProps = (state) => {
  return {
    upsell_info: state.upsell_info
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => {
    }
  }
};

const CUpSells = connect(mapStateToProps, mapDispatchToProps)(Upsells);

export default CUpSells;