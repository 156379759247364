import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import {
  CustomArrow,
  CustomCheck,
  CustomCircle,
  CustomLine,
  CustomSquare,
  CustomText,
  URLImage,
  URLImageRef,
} from '../../../../custom-components/KonvaCanvas/Konva.components';
import { ELEMENT_TYPE } from '../../../../custom-components/KonvaCanvas/Konva.helpers';
import { TOOL } from '../../../../reducers/konva-canvas/konva-canvas';
import { WIDTH_TABLET } from '../../../../constants/index';
import { useMediaQuery } from '@mui/material';

export const ImageConfirm = ({
  elements,
  allowEditing,
  base64,
  color,
  tool,
  setTool,
  stageX,
  stageY,
  setStageX,
  setStageY,
  stageScale,
  elementsCanvas,
  setElementCanvas,
  scrollRef,
  setElementActive,
  fontSize,
}) => {
  const isDrawing = React.useRef(false);

  const objectSelected = { key: -1, isEditing: false };
  const [selected, setSelected] = useState(objectSelected);
  const [isEditing, setIsEditing] = useState(false);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [scale, setScale] = useState(1);
  const [forceReload, setForceReload] = useState(0);
  const konvaRef = useRef(null);
  const pointer = useRef({
    x: 0,y: 0
  })
  const elementsMaxKey = elements?.length
    ? elements.sort((a, b) => b.key - a.key)[0].key
    : 0;
  const localColor = localStorage.getItem("draw-color") ? localStorage.getItem("draw-color") : "#000";
  color = localColor;
  const isTablet = useMediaQuery(`(max-width:${WIDTH_TABLET}px)`);


  useEffect(() => {
    if(selected.key === -1) return;
    setElementActive(selected);
  }, [selected]);



  const handleMouseDown = (e) => {
    setIsEditing(false);
    if (tool !== TOOL.DRAG && allowEditing && tool !== TOOL.TEXT) {
      isDrawing.current = ![TOOL.CHECK, TOOL.SQUARE, TOOL.CIRCLE].includes(
        tool
      );
      const stageEvent = e.target.getStage();
      const pos = stageEvent.getPointerPosition();


      const element = {
        color: tool !== TOOL.CHECK ? color : '#000',
        key:
          elementsMaxKey +
          (elementsCanvas.length
            ? elementsCanvas[elementsCanvas.length - 1].key + 1
            : 1),
      };

      if ([TOOL.CHECK, TOOL.ARROW, TOOL.PEN, TOOL.STRAIGHT_LINE].includes(tool)) {
        element.points = [pos.x, pos.y];
        element.type =
          tool !== TOOL.CHECK ? ELEMENT_TYPE.LINE : ELEMENT_TYPE.CHECK;
        element.x = 0 - stageX / stageScale - pos.x + pos.x / stageScale;
        element.y = 0 - stageY / stageScale - pos.y + pos.y / stageScale;
      } else if (tool === TOOL.SQUARE) {
        element.width = 100;
        element.height = 100;
        element.type = ELEMENT_TYPE.SQUARE;
        element.x = (pos.x - stageX) / stageScale;
        element.y = (pos.y - stageY) / stageScale;
      } else if (tool === TOOL.CIRCLE) {
        element.x = (pos.x - stageX) / stageScale;
        element.y = (pos.y - stageY) / stageScale;
        element.width = 100;
        element.height = 100;
        element.type = ELEMENT_TYPE.CIRCLE;
      }

      if ([TOOL.CHECK, TOOL.ARROW, TOOL.PEN, TOOL.STRAIGHT_LINE].includes(tool)) {
        element.points = [pos.x, pos.y];
        element.type =
          tool !== TOOL.CHECK ? ELEMENT_TYPE.LINE : ELEMENT_TYPE.CHECK;
        element.x = 0 - stageX / stageScale - pos.x + pos.x / stageScale;
        element.y = 0 - stageY / stageScale - pos.y + pos.y / stageScale;
      } else if (tool === TOOL.SQUARE) {
        element.width = 100;
        element.height = 100;
        element.type = ELEMENT_TYPE.SQUARE;
        element.x = (pos.x - stageX) / stageScale;
        element.y = (pos.y - stageY) / stageScale;
      } else if (tool === TOOL.CIRCLE) {
        element.x = (pos.x - stageX) / stageScale;
        element.y = (pos.y - stageY) / stageScale;
        element.width = 100;
        element.height = 100;
        element.type = ELEMENT_TYPE.CIRCLE;
      }
      setElementCanvas({
        elementsCanvas: [...elementsCanvas, element],
        newStep: true,
      });

      if ([TOOL.SQUARE, TOOL.CIRCLE].includes(tool)) {
        setTool(TOOL.DRAG);
      }

    }
  };

  const handleMouseMove = (e) => {
    if (isDrawing.current) {
      const stageEvent = e.target.getStage();
      const point = stageEvent.getPointerPosition();
      let lastLine = elementsCanvas[elementsCanvas.length - 1];

      // caculate point after scale
      const pointX =
        lastLine.points[0] + (point.x - lastLine.points[0]) / stageScale;
      const pointY =
        lastLine.points[1] + (point.y - lastLine.points[1]) / stageScale;
      // add point
      lastLine.points =
        tool === TOOL.ARROW || tool === TOOL.STRAIGHT_LINE
          ? [lastLine.points[0], lastLine.points[1], pointX, pointY]
          : lastLine.points.concat([pointX, pointY]);
      lastLine.type =
        tool === TOOL.ARROW ? ELEMENT_TYPE.ARROW : ELEMENT_TYPE.LINE;
      // replace last
      elementsCanvas.splice(elementsCanvas.length - 1, 1, lastLine);
      setElementCanvas({ elementsCanvas: [...elementsCanvas] });
    }
  };

  const handleKeyPress = (e) => {
    if (!elementsCanvas.length || !allowEditing) return;

    if (e.keyCode === 46 || e.keyCode === 8) {
      // backspace, delete
      const element_action = elementsCanvas.find(
        (el) => el.key === selected.key
      );
      if (element_action && element_action.type === 'text' && isEditing) return;

      const new_elements = [...elementsCanvas].filter(
        (el) => el.key !== selected.key
      );
      setElementCanvas({ elementsCanvas: new_elements, newStep: true });
      setSelected(objectSelected);
      setIsEditing(true);
      e.preventDefault();
    } else {
      if (![37, 38, 39, 40].includes(e.keyCode)) return true;

      const index = elementsCanvas.findIndex((el) => el.key === selected.key);
      if (index < 0) return;

      let el = { ...elementsCanvas[index] };
      const DELTA = 4;
      if (e.keyCode === 37) {
        el.x = el.x - DELTA;
      } else if (e.keyCode === 38) {
        el.y = el.y - DELTA;
      } else if (e.keyCode === 39) {
        el.x = el.x + DELTA;
      } else if (e.keyCode === 40) {
        el.y = el.y + DELTA;
      } else {
        return;
      }
      e.preventDefault();
      changeElement(el);
    }
  };

  const handleMouseUp = useCallback((e) => {
    isDrawing.current = false;
    if(tool === TOOL.DRAG && e.target.className === 'Image') {
      pointer.current = {
        x: null,
        y: null,
        isMove: false
      }
    }
  }, []);

  const changeElement = (ele) => {
    const cpElementsCanvas = [...elementsCanvas];
    const eleIndex = cpElementsCanvas.findIndex((item) => item.key === ele.key);
    if (eleIndex > -1) {
      cpElementsCanvas.splice(eleIndex, 1, { ...ele });
    }
    setElementCanvas({ elementsCanvas: [...cpElementsCanvas], newStep: true });
  };

  const handleText = (e) => {
    if (tool && tool === TOOL.TEXT) {
      const stageEvent = e.target.getStage();
      const pos = stageEvent.getPointerPosition();
      const key =
        elementsMaxKey +
        (elementsCanvas.length
          ? elementsCanvas[elementsCanvas.length - 1].key + 1
          : 1);
      const x = (pos.x - stageX) / stageScale;
      const y = (pos.y - stageY) / stageScale - 8;
      const element = {
        type: ELEMENT_TYPE.TEXT,
        text: '',
        x,
        y,
        key,
        color,
        points: [pos.x, pos.y],
        fontSize,
      };
      setElementCanvas({
        elementsCanvas: [...elementsCanvas, element],
      });
      setSelected({ ...element, isEditing: true });
      setIsEditing(true);
      setTool(TOOL.DRAG);
    }
  };

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target.className === 'Image';
    if (clickedOnEmpty) {
      setSelected(objectSelected);
    }
  };

  // const startScroll = (e) => {
  //   if(tool === TOOL.DRAG && e.target.className === 'Image' && e.evt?.layerX && e.evt?.layerY) {
  //     pointer.current = {
  //       x: e.evt.layerX,
  //       y: e.evt.layerY,
  //       isMove: true
  //     }
  //   }
  // }

  // const handleScroll = (e) => {

  //   if(pointer.current.isMove&&tool === TOOL.DRAG && e.target.className === 'Image' && e.evt?.layerX && e.evt?.layerY) {
  //     scrollRef.current.scrollTo({
  //       left: scrollRef.current.scrollLeft - e.evt.layerX + pointer.current.x || 0,
  //       top: scrollRef.current.scrollTop - e.evt.layerY + pointer.current.y || 0,
  //     })
  //   }
  // }

  // const endScroll = (e) => {
  //   if(tool === TOOL.DRAG && e.target.className === 'Image' && e.evt?.layerX && e.evt?.layerY) {
  //     pointer.current = {
  //       ...pointer.current,
  //       isMove: false
  //     }
  //   }
  // }
  const startScroll = (e) => {
    if(tool === TOOL.DRAG && e.target.className === 'Image' ) {

      const stage = e.target.getStage();
      const targetElementBounds = stage.getClientRect();
  
      let relativeX, relativeY;
  
      if (e.evt.touches && e.evt.touches[0]) {
        const touch = e.evt.touches[0];
        relativeX = touch.clientX - targetElementBounds.left;
        relativeY = touch.clientY - targetElementBounds.top;
      } else {
        relativeX = e.evt.layerX;
        relativeY = e.evt.layerY;
      }
  

      if(relativeX && relativeY) {
        pointer.current = {
          x: relativeX,
          y: relativeY,
          isMove: true
        }
      }
    }
  }

  const handleScroll = (e) => {
    if(pointer.current.isMove&&tool === TOOL.DRAG && e.target.className === 'Image') {
      const stage = e.target.getStage();
      const targetElementBounds = stage.getClientRect();
  
      let relativeX, relativeY;
  
      if (e.evt.touches && e.evt.touches[0]) {
        const touch = e.evt.touches[0];
        relativeX = touch.clientX - targetElementBounds.left;
        relativeY = touch.clientY - targetElementBounds.top;
      } else {
        relativeX = e.evt.layerX;
        relativeY = e.evt.layerY;
      }
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft - relativeX + pointer.current.x || 0,
        top: scrollRef.current.scrollTop - relativeY + pointer.current.y || 0,
      })
    }
  }

  const endScroll = (e) => {
    if(tool === TOOL.DRAG && e.target.className === 'Image' ) {
      pointer.current = {
        ...pointer.current,
        isMove:false
      }
    }
  }

  useEffect(() => {
    let i = new Image();
    i.src = base64;
    i.onload = async function () {
      setWidth(i.width);
      setHeight((i.height * 1000) / i.width);
    };
    setForceReload(forceReload + 1);
    konvaRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setScale(width > 0 ? 1000 / width : 1);
  }, [base64, width, height, scale]);

  useEffect(() => {
    if (tool !== TOOL.DRAG) {
      setSelected(objectSelected);
    }
  }, [tool]);
  let cursor = useMemo(() => {
    switch (tool) {
      case TOOL.DRAG:
        return 'grab';
      case TOOL.TEXT:
        return 'text';
      default:
        return 'default';
    }
  }, [tool]);

  return (
    <div
      ref={konvaRef}
      tabIndex="0"
      id="konva-confirm"
      onKeyDown={handleKeyPress}
      onMouseOut={() => {
        if (isDrawing.current) {
          isDrawing.current = false;
        }
      }}
      style={{ backgroundColor: isTablet ? 'rgba(0,0,0,0.1)' : '', height:isTablet?'100%':'',  cursor }}
    >
      <Stage
        width={1000 * stageScale}
        height={height * stageScale}
        // down
        onMousedown={(e) => {
          if (allowEditing) {
            checkDeselect(e);
            handleMouseDown(e);
          }
          startScroll(e)
        }}
        // onTouchstart={(e) => {
        //   if (allowEditing) {
        //     checkDeselect(e);
        //     handleMouseDown(e);
        //   }
        //   startScroll(e)
        // }}
        onPointerdown={(e) => {
          if (allowEditing) {
            checkDeselect(e);
            handleMouseDown(e);
          }
          startScroll(e)
        }}
       
        // move
        onMousemove={(e) => {
          if(allowEditing) {
            handleMouseMove(e)
          }
          handleScroll(e)
        }}
        // onTouchmove={(e) => {
        //   if(allowEditing) {
        //     handleMouseMove(e)
        //   }
        //   handleScroll(e)
        // }}
        onPointermove={(e) => {
          if(allowEditing) {
            handleMouseMove(e)
          }
          handleScroll(e)
        }}
        // up
        onMouseup={e => {
          if(allowEditing) {
            handleMouseUp(e)
          }
          endScroll(e)
        }}
        // onTouchend={e => {
        //   if(allowEditing) {
        //     handleMouseUp(e)
        //   }
        //   endScroll(e)
        // }}
        onPointerup={e => {
          if(allowEditing) {
            handleMouseUp(e)
          }
          endScroll(e)
        }}
        // draggable={tool === TOOL.DRAG && selected.key < 0}
        // onDragEnd={(e) => {
        //   if (!e.target.parent && e.target.x()< 1000/stageScale && e.target.y()<height*stageScale) {
        //     setStageX(e.target.x());
        //     setStageY(e.target.y());
        //   }
        // }}
        scaleX={stageScale}
        scaleY={stageScale}
        x={stageX}
        y={stageY}
        onClick={(e) => {
          if (tool === TOOL.TEXT) handleText(e);
        }}
        onTap={(e) => {
          if (tool === TOOL.TEXT) handleText(e);
        }}
        onPointerclick={(e) => {
          if (tool === TOOL.TEXT) handleText(e);
        }}
      >
        <Layer className="bg-mac">
          {isTablet ? (
            <URLImageRef
              onMouseOut={() => {
                if (isDrawing.current) {
                  isDrawing.current = false;
                }
              }}
              src={base64}
              scale={scale}
            />
          ) : (
            <URLImage src={base64} scale={scale} />
          )}
          {(elements ?? []).map((ele, i) => {
            switch (ele.type) {
              case ELEMENT_TYPE.LINE:
                return (
                  <CustomLine
                    // key={ele.key}
                      key={i}
                    shapeProps={ele}
                    isSelected={false}
                    onSelect={() => {}}
                    onChange={(newAttrs) => {}}
                  />
                );
              case ELEMENT_TYPE.ARROW:
                return (
                  <CustomArrow
                    // key={ele.key}
                      key={i}
                    shapeProps={ele}
                    isSelected={false}
                    onSelect={() => {}}
                    onChange={(newAttrs) => {}}
                  />
                );
              case ELEMENT_TYPE.SQUARE:
                return (
                  <CustomSquare
                    // key={ele.key}
                      key={i}
                    shapeProps={ele}
                    isSelected={false}
                    onSelect={() => {}}
                    onChange={(newAttrs) => {}}
                  />
                );
              case ELEMENT_TYPE.CIRCLE:
                return (
                  <CustomCircle
                    // key={ele.key}
                      key={i}
                    shapeProps={ele}
                    isSelected={false}
                    onSelect={() => {}}
                    onChange={(newAttrs) => {}}
                  />
                );
              case ELEMENT_TYPE.CHECK:
                return (
                  <CustomCheck
                    // key={ele.key}
                      key={i}
                    shapeProps={ele}
                    isSelected={false}
                    onSelect={() => {}}
                    onChange={(newAttrs) => {}}
                  />
                );
              case ELEMENT_TYPE.TEXT:
                return (
                  <CustomText
                    // key={ele.key}
                      key={i}
                    shapeProps={ele}
                    isSelected={false}
                    onSelect={() => {}}
                    onChange={(newAttrs) => {}}
                    propIsEditing={false}
                  />
                );
              default:
                return null;
            }
          })}
          {allowEditing &&
            elementsCanvas.map((ele, i) => {
              switch (ele.type) {
                case ELEMENT_TYPE.LINE:
                  return (
                    <CustomLine
                      key={ele.key}
                      shapeProps={ele}
                      isSelected={
                        ele.key === selected.key &&
                        allowEditing &&
                        tool === TOOL.DRAG
                      }
                      onSelect={() => {
                        setSelected({ ...ele, isEditing: true });
                      }}
                      onChange={(newAttrs) => {
                        changeElement(newAttrs);
                      }}
                    />
                  );
                case ELEMENT_TYPE.ARROW:
                  return (
                    <CustomArrow
                      key={ele.key}
                      shapeProps={ele}
                      isSelected={
                        ele.key === selected.key &&
                        allowEditing &&
                        tool === TOOL.DRAG
                      }
                      onSelect={() => {
                        setSelected({ ...ele, isEditing: true });
                      }}
                      onChange={(newAttrs) => {
                        changeElement(newAttrs);
                      }}
                    />
                  );
                case ELEMENT_TYPE.SQUARE:
                  return (
                    <CustomSquare
                      key={ele.key}
                      shapeProps={ele}
                      isSelected={
                        ele.key === selected.key &&
                        allowEditing &&
                        tool === TOOL.DRAG
                      }
                      onSelect={() => {
                        setSelected({ ...ele, isEditing: true });
                      }}
                      onChange={(newAttrs) => {
                        changeElement(newAttrs);
                      }}
                    />
                  );
                case ELEMENT_TYPE.CIRCLE:
                  return (
                    <CustomCircle
                      key={ele.key}
                      shapeProps={ele}
                      isSelected={
                        ele.key === selected.key &&
                        allowEditing &&
                        tool === TOOL.DRAG
                      }
                      onSelect={() => {
                        setSelected({ ...ele, isEditing: true });
                      }}
                      onChange={(newAttrs) => {
                        changeElement(newAttrs);
                      }}
                    />
                  );
                case ELEMENT_TYPE.CHECK:
                  return (
                    <CustomCheck
                      key={ele.key}
                      shapeProps={ele}
                      isSelected={
                        ele.key === selected.key &&
                        allowEditing &&
                        tool === TOOL.DRAG
                      }
                      onSelect={() => {
                        setSelected({ ...ele, isEditing: true });
                      }}
                      onChange={(newAttrs) => {
                        changeElement(newAttrs);
                      }}
                    />
                  );
                case ELEMENT_TYPE.TEXT:
                  return (
                    <CustomText
                      key={ele.key}
                      shapeProps={ele}
                      isSelected={
                        ele.key === selected.key &&
                        allowEditing &&
                        tool === TOOL.DRAG
                      }
                      onSelect={() => {
                        setSelected({ ...ele });
                      }}
                      onChange={(newAttrs) => {
                        changeElement(newAttrs);
                        setSelected({ ...selected, isEditing: false });
                      }}
                      propIsEditing={
                        ele.key === selected.key &&
                        allowEditing &&
                        tool === TOOL.DRAG &&
                        selected.isEditing
                      }
                      scaleText={stageScale}
                    />
                  );
                default:
                  return null;
              }
            })}
        </Layer>
      </Stage>
    </div>
  );
};
