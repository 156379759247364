import {
  GET_ALL_USER_BELONG_DRAW_CONST,
  GET_LIST_DRAW_SCHEDULE_CONST,
  STATUS_LIST_DRAW_SCHEDULE,
} from 'constants/store';
import { requestGraphQL } from 'constants/utils';
import { useDispatch } from 'react-redux';
import { createAction } from 'redux-actions';
import { GET_ALL_USER_BELONG_DRAW, GET_LIST_DRAW_SCHEDULE } from 'share/query';

export const get_all_user_belong_draw = createAction(
  GET_ALL_USER_BELONG_DRAW_CONST
);
export const get_list_draw_schedule = createAction(
  GET_LIST_DRAW_SCHEDULE_CONST
);
export const set_status_list_draw_schedule = createAction(
  STATUS_LIST_DRAW_SCHEDULE
);

export const getListDrawSchedule = ({ draw_id, room_id, role, isShowTaskFinish }) => {
  return async (dispatch, getState) => {
    try {
      const data = await requestGraphQL(GET_LIST_DRAW_SCHEDULE, {
        draw_id,
        room_id,
        role,
        isShowTaskFinish,
      });
      if (data && data.getListDrawSchedule) {
        dispatch(get_list_draw_schedule(data.getListDrawSchedule));
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const getListUserBelongDraw = ({ draw_id }) => {
  return async (dispatch, getState) => {
    try {
      const data = await requestGraphQL(GET_ALL_USER_BELONG_DRAW, { draw_id });
      if (data && data.getAllUserBelongDraw) {
        dispatch(get_all_user_belong_draw(data.getAllUserBelongDraw));
      }
    } catch (err) {
      console.error(err);
    }
  };
};
