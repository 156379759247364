import {createAction} from "redux-actions";
import {DOCUMENT_DIALOG, DOCUMENT_FILTER, DOCUMENT_LIST, DOCUMENT_INFO, DOCUMENT_LIST_STORAGE, SUB_DRAW_INFO} from "../constants/store";

export const document_set_filter = createAction(DOCUMENT_FILTER);
export const document_set_list = createAction(DOCUMENT_LIST);
export const document_set_storage = createAction(DOCUMENT_LIST_STORAGE);
export const document_set_info = createAction(DOCUMENT_INFO);
export const document_set_dialog = createAction(DOCUMENT_DIALOG);
export const saveDocumentInfoCanvasAction = (drawDetailId) => {
    return (dispatch, getState) => {
        const {list, info } = getState().document;
        const {sub_draw} = getState().draw;
        let totalList = []
        if(Object.keys(sub_draw).length > 0){
            for (var i=1; i< Object.keys(sub_draw).length; i++){
            totalList.push(sub_draw[i])
            }
        }
        // const {elements} = getState().konvaCanvas;
        const {history, step} = getState().konvaCanvas;
        const elements = history[step]?.elements ?? []
        let document = list.find(item => item.id === drawDetailId);
        let subdrawInfo = totalList.find(item => item.id === drawDetailId);
        const documentSub = list.find(item => item?.id === subdrawInfo?.parent_id);
        if(document) {
            document.elements = elements;
            document.process = info.process
        }
        if(subdrawInfo){
            subdrawInfo.elements = elements;
            subdrawInfo.process = info.process
        }

        if(documentSub) {
            documentSub.process = info.process;
        }
        dispatch({
            type: DOCUMENT_LIST,
            payload:list
        })
        // dispatch({
        //     type: SUB_DRAW_INFO,
        //     payload:subdrawInfo
        // })
        
    }
}