import {createAction} from "redux-actions";

import {DRAG_FILE, PROPERTY_INFO, PROPERTY_LIST, PROPERTY_SIDEBAR, PROPERTY_VIEW} from "../constants/store";
import { requestGraphQL } from "../constants/utils";
import { GET_PROPERTY } from "../share/query";

export const property_set_sidebar = createAction(PROPERTY_SIDEBAR);
export const property_set_list = createAction(PROPERTY_LIST);
export const property_set_info = createAction(PROPERTY_INFO);
export const property_set_view = createAction(PROPERTY_VIEW);
export const property_set_drag_file = createAction(DRAG_FILE);
export const getPropertyByIdAction = (id) => {
    return async (dispatch) =>{
        try {
            const data = await requestGraphQL(GET_PROPERTY, {
                id
            });
            dispatch(property_set_info(data.getProperty))
        } catch (error) {
            console.log(error)
        }
    }
}
