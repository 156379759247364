import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class Snackbars extends React.Component {
  render() {
    const {snackbar, handleClose} = this.props;
    let {open, t, m} = snackbar;
    
    if (!open) return null;
    
    t = ['error', 'warning', 'info', 'success'].includes(t) ? t : 'success';
    
    setTimeout(() => {
      handleClose();
    }, 3000);
    
    return (
      <div>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          message={m}
          style={{whiteSpace: 'pre-line', background: 'white'}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <Alert variant="outlined" onClose={handleClose} severity={t}>
            {m}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default Snackbars;
