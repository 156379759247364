import { TAB_DRAW_SCHEDULE } from "../../constants";
import { STATUS_LIST_DRAW_SCHEDULE } from "constants/store";
const initialState = {
    role: TAB_DRAW_SCHEDULE.ALL,
    isShowTaskFinish: true,
}

const status_list_draw_schedule = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_LIST_DRAW_SCHEDULE:
      return Object.assign({}, action.payload);
    default:
      return state;
  }
};

export default status_list_draw_schedule;