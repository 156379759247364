import { basic_loading, basic_open_snackbar } from "actions/basic";
import { convertBase64PdfToImage, convertToBase64, onPasteFile, sizeImageMax } from "constants/utils";
import {connect} from "react-redux";
import { setOpenConfirmNotificationAction } from "../../../../actions/confirm";
import { document_set_dialog, document_set_info } from "../../../../actions/document";
import {uploadFileDrawDetailsAction, uploadFileDrawDetailsByUserAction, userGetDrawDetailsAction} from "../../../../actions/draw";
import { initKonvaAction, setKonvaElementsAction } from "../../../../actions/konva-canvas";
import { getNotificationsAction } from "../../../../actions/notification";
import {property_set_drag_file, property_set_sidebar} from "../../../../actions/property";
import { BASIC_FIRST_LOAD, CONFIRM_LIST, KONVA_CHANGE_TOOL } from "../../../../constants/store";
import Draw from "../../../../containers/pages/user/draw/draw";
import { TOOL } from "../../../../reducers/konva-canvas/konva-canvas";
import { getListDrawSchedule, getListUserBelongDraw } from "actions/schedule";
import { TAB_DRAW_SCHEDULE } from "../../../../constants";
import { handleUserDropFiles } from "./cdraw.components";

let document_list = [];
let document_info = null;
let property_sidebar = {};
let statusListDrawSchedule = {};
let draw_info = {};
const mapStateToProps = (state) => {
  property_sidebar = state.property.property_sidebar;
  document_list = state.document.list;
  document_info = state.document.info;
  const {list: notification_list} = state.notification;
  const {openNotification} = state.confirm;
  const {dialog: document_dialog} = state.document;
  statusListDrawSchedule = state.schedule.status_list_draw_schedule;
  draw_info = state.draw.info;
  
  return {
    property_sidebar,
    document_list,
    notification_list,
    openNotification,
    document_dialog,
    document_info,
    property_sidebar,
    windowSize: state.basic.windowSize,
    draw_info,
    firstLoad: state.basic.firstLoad,
    isShowQuestion:state.basic.is_show_question,
  }
};

const mapDispatchToProps = (dispatch) => {
  const closeSideBar = (status, property_sidebar) => {
    const sidebar = document.getElementById('sidebar');
    const render = document.getElementById('render');
    
    if (!sidebar || !render) return;
  
    if (status) {
      sidebar.style.display = 'none';
      render.style.width = '100%';
      property_sidebar.status = false;
      dispatch(property_set_sidebar(property_sidebar));
    }
    else {
      // sidebar.style.display = 'flex';
      render.style.width = 'calc(100% - 280px)';
      sidebar.style.display = 'unset';
    }
  };
  const changeSidebar = (type, property_sidebar) => {
    if (property_sidebar.type === type) {
      property_sidebar.status = !property_sidebar.status;
    } else {
      property_sidebar.status = true;
    }
    
    property_sidebar.type = type;
    closeSideBar(!property_sidebar.status);
    
    dispatch(property_set_sidebar(property_sidebar));
  };
  
  const getDrawInfomation = async (drawId) => {
    const {role, isShowTaskFinish} = statusListDrawSchedule;
    dispatch(getListDrawSchedule({ draw_id: drawId, role: TAB_DRAW_SCHEDULE.ALL, isShowTaskFinish }));
    dispatch(getListUserBelongDraw({draw_id: drawId}));

    await dispatch(userGetDrawDetailsAction(drawId))
    if(!document_info.id && document_list.length) {
      dispatch(document_set_info(document_list[0]));
      dispatch(document_set_dialog(true));
      dispatch({type: KONVA_CHANGE_TOOL, payload: TOOL.DRAG});
      dispatch(setKonvaElementsAction({elements: document_list[0].elements ?? []}));
    }
  };

  const getNotifications = () => {
    dispatch(getNotificationsAction());
  }

  const setConfirmList = (list) => {
    dispatch({
      type: CONFIRM_LIST,
      payload: list
    })
  }

  const setOpenConfirmNotification = (isOpen) => {
    dispatch(setOpenConfirmNotificationAction(isOpen))
  }

  const onDropFiles = async (files, drawId) => {
    handleUserDropFiles(files,dispatch,3)
    dispatch(property_set_drag_file({dragFile: false}));
  };

  const onDragFilesEnter = () => {
    if(!property_sidebar.dragFile) {
      dispatch(property_set_drag_file({dragFile: true}));
    }
  };
  
  const onDragFilesLeave = () => {
    if(property_sidebar.dragFile) {
      dispatch(property_set_drag_file({dragFile: false}));
    }
  };

  const setFirstLoad = () => {
    dispatch({
      type: BASIC_FIRST_LOAD,
      payload: false
    })
  }
  const handlePasteFile = (event)=>{
    onPasteFile(event,dispatch)
  }
  return {
    closeSideBar,
    changeSidebar,
    getDrawInfomation,
    getNotifications,
    setConfirmList,
    setOpenConfirmNotification,
    onDropFiles,
    onDragFilesEnter,
    onDragFilesLeave,
    setFirstLoad,
    handlePasteFile
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Draw)