import {DOCUMENT_DIALOG} from "../../constants/store";

const dialog = (state = false, action) => {
  switch (action.type) {
    case DOCUMENT_DIALOG:
      return action.payload;
    default:
      return state;
  }
};

export default dialog;