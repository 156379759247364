import { GET_LIST_DRAW_SCHEDULE_CONST } from "constants/store";

const list_draw_schedule = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_DRAW_SCHEDULE_CONST:
      return Object.assign([], action.payload);
    default:
      return state;
  }
};

export default list_draw_schedule;