export const SHAPESPARK_TYPES = [
  { type: 'load_url', title: 'LOAD_URL' },
  { type: 'distance', title: 'DISTANCE' },
  { type: 'node_type', title: 'NODE_TYPE' },
  { type: 'texture', title: 'TEXTURE' },
  { type: 'texture_url', title: 'TEXTURE_URL' },
  { type: 'popup', title: 'POPUP' },
  { type: 'combo', title: 'COMBO' },
  { type: 'reset_material', title: 'RESET_MATERIAL' },
];

export const TEXT_LIST = {
  main: 'SHAPESPARK',
  email: 'Eメール ', //email
  register: '登録', //đăng ký
  register_mode: '新規登録', //đăng ký
  update: '更新', //cập nhật
  edit: '編集', //chỉnh sửa
  cancel: 'キャンセル', //huỷ bỏ
  create: '作成', //tạo nên
  create_new: '新規作成', //tạo mới
  close: '閉じる', //đóng
  go_back: '戻る', // quay lại
  view: '閲覧', //xem
  delete: '削除', //xoá
  save: '保存',
  save_success: '保存しました', //đã lưu thành công
  upload_success: 'ファイルがアップロードされました', //upload thành công
  invitation: '招待', //lời mời
  has_registered: '登録しました。', //đã đăng ký
  has_updated: '更新しました。', //đã cập nhật
  has_removed: '削除しました', //Nó đã bị xoá
  is_required: 'は必須です', //là bắt buộc,
  confirm_delete: 'このファイルを削除してもよろしいですか？', //Bạn có chắc chắn muốn xóa tệp này không?
  login: 'ログイン', // Dang nhap
  logout: 'ログアウト',
  forgot_password: 'パスワードを忘れた場合', // Neu ban quen mat khau
  send: '送信', //Gui
  price: '価格',
  can_i_save: '保存しても良いですか',
  send_success: 'メールを送信しました',
  dupplicate_success: '元ファイルを複製しました',
  dupplicate_success_after_edit: '編集後ファイルを複製しました',
  revert_success: '画像を元に戻しました',
  send_request: '依頼送信', // Gửi yêu cầu
  btn_finish: '確認完了', // Hoàn thành
  btn_change: '変更', // Hoàn thành
  invalid_password: 'パスワードが無効です',
  cannot_edit: '編集できません',
  btn_confirm: 'はい',
  btn_cancel: 'いいえ',
  // ------------------------------------
  floor: '床', //Sàn nhà
  wall: '壁', //Tường
  fitting: '建具', //Phụ kiện
  kitchen: 'キッチン', //Phòng bếp
  measurement: '計測', // Đo đạc
  // -------------------------------------
  full_name: '氏名',
  tel: '電話番号', //So dien thoai
  password: 'パスワード',
  confirm_password: '確認パスワード',
  email_address: 'メールアドレス',
  malformed: 'が適正な値ではありません。', //Không phải là một giá trị hợp lệ.
  please_check_again_and_enter: 'もう一度ご確認のうえご入力下さい', //Vui lòng kiểm tra lại và nhập
  please_enter: 'をご入力下さい', //Vui lòng nhập
  please_login: 'ログインしてください', //Vui lòng đăng nhập
  text_input: 'テキスト入力', // Text input
  download: 'ダウンロード',
  download_images: 'ファイルダウンロード',
  download_success: '正常にダウンロードされました',
  missing_images: 'ファイルを選択してください',
  success: '成功',
  create_task_success: 'タスクが作成されました'
};

export const USER_ROLE = {
  ADMIN_ASSIGNED: 'admin-assigned',
  ADMIN: 'admin',
  USER: 'user',
};

export const DRAW_BUTTON = {
  LINE: 'LINE',
  ARROW: 'ARROW',
  DRAG: 'DRAG',
  TEXT: 'TEXT',
  CHECK: 'CHECK',
};

export const DRAW_DETAIL_PROCESS = [
  { type: 'request', title: '要記入', color: '#f00' }, //yêu cầu
  { type: 'request-confirm', title: '要確認', color: '#f8a64e' }, //yêu cầu xác nhận
  { type: 'agreed', title: '記入済み', color: '#f00' }, //đã đồng ý
  { type: 'confirmed', title: '確認済み', color: '#f8a64e' }, //đã xác nhận
  { type: 'cancelRequest', title: '差戻し', color: '#f00',badge:'#f00' }, //đã cancel
  { type: 'cancelRequestConfirm', title: '差戻し', color: '#f8a64e',badge:'#f00' }, //đã cancel
];

export const WIDTH_MOBILE = 500;
export const WIDTH_TABLET = 1200;

export const USER_TYPE = {
  SELLER: 'seller',
  END_USER: 'end-user',
};

export const TAB_DRAW_SCHEDULE = {
  GUEST: 'GUEST',
  HOST: 'HOST',
  ALL: 'ALL',
}

export const SMS_METHOD = {
  SMS: 1,
  SPEAK: 6,
}

export const TYPE_PDF = "application/pdf";

export const EXPANDED_CONST = {
  LIST_IMAGE_DRAW: 'LIST_IMAGE_DRAW',
  LIST_SCHEDULE_DRAW: 'LIST_SCHEDULE_DRAW',
};

export const SIDEBAR_RIGHT  = {
  DRAW: "DRAW",
  CG: "CG",
}

// export const URL_DOMAIN_LIST = ['3d-cg','matterport','spacely','gousei','youtube','youtu.be']
export const URL_DOMAIN_LIST = [
  {
    type:'3d-cg',
    domain:'3d-cg.jp (3DVISTA)'
  },
  {
    type:'matterport',
    domain:'my.matterport.com'
  },
  {
    type:'spacely',
    domain:'spacely.co.jp'
  },
  {
    type:'gousei',
    domain:'gousei-ar.mina-toku.jp'
  },
  {
    type:'youtube',
    domain:'youtube.com'
  },
  {
    type:'youtu.be',
    domain:'youtu.be'
  }
]

export * from "./subscription"