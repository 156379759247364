import {DRAG_FILE, PROPERTY_SIDEBAR} from "../../constants/store";

const state_default = {status: true, type: '', action: false, dragFile: false};
export const property_sidebar = (state = state_default, action) => {
  switch (action.type) {
    case PROPERTY_SIDEBAR:
      return Object.assign({}, action.payload);
    case DRAG_FILE:
      return {...state_default, ...action.payload};
    default:
      return state
  }
};