import React, { useEffect, useState } from 'react';
import './style.scss';
import { setScaleByScreenKonvaAction } from 'actions/konva-canvas';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
const CloseSidebar = () => {
  const dispatch = useDispatch();
  const [isCloseSidebar, setCloseSidebar] = useState(false);

  const handleCloseSidebar = () => {
    const sidebar = document.getElementById('sidebar');
    const render = document.getElementById('render');
    const closeSidebar = document.getElementById('closeSidebar');
    const cg3d = document.getElementById('cg-3d');
    const konvaRef = document.getElementById('konvaRef');
    if (konvaRef?.clientWidth) {
      dispatch(
        setScaleByScreenKonvaAction((konvaRef.clientWidth - 20) / 1000)
      );
    }
    setCloseSidebar(!isCloseSidebar);
    if (!isCloseSidebar) {
      sidebar.style.display = 'none';
      if(render) {
        render.style.width = '100%';
      } else if(cg3d) {
        cg3d.style.width = '100%';
      }
      if(closeSidebar) closeSidebar.style.right = '0';

    } else {
      if(cg3d) {
        cg3d.style.width = 'calc(100% - 350px)';
      }
      if(render) {
        render.style.width = 'calc(100% - 350px)';
      }
      sidebar.style.display = 'unset';
      if(closeSidebar) closeSidebar.style.right = '350px';
    }
  };
  
  return (
    <div
      id="closeSidebar"
      onClick={handleCloseSidebar}
      className="close-sidebar-arrow-right"
    >
      <i
        className={`icon fa-solid fa-chevron-${isCloseSidebar ? 'left' : 'right'}`}
      ></i>
    </div>
  );
};
export default React.memo(CloseSidebar);
