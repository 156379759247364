import { GET_NOTIFICATIONS } from "../constants/store";
import { userRequestGraphQL } from "../constants/utils"
import { USER_GET_NOTIFICATIONS } from "../share/query"

export const getNotificationsAction = () => {
    return async (dispatch, getState) => {
        try {
            const data = userRequestGraphQL(USER_GET_NOTIFICATIONS);
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: data.userGetNotifications
            })
        } catch (error) {
            throw error
        }
    }
}