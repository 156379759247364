import { Stack, Typography } from '@mui/material';
import './document.css';
import React from 'react';

const CustomIconConfirm = () => {
  return (
    <React.Fragment>
      <Stack className="stack-confirm">
        <Typography variant="h5" className="type-confirm">
          済
        </Typography>
      </Stack>
    </React.Fragment>
  );
};

export default CustomIconConfirm;
