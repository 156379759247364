import { DRAW_DETAIL } from "../../constants/store"

const initialState = {
    id:null,
    elements: [],
    process: null,
    draw_id: null,
    attachment: null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case DRAW_DETAIL:
    return { ...state, ...payload }

  default:
    return state
  }
}
