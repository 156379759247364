import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Stage, Layer, Rect } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { KONVA_CHANGE, KONVA_ELEMENT } from '../../constants/store';
import { TOOL } from '../../reducers/konva-canvas/konva-canvas';
import {
  CustomArrow,
  CustomCheck,
  CustomCircle,
  CustomLine,
  CustomSquare,
  CustomText,
  URLImage,
} from './Konva.components';
import { ELEMENT_TYPE } from './Konva.helpers';
import { document_set_info } from '../../actions/document';
import {
  changeElementKonvaAction,
  changeStepKonvaAction,
  changeToolKonvaAction,
  checkEmptyElement,
  setElementKonvaAction,
  setIsChangedKonvaAction,
  setKonvaElementsAction,
  setScaleByScreenKonvaAction,
} from '../../actions/konva-canvas';
import { useMediaQuery } from '@mui/material';
import { WIDTH_MOBILE } from '../../../src/constants/index';

const KonvaCanvas = ({scrollRef,onChangeKonVa = null}) => {
  const isDrawing = React.useRef(false);
  const {
    element,
    history,
    step,
    tool,
    stageScale: scaleKonva,
    stageX,
    stageY,
    scaleByScreen,
  } = useSelector((a) => a.konvaCanvas);
  const elements = history[step]?.elements ?? [];

  const isMobile = useMediaQuery(`(max-width:${WIDTH_MOBILE}px)`);

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [scale, setScale] = useState(1);
  const pointer = useRef({
    x: null,
    y: null,
    isMove:false
  })

  const konvaRef = useRef(null);
  const { info } = useSelector((a) => a.document);
  
  const localColor = localStorage.getItem("draw-color") ? localStorage.getItem("draw-color") : "#000";
  const color = info && info.color ? info.color : localColor;
  // const stroke = info && info.stroke ? info.stroke : '#000';
  const fontSize = info && info.fontSize ? info.fontSize : 16;
  const { base64, id , attachment } = info;
  const dispatch = useDispatch();

  const removeLastEmptyElement = () => {
    const lastElement = elements[elements.length - 1];
    if (checkEmptyElement(lastElement)) {
      elements.pop();
      dispatch(changeStepKonvaAction(step - 1));
    }
  };
  const setKonVaChange = (data)=>{
    if(onChangeKonVa){
      onChangeKonVa(data)
    }
    
  }

  const handleText = (e) => {
    if (tool && tool === TOOL.TEXT) {
      const stageEvent = e.target.getStage();
      const pos = stageEvent.getPointerPosition();
      const x = (pos.x - stageX) / stageScale;
      const y = (pos.y - stageY) / stageScale - fontSize / 2;
      const element = {
        isEditing: true,
        type: ELEMENT_TYPE.TEXT,
        text: '',
        color: color || '#000',
        points: [pos.x, pos.y],
        y: y,
        x: x,
        key: elements.length ? elements[elements.length - 1].key + 1 : 0,
        fontSize,
      };

      removeLastEmptyElement();

      if (element?.isEditing) {
        dispatch(setElementKonvaAction(element));
        dispatch(
          setKonvaElementsAction({
            elements: [...elements, element],
          })
        );
      }
      dispatch(changeToolKonvaAction(TOOL.DRAG));
    }
  };

  const handleMouseDown = (e) => {
    //ignore event mouse
    if(e.evt.pointerType === "mouse") {
      return;
    }

    if (tool && ![TOOL.DRAG, TOOL.TEXT].includes(tool)) {
      isDrawing.current = ![TOOL.CHECK, TOOL.SQUARE, TOOL.CIRCLE].includes(
        tool
      );
      const stageEvent = e.target.getStage();
      const pos = stageEvent.getPointerPosition();

      removeLastEmptyElement();
      const element = {
        color,
        // stroke,
        key: elements.length ? elements[elements.length - 1].key + 1 : 0,
      };

      if ([TOOL.CHECK, TOOL.ARROW, TOOL.PEN, TOOL.STRAIGHT_LINE].includes(tool)) {
        element.points = [pos.x, pos.y];
        element.type =
          tool !== TOOL.CHECK ? ELEMENT_TYPE.LINE : ELEMENT_TYPE.CHECK;
        element.x = 0 - stageX / stageScale - pos.x + pos.x / stageScale;
        element.y = 0 - stageY / stageScale - pos.y + pos.y / stageScale;
      } else if (tool === TOOL.SQUARE) {
        element.width = 100;
        element.height = 100;
        element.type = ELEMENT_TYPE.SQUARE;
        element.x = (pos.x - stageX) / stageScale;
        element.y = (pos.y - stageY) / stageScale;
      } else if (tool === TOOL.CIRCLE) {
        element.x = (pos.x - stageX) / stageScale;
        element.y = (pos.y - stageY) / stageScale;
        element.width = 100;
        element.height = 100;
        element.type = ELEMENT_TYPE.CIRCLE;
      }

      dispatch(
        setKonvaElementsAction({
          elements: [...elements, element],
          newStep: true,
        })
      );
      dispatch(setIsChangedKonvaAction(true));
      setKonVaChange(true)
      if ([TOOL.SQUARE, TOOL.CIRCLE].includes(tool)) {
        removeLastEmptyElement();
        dispatch(setElementKonvaAction({ ...element, color: 'transparent' }));
        dispatch(changeToolKonvaAction(TOOL.DRAG));
      }
    }
  };

  const handleMouseMove = (e) => {
    if (isDrawing.current && tool !== TOOL.TEXT) {
      const stageEvent = e.target.getStage();
      const point = stageEvent.getPointerPosition();
      if (elements.length) {
        let lastLine = elements[elements.length - 1];

        // caculate point after scale
        const pointX =
          lastLine.points[0] + (point.x - lastLine.points[0]) / stageScale;
        const pointY =
          lastLine.points[1] + (point.y - lastLine.points[1]) / stageScale;

        lastLine.points =
          tool === TOOL.ARROW || tool === TOOL.STRAIGHT_LINE
            ? [lastLine.points[0], lastLine.points[1], pointX, pointY]
            : lastLine.points.concat([pointX, pointY]);

        lastLine.type =
          tool === TOOL.ARROW ? ELEMENT_TYPE.ARROW : ELEMENT_TYPE.LINE;

        // replace last
        elements.splice(elements.length - 1, 1, lastLine);
        dispatch(setKonvaElementsAction({ elements: elements.concat() }));
      } else {
        const element = {
          points: [point.x, point.y],
          x: 0 - stageX / stageScale - point.x + point.x / stageScale,
          y: 0 - stageY / stageScale - point.y + point.y / stageScale,
          color: color,
          type: tool !== TOOL.CHECK ? ELEMENT_TYPE.LINE : ELEMENT_TYPE.CHECK,
          key: elements.length ? elements[elements.length - 1].key + 1 : 0,
        };
        dispatch(
          setKonvaElementsAction({
            elements: [...elements, element],
            newStep: true,
          })
        );
      }
      dispatch(setIsChangedKonvaAction(true));
      setKonVaChange(true)
    }
  };

  const handleMouseUp = useCallback((e) => {
    isDrawing.current = false;
  }, []);

  const startScroll = (e) => {
    if(tool === TOOL.DRAG && e.target.className === 'Image' && e.evt?.layerX && e.evt?.layerY) {
        pointer.current = {
          x: e.evt?.layerX,
          y: e.evt?.layerY,
          isMove: true
        }

    }
  }

  const handleScroll = (e) => {
    if(pointer.current.isMove&&tool === TOOL.DRAG && e.target.className === 'Image'&& e.evt?.layerX && e.evt?.layerY) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft - e.evt?.layerX + pointer.current.x || 0,
        top: scrollRef.current.scrollTop - e.evt?.layerY + pointer.current.y || 0,
      })
    }
  }

  const endScroll = (e) => {
    if(tool === TOOL.DRAG && e.target.className === 'Image' && e.evt?.layerX && e.evt?.layerY) {
      pointer.current = {
        ...pointer.current,
        isMove:false
      }
    }
  }

  // settimeout để k bị outfocus do modal của MUI
  const handleClickFocus = useCallback((id) => {
    const findId = document.getElementById(id);
    if (findId) window.setTimeout(() => findId.focus(), 100);
  }, []);

  const allowed_move = !(info && (info.process || info.from_confirmed));

  const handleKeyPress = (e) => {
    if (!elements.length || !element || element.key < -1) return;
    if (!allowed_move) return;
    const element_action = elements.find((el) => el.key === element.key);
    if (element_action && element_action.type === 'text' && element.isEditing)
      return;

    if (e.keyCode === 46 || e.keyCode === 8) {
      // backspace, delete

      const new_elements = [...elements].filter((el) => el.key !== element.key);
      dispatch(
        setKonvaElementsAction({ elements: new_elements, newStep: true })
      );
      e.preventDefault();

      handleElement();
    } else {
      if (![37, 38, 39, 40].includes(e.keyCode)) return true;

      const index = elements.findIndex((el) => el.key === element.key);
      if (index < 0) return;

      let el = { ...elements[index] };
      const DELTA = 4;
      if (e.keyCode === 37) {
        el.x = el.x - DELTA;
      } else if (e.keyCode === 38) {
        el.y = el.y - DELTA;
      } else if (e.keyCode === 39) {
        el.x = el.x + DELTA;
      } else if (e.keyCode === 40) {
        el.y = el.y + DELTA;
      } else {
        return;
      }
      e.preventDefault();

      removeLastEmptyElement();
      dispatch(
        changeElementKonvaAction({
          key: el.key,
          element: {
            ...el,
          },
          newStep: true,
        })
      );
    }
  };

  const handleEndDrag = (e, ele) => {
    ele = {
      ...ele,
      x: e.target.x(),
      y: e.target.y(),
    };
    dispatch(
      changeElementKonvaAction({ key: ele.key, element: ele, newStep: true })
    );
  };

  const handleElement = (ele, isEditing) => {
    if (ele) {
      // info.color = ele.color;
      info.fontSize = ele.fontSize;
      dispatch(document_set_info(info));
      // localStorage.setItem("draw-color", ele.color);
      if ([true, false].includes(isEditing)) {
        ele.isEditing = isEditing;
        dispatch(setIsChangedKonvaAction(true));
        setKonVaChange(true)
      }
    }
    ele = ele ? ele : {};
    dispatch({ type: KONVA_ELEMENT, payload: ele });
  };

  useEffect(() => {
    let i = new Image();
    i.src = attachment.url;
    i.onload = async function () {
      setWidth(i.width);
      setHeight((i.height * 1000) / i.width);
    };
    setScale(width > 0 ? 1000 / width : 1);
  }, [width, height, scale, attachment]);

  useEffect(() => {
    if (
      element.key !== -1 &&
      elements.length &&
      elements[element.key] &&
      elements[element.key].type === ELEMENT_TYPE.TEXT &&
      element.isEditing
    ) {
      handleClickFocus('ele' + element.key);
    }
  }, [element, elements, handleClickFocus]);

  useEffect(() => {
    dispatch({
      type: KONVA_CHANGE,
      payload: {
        stageScale: 1,
        stageX: 0,
        stageY: 0,
      },
    });
  }, [id, dispatch]);

  useEffect(() => {
    if (konvaRef.current?.clientWidth) {
      dispatch(
        setScaleByScreenKonvaAction((konvaRef.current.clientWidth - 20) / 1000)
      );
    }
  }, [konvaRef.current?.clientWidth]);

  let cursor = useMemo(() => {
    switch (tool) {
      case TOOL.DRAG:
        return 'grab';
      case TOOL.TEXT:
        return 'text';
      default:
        return 'default';
    }
  }, [tool]);

  const stageScale = useMemo(
    () => (scaleKonva && scaleByScreen ? scaleKonva * scaleByScreen : 1),
    [scaleByScreen, scaleKonva]
  );

  useEffect(() => {
    document.getElementById('KonvaCanvas').firstChild.style.width = stageScale * 1000 + 'px';
  }, [stageScale,scaleByScreen]);

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target.className === 'Image';
    if (clickedOnEmpty) {
      handleElement(null);
    }
  };
  return (
    <div
      ref={konvaRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      id="konvaRef"
    >
      <div
        style={{
          cursor,
          width: isMobile ? window.innerWidth : 1000 * scaleByScreen - 20,
        }}
        className={scaleKonva * 1000 > 1000 ? '' : 'd-flex justify-content-center'}
        tabIndex={0}
        onKeyDown={handleKeyPress}
        onMouseOut={() => {
          if (isDrawing.current) {
            isDrawing.current = false;
          }
        }}
        id="KonvaCanvas"
      >
        <Stage
          width={isMobile ? window.innerWidth : 1000 * stageScale}
          height={height * stageScale}
          onMouseDown={(e) => {
            checkDeselect(e);
            handleMouseDown(e);
            startScroll(e)
          }}
          onTouchstart={(e) => {
            checkDeselect(e);
            handleMouseDown(e);
            startScroll(e)
          }}
          onPointerdown={(e) => {
            checkDeselect(e);
            handleMouseDown(e);
            startScroll(e)
          }}
          
          onMousemove={e => {
            if(!info.process) { 
              handleMouseMove(e);
            }
            handleScroll(e)
          }}
          onTouchmove={e => {
            if(!info.process) { 
              handleMouseMove(e);
            }
            handleScroll(e)
          }}
          onPointerMove={e => {
            if(!info.process) { 
              handleMouseMove(e);
            }
            handleScroll(e)
          }}
          
          onMouseup={(e) => {
            handleMouseUp(e)
            endScroll(e)
          }}
          onTouchend={(e) => {
            handleMouseUp(e)
            endScroll(e)
          }}
          onPointerUp={(e) => {
            handleMouseUp(e)
            endScroll(e)
          }}
          // draggable={tool === TOOL.DRAG && !element}
          // onDragEnd={(e) => {
          //   if (!e.target.parent) {
          //     dispatch({
          //       type: KONVA_CHANGE,
          //       payload: {
          //         stageX: e.target.x(),
          //         stageY: e.target.y(),
          //       },
          //     });
          //   }
          // }}
          // onWheel={handleWheel}
          scaleX={stageScale}
          scaleY={stageScale}
          x={stageX}
          y={stageY}
          onClick={(e) => {
            if (tool === TOOL.TEXT) {
              handleText(e);
            }
          }}
          onTap={(e) => {
            if (tool === TOOL.TEXT) {
              handleText(e);
            }
          }}
          style={{
            display: 'flex',
            marginTop: '10px',
            justifyContent: 'center',
          }}
        >
          <Layer>
            <URLImage
              src={attachment.url}
              scale={scale}
              onClick={(e) => handleElement()}
            />
            {(elements ?? []).map((ele, i) => {
              switch (ele.type) {
                case ELEMENT_TYPE.LINE:
                  return (
                    <CustomLine
                      // key={ele.key}
                      key={i}
                      shapeProps={ele}
                      isSelected={
                        ele.key === element.key &&
                        allowed_move &&
                        tool === TOOL.DRAG
                      }
                      onSelect={() => {
                        handleElement(ele);
                      }}
                      onChange={(newAttrs) => {
                        if (allowed_move) {
                          dispatch(
                            changeElementKonvaAction({
                              key: ele.key,
                              element: newAttrs,
                              newStep: true,
                            })
                          );
                        }
                      }}
                    />
                  );
                case ELEMENT_TYPE.ARROW:
                  return (
                    <CustomArrow
                       // key={ele.key}
                       key={i}
                      shapeProps={ele}
                      isSelected={
                        ele.key === element.key &&
                        allowed_move &&
                        tool === TOOL.DRAG
                      }
                      onSelect={() => {
                        handleElement(ele);
                      }}
                      onChange={(newAttrs) => {
                        if (allowed_move) {
                          dispatch(
                            changeElementKonvaAction({
                              key: ele.key,
                              element: newAttrs,
                              newStep: true,
                            })
                          );
                        }
                      }}
                    />
                  );
                case ELEMENT_TYPE.SQUARE:
                  return (
                    <CustomSquare
                      // key={ele.key}
                      key={i}
                      shapeProps={ele}
                      isSelected={
                        ele.key === element.key &&
                        allowed_move &&
                        tool === TOOL.DRAG
                      }
                      onSelect={() => {
                        handleElement(ele);
                      }}
                      onChange={(newAttrs) => {
                        if (allowed_move) {
                          dispatch(
                            changeElementKonvaAction({
                              key: ele.key,
                              element: newAttrs,
                              newStep: true,
                            })
                          );
                        }
                      }}
                    />
                  );
                case ELEMENT_TYPE.CIRCLE:
                  return (
                    <CustomCircle
                       // key={ele.key}
                       key={i}
                      shapeProps={ele}
                      isSelected={
                        ele.key === element.key &&
                        allowed_move &&
                        tool === TOOL.DRAG
                      }
                      onSelect={() => {
                        handleElement(ele);
                      }}
                      onChange={(newAttrs) => {
                        if (allowed_move) {
                          dispatch(
                            changeElementKonvaAction({
                              key: ele.key,
                              element: newAttrs,
                              newStep: true,
                            })
                          );
                        }
                      }}
                    />
                  );
                case ELEMENT_TYPE.CHECK:
                  return (
                    <CustomCheck
                      // key={ele.key}
                      key={i}
                      shapeProps={ele}
                      isSelected={
                        ele.key === element.key &&
                        allowed_move &&
                        tool === TOOL.DRAG
                      }
                      onSelect={() => {
                        handleElement(ele);
                      }}
                      onChange={(newAttrs) => {
                        if (allowed_move) {
                          dispatch(
                            changeElementKonvaAction({
                              key: ele.key,
                              element: newAttrs,
                              newStep: true,
                            })
                          );
                        }
                      }}
                    />
                  );
                case ELEMENT_TYPE.TEXT:
                  return (
                    <CustomText
                       // key={ele.key}
                       key={i}
                      shapeProps={ele}
                      isSelected={
                        ele.key === element.key &&
                        allowed_move &&
                        tool === TOOL.DRAG
                      }
                      onSelect={(isEditing = false) => {
                        handleElement(ele, isEditing);
                      }}
                      onChange={(newAttrs) => {
                        if (allowed_move) {
                          dispatch(
                            changeElementKonvaAction({
                              key: ele.key,
                              element: newAttrs,
                              // newStep: true,
                              newStep:
                                ele.text !== newAttrs.text &&
                                newAttrs.text !== '',
                            })
                          );
                        }
                      }}
                      propIsEditing={
                        ele.key === element.key &&
                        allowed_move &&
                        tool === TOOL.DRAG &&
                        element.isEditing
                      }
                      scaleText={stageScale}
                    />
                  );
                default:
                  return null;
              }
            })}
          </Layer>
        </Stage>
      </div>
    </div>
  );
};

export default KonvaCanvas;
