import { Button, ImageList, Tooltip, Typography } from '@mui/material';
import ImageListItem from '@mui/material/ImageListItem';
import { clearConfirmAction, handleSendMessage, sendConfirmDrawAction } from 'actions/draw';
import { openHOCModalAction } from 'actions/hoc-modal';
import { TEXT_LIST } from '../../../constants';
import { findProcessDrawDetail, processRequestConfirm, requestGraphQL } from 'constants/utils';
import { QRDialog } from 'containers/common/document';
import { ImagePreview } from 'custom-components/KonvaCanvas/Konva.components';
import ProcessLabel from 'custom-components/process-label/process-label';
import React, { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ROOM_INFO_BY_DRAW_ID } from 'share/query';
import { basic_loading } from 'actions/basic';
import { UPDATE_TEXT_CONFIRM_POSITION } from 'share/mutation';


const SendAndClearRequest = () => {
  const dispatch = useDispatch();
  const { list } = useSelector((a) => a.document);
  const { info } = useSelector((a) => a.draw);
  const room_select = useSelector(a => a.draw.room_select);
  // const arraySplitDraw = window.location.pathname.split('/');
  // const drawId = arraySplitDraw[arraySplitDraw.length - 1];
  // const valueSelectRoom = (room_select || []).find((item) => item.draw_id === drawId);

  const processRequest = findProcessDrawDetail('request');

  const sendList = useMemo(
    () =>
      list.filter(
        (item) =>
          !item.from_confirmed &&
          (item.process === processRequest.type ||
            item.process === processRequestConfirm.type)
      ),
    [list, processRequest, processRequestConfirm]
  );

  const onCheckedValue = async(e,id)=>{
    dispatch(basic_loading(true));
    if(e.target.value && e.target.value.length === 2 && id){
      const value = e.target.value
      try {
        await requestGraphQL(UPDATE_TEXT_CONFIRM_POSITION,{draw_detail_id:id,position:String(value)});
        dispatch(basic_loading(false));
      } catch (error) {
        dispatch(basic_loading(false));
      }
    }
    
  }

    const SellerPreviewDialog = () => {
      const [forceReloadCoppy, setForceReloadCoppy] = useState(0);

      return (
        <>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            fontWeight="700"
            textAlign="center"
            marginY={'12px'}
          >
            依頼確認
          </Typography>
          <div className="container">
            <Typography>以下の依頼を送信します。</Typography>
            <Typography>クリックで詳細を確認できます。</Typography>
            <div
              className="mt-3"
            >
              <ImageList cols={4} gap={15} sx={{ overflowY: 'visible' }}>
                {sendList.map((image, index) => {
                  const { process, base64, elements } = image;
                  const processing = findProcessDrawDetail(process);
                  return (
                    <ImageListItem key={index}>
                      <ImagePreview src={base64} elements={elements} />
                      {process ? <ProcessLabel processing={processing} /> : null}
                      <div style={{display:'flex',gap:'10px',alignItems:'center'}}>
                        <div style={{fontSize:'12px'}}>確認日付の位置</div>
                        <div className='radio-check-time-position' onChange={(e)=>onCheckedValue(e,image.id)}>
                          {/* top left  */}
                          <div style={{display:'flex',alignItems:'center'}} className='radio-check-tl'>
                            <label style={{fontSize:'10px',marginRight:'3px'}} for={`${image.id}左上`}>左上</label>
                            <input type="radio" id={`${image.id}左上`} name={image.id} value="tl" />
                          </div>
                          <div className='radio-line'></div>

                          {/* top right  */}
                          <div style={{display:'flex',alignItems:'center'}} className='radio-check-tr'>
                            <input type="radio" id={`${image.id}右上`} name={image.id} value="tr" />
                            <label style={{fontSize:'10px'}} for={`${image.id}右上`}>右上</label>
                          </div>

                          {/* right bottom  */}
                          <div style={{display:'flex',alignItems:'center',position:'relative'}} className='radio-check-rb'>
                            <input type="radio" id={`${image.id}右下`} name={image.id} value="rb" defaultChecked={true}/>
                             <label style={{fontSize:'10px'}} for={`${image.id}右下`}>右下</label>
                            <div className='radio-ver-line1'></div>
                          </div>
                          <div className='radio-line'></div>

                          {/* left bottom  */}
                          <div style={{display:'flex',alignItems:'center',position:'relative'}} className='radio-check-lb'>
                            <label style={{fontSize:'10px',marginRight:'3px'}} for={`${image.id}左下`}>左下</label>
                            <input type="radio" id={`${image.id}左下`} name={image.id} value="lb" />
                            <div className='radio-ver-line2'></div>
                          </div>
                        </div>
                      </div>
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </div>
            <Typography textAlign="center" marginTop={'15px'}>
              依頼を送信するとお客様がアクセスする依頼確認用のURLが表示されます。
            </Typography>
            <div className="d-flex justify-content-center">
              <div
                className={
                  'button-confirm' +
                  (sendList.length ? '' : ' cursor-no-drop opacity-50')
                }
                onClick={async () => {
                  if (sendList.length) {
                    const roomData = await requestGraphQL(GET_ROOM_INFO_BY_DRAW_ID, {id: info.id});
                    if(roomData.getRoomInfoByDrawId !== null){
                      const result = await dispatch(sendConfirmDrawAction(info.id,true));
                      setForceReloadCoppy(forceReloadCoppy + 1);
                      if (result && result.url)
                      await handleSendMessage(info,result.url,roomData.getRoomInfoByDrawId, room_select)
                      dispatch(
                        openHOCModalAction(
                          <QRDialog url={result.url} reload={forceReloadCoppy} />
                        )
                      );
                    }else{
                      const result = await dispatch(sendConfirmDrawAction(info.id,true));
                      setForceReloadCoppy(forceReloadCoppy + 1);
                      if (result && result.url)
                      dispatch(
                        openHOCModalAction(
                          <QRDialog url={result.url} reload={forceReloadCoppy} />
                        )
                      );
                    }

                    
                    
                  }
                }}
              >
                {TEXT_LIST.send_request}
              </div>
            </div>
          </div>
        </>
      );
    };
  return (
    <div className='w-100 d-flex justify-content-center'>
      {info.userAddressBookId && sendList.length ? (
        <Button
          variant='outlined'
          className="button_invite"
          onClick={() => {
            dispatch(openHOCModalAction(<SellerPreviewDialog />));
          }}
        >
          <span className="button_invite_label">{TEXT_LIST.send_request}</span>
        </Button>
      ) : (
        <Tooltip
          title={
            <div style={{ whiteSpace: 'pre-line' }}>
              {!info.userAddressBookId
                ? '未招待なので依頼送信できません'
                : '要記入・要確認ファイルがありません'}
            </div>
          }
          placement="top"
          arrow
        >
          <Button variant='outlined' className="button_invite send-and-request-unactive">
            <span className="button_invite_label">
              {TEXT_LIST.send_request}
            </span>
          </Button>
        </Tooltip>
      )}
      {sendList.length ? (
        <Button
          variant='outlined'
          className="button_invite"
          onClick={() => {
            dispatch(clearConfirmAction(info.id));
          }}
        >
          <span className="button_invite_label">依頼クリア</span>
        </Button>
      ) : (
        <Button variant='outlined' className="button_invite send-and-request-unactive">
          <span className="button_invite_label">依頼クリア</span>
        </Button>
      )}
    </div>
  );
};
export default SendAndClearRequest;
