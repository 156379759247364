import { connect } from 'react-redux';
import PchatRoute from '../../containers/basic/pchat-route';
import { basic_set_me, basic_set_open_snackbar } from '../../actions/basic';
import {
  COOKIE_DOMAIN,
  createClient,
  getCookieXToken,
  getErrorMessage,
  redirectToLoginPage,
  removeCookie,
  setCookie,
  signInByCookie,
} from '../../constants/utils';
import { GET_ME } from '../../share/query';

const mapStateToProps = (state) => {
  return {
    me: state.basic.me,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: async () => {
      if (!getCookieXToken()) await signInByCookie();

      const client = createClient();
      await client
        .request(GET_ME)
        .then((data) => {
          if (data.me) {
            dispatch(basic_set_me(data.me));
          } else {
            dispatch(
              basic_set_open_snackbar({ t: 'error', m: 'Not found user' })
            );
            setTimeout(() => {
              redirectToLoginPage();
            }, 2000);
          }
        })
        .catch((err) => {
          // Handle GET_ME error here
          const msg = getErrorMessage(err);
          dispatch(basic_set_open_snackbar({ t: 'error', m: msg }));
          removeCookie('x-token', COOKIE_DOMAIN);
          setTimeout(() => {
            redirectToLoginPage();
          }, 2000);
        });
    },
  };
};

const CPchatRoute = connect(mapStateToProps, mapDispatchToProps)(PchatRoute);

export default CPchatRoute;
