import {PROPERTY_VIEW} from "../../constants/store";

const view = (state = {}, action) => {
  switch (action.type) {
    case PROPERTY_VIEW:
      return Object.assign({}, action.payload);
    default:
      return state;
  }
};

export default view;