import {connect} from "react-redux";
import Snackbar from "../../containers/basic/snackbar";
import {basic_set_close_snackbar} from "../../actions/basic";

const mapStateToProps = (state) => {
  return {
    snackbar: state.basic.snackbar
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleClose: () => {
      return dispatch(basic_set_close_snackbar());
    }
  }
};

const CSnackbar = connect(mapStateToProps, mapDispatchToProps)(Snackbar);

export default CSnackbar;