
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams,useHistory } from 'react-router-dom';
import './iframe-modal.css'
import { createClient, createClient3D, createClientDraw, getErrorMessage } from 'constants/utils';
import { GET_CGS_FRAMES_INDEX, GET_DRAW_INFO } from 'share/query';
import { basic_loading, basic_set_is_modal_question, basic_set_open_snackbar } from 'actions/basic';
import CG3DForm from 'custom-components/CG3DForm/CG3DForm';
import { ADD_NEW_FRAME_3D, DRAW_REGISTER_USER_3D, UPDATE_CG_3D_URL } from 'share/mutation';
import { draw_set_frame_list, draw_set_info } from 'actions/draw';
import SidebarRight from 'custom-components/sidebarRight';
import { AddNewFrameItem, handleSortArray } from 'utils/common';
import { isMobile } from 'react-device-detect';
import MobileHeaderMenu from 'custom-components/mobile-header-menu/mobile-header-menu';


function IframeModal() {

    const [drawDetail, setdrawDetail] = useState(null)
    const [isOpenIframeModal, setisOpenIframeModal] = useState(false)
    const [frameInfo, setframeInfo] = useState(null)
    const [isShowMobileMenu, setisShowMobileMenu] = useState(true)

    const params = useParams();
    const { draw_id, frame_id } = useParams();
    const me = useSelector((a)=>a.basic.me)
    const meDraw = useSelector((a)=>a.basic.me_draw)
    const frame_list = useSelector((a)=>a.draw.frame_list)
    const history = useHistory()
    const dispatch = useDispatch()

    const getDrawInfo = async (drawId)=>{
        dispatch(basic_loading(true))
        const client = createClient();
        const drawData = await client.request(GET_DRAW_INFO,{drawId});
        if(drawData.getDrawById.id){
            dispatch(draw_set_info(drawData.getDrawById))
            setdrawDetail(drawData.getDrawById)
            // dispatch(getDrawDetailsAction(drawId))
            const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:drawId})
            if(cgData.getCgAndFrameListIndex.length > 0){
                const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
                dispatch(draw_set_frame_list(sortResult))
                const frameIndex = sortResult.findIndex((item)=> item.id === frame_id)
                if(frameIndex !== -1){
                    setframeInfo(sortResult[frameIndex])
                }
            }else{
                dispatch(draw_set_frame_list([]))
            }
        }
        dispatch(basic_loading(false))
    }

    const create3DUser =async (url)=>{
        const client3D = createClient3D();
        const client = createClientDraw();
        // DRAW_REGISTER_USER_3D
        const variables={
            email: drawDetail.to_user.email,
            full_name: drawDetail.user_address_book.user_name ? drawDetail.user_address_book.user_name : drawDetail.to_user.name,
            url:url,
            host_id: me.id,
            id: drawDetail.to_user.id,
            draw_id:drawDetail.id
        }
        const urlArray = url.split("/");
        const property_index = urlArray.findIndex((el)=> el === "properties");
        const property_id = urlArray[property_index + 1];
        try {
            await client3D.request(DRAW_REGISTER_USER_3D, variables).then((data) => {
                const result = data.drawRegisterUser
                if(result){
                    client.request(UPDATE_CG_3D_URL, {property_id: property_id,id:drawDetail.id,u_id:result.id}).then(async(data) => {
                    if(data.updateCg3DUrl){
                        dispatch(draw_set_info({
                            ...drawDetail,
                            cg_property_id: property_id,
                        }))
                        setdrawDetail({
                            ...drawDetail,
                            cg_property_id: property_id,
                        })

                        const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:drawDetail.id})
                        if(cgData.getCgAndFrameListIndex.length > 0){
                            const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
                            dispatch(draw_set_frame_list(sortResult))
                        }else{
                            dispatch(draw_set_frame_list([]))
                        }
                    }
                    }).catch((err)=>{
                        const msg = getErrorMessage(err);
                        dispatch(basic_set_open_snackbar({ t: 'error', m: msg.slice(6) }));
                    })
                }
            })
            handleCloseIframeModal()
        } catch (error) {
            const msg = getErrorMessage(error);
            if(url.length <= 0){
                dispatch(basic_set_open_snackbar({ t: 'error', m: "URLを入力してください" }));
            }else{
            dispatch(basic_set_open_snackbar({ t: 'error', m: "正しいURLを入力してください" }));
            }
            handleCloseIframeModal()
        }
          
    }



    const handleAddNewFrame = async(url)=>{
        dispatch(basic_loading(true))
        try {
            const add_data = await AddNewFrameItem(drawDetail,url,dispatch);
            if(add_data && add_data.length > 0){
                const client = createClientDraw()
                const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:drawDetail.id})
                if(cgData.getCgAndFrameListIndex.length > 0){
                    const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
                    dispatch(draw_set_frame_list(sortResult))
                    dispatch(basic_set_open_snackbar({ t: 'success', m: "追加されました" }));
                }else{
                    dispatch(draw_set_frame_list([]))
                }
            }
            dispatch(basic_loading(false))
            setisOpenIframeModal(false)
        } catch (error) {
            dispatch(basic_loading(false))
            const msg = getErrorMessage(error)
            dispatch(basic_set_open_snackbar({t:'error',m:msg}))
        }
    }

    const handleCloseIframeModal = ()=>{
        setisOpenIframeModal(false)
    }

    const handleRemoveFrameItem = async(data)=>{
        if(data.status){
            const client = createClientDraw()
            const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:drawDetail.id})
            if(cgData.getCgAndFrameListIndex.length > 0){
                const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
                dispatch(draw_set_frame_list(sortResult))
            }else{
                dispatch(draw_set_frame_list([]))
            }
            dispatch(basic_set_open_snackbar({ t: 'success', m: "削除されました" }));
        }else{
            dispatch(basic_set_open_snackbar({ t: 'error', m: data.data }));
        }
    }

    useEffect(() => {
        getDrawInfo(draw_id)
    }, [draw_id])  
    useEffect(() => {
        if(frame_id && drawDetail){
            const frameIndex = drawDetail.cg_frame_list.findIndex((item)=> item.id === frame_id)
            if(frameIndex !== -1){
                setframeInfo(drawDetail.cg_frame_list[frameIndex])
            }
        }
    }, [frame_id])
    

  return useMemo(
    ()=>
    (
        <div className='frame-wp'>
            {
                isMobile &&
                <MobileHeaderMenu
                    history={history}
                    draw_id={draw_id}
                    paramUrl={frame_id}
                    isHostSide={me ? true : false}
                    cg_id={drawDetail?.cg_property_id}
                    // handleOpenCgModal={() => setisOpenCreateUserModal(true)}
                    handleOpenCgModal={() => setisOpenIframeModal(true)}
                    frame_list={frame_list}
                    onremoveItem={handleRemoveFrameItem}
                    showMenu={isShowMobileMenu}
                    handleToggleMenu={()=> setisShowMobileMenu((e)=> !e)}
                />
            }
            <div className='frame-content'>
                <iframe src={frameInfo?.url} width="100%" height="100%" allowFullScreen></iframe>
            </div>
            {
                !isMobile &&
                <SidebarRight
                    history={history}
                    draw_id={draw_id}
                    paramUrl={frame_id}
                    isHostSide={me ? true : false}
                    cg_id={drawDetail?.cg_property_id}
                    // handleOpenCgModal={() => setisOpenCreateUserModal(true)}
                    handleOpenCgModal={() => setisOpenIframeModal(true)}
                    frame_list={frame_list}
                    onremoveItem={handleRemoveFrameItem}
                />
            }
            
            {/* {
                isOpenCreateUserModal && !drawDetail?.cg_property_id && <CG3DForm create3DUser={create3DUser} closeModal={handleCloseCGModal} />
            } */}

            {
                isOpenIframeModal && <CG3DForm isIframe={true} closeModal={handleCloseIframeModal} create3DUser={create3DUser} addNewFrame={handleAddNewFrame}/>
            }
        </div>
    ),
    [frame_id,drawDetail,isOpenIframeModal,frameInfo,frame_list,isShowMobileMenu]
  )
}

export default IframeModal