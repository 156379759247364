import { Stack, Typography } from '@mui/material';
import React from 'react';
import './document.css';
import IconWarningRed from '../../assets/images/warning_red.png';
import IconWarningYellow from '../../assets/images/warning_yellow.png';

const CustomProcessCancel = ({ isWarningRed,top=null, width }) => {
  return (
    <React.Fragment>
      <Stack className="stack-process-cancel" style={{top:`${top ? top+"%":'none'}`}}>
        <img
          src={isWarningRed ? IconWarningRed : IconWarningYellow}
          alt="warning"
          width={width ?? 30}
          
        />
      </Stack>
    </React.Fragment>
  );
};

export default React.memo(CustomProcessCancel);
