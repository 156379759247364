import {combineReducers} from "redux";
import {property_sidebar} from "./property-sidebar";
import list from "./list";
import info from "./info";
import view from "./view";

const property = combineReducers({
  property_sidebar: property_sidebar,
  list,
  info,
  view
});

export default property;
